import { Box, Link, Typography, Grid } from '@material-ui/core';
import { DateTime } from 'luxon';
import { FormSubmitsGetResponse, LoadingState } from '../types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { usePersistentScrollLocation } from '../utils';
import { Skeleton } from '@material-ui/lab';

interface FormSubmitsMobileProps {
  formSubmitList: FormSubmitsGetResponse | null;
  showPagination: () => void;
  isLoading?: LoadingState;
}

const FormSubmitsMobile = ({
  formSubmitList,
  showPagination,
  isLoading
}: FormSubmitsMobileProps) => {
  const { search } = useLocation();
  const rows = Array.from(Array(formSubmitList?.results.length).keys());
  usePersistentScrollLocation();

  return (
    <>
      {showPagination()}
      {isLoading === LoadingState.IsLoading
        ? rows.map((row) => (
            <Box key={row} mb={2}>
              <Skeleton variant="rect" height={70} />
            </Box>
          ))
        : formSubmitList?.results.map(
            ({ id, productId, updatedAt, formId, formName, updatedByName }) => {
              const productIds = productId
                ?.filter(({ value }) => value)
                .map(({ value }) => value)
                .join(', ');

              return (
                <Link
                  key={`vastaus-${id}`}
                  component={RouterLink}
                  to={{
                    pathname: `/vastaus/${id}`,
                    search
                  }}
                >
                  <Box mb={2}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography color="textPrimary">{`${id} ${
                        productIds ? ` | ${productIds}` : ''
                      }`}</Typography>
                      <Typography color="textSecondary">
                        {DateTime.fromISO(updatedAt).toFormat(
                          'dd.MM.yyyy HH:mm'
                        )}
                      </Typography>
                    </Grid>
                    <Typography color="primary">{`${formId} | ${formName}`}</Typography>
                    <Typography color="textPrimary">{updatedByName}</Typography>
                  </Box>
                </Link>
              );
            }
          )}
      {showPagination()}
    </>
  );
};

export default FormSubmitsMobile;
