import axios from 'axios';
import { EditState } from './Forms/DynamicForms/AddForm';
import { FormGroup } from './Forms/FormGroups';
import { getUserDataStorage, getAccessTokenStorage } from './storageHelper';
import {
  DataList,
  LoginResponse,
  sendTTLResponse,
  TtlFormInput,
  Order,
  GetOrderCountResponse,
  OrderFilterRequest,
  Envs,
  SearchResultsResponse,
  SubmitFormData,
  SubmitFormResponse,
  PutFormResponse,
  PostFormResponse,
  UploadProgressEvent,
  PostDataListResponse,
  DataListPostRequest,
  ProductInfo,
  TtlStatistics
} from './types';
import { selectOrderCopyEmail } from './utils';

const sendJson = (url: string, json: any, method: 'POST' | 'PUT') => {
  return fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      access_token: getAccessTokenStorage() ?? ''
    },
    body: JSON.stringify(json)
  });
};

const postJson = (url: string, json: any) => {
  return sendJson(url, json, 'POST');
};

const putJson = (url: string, json: any) => {
  return sendJson(url, json, 'PUT');
};

export const getJson = async <T>(url: string) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      access_token: getAccessTokenStorage() ?? ''
    }
  });
  return handleJsonResponse<T>(response);
};

const handleJsonResponse = async <T>(response: Response) => {
  if (isOk(response.status)) {
    return (await response.json()) as T;
  } else {
    const rejectedBody = await response.json();
    return Promise.reject({
      statusText: response.statusText,
      message: rejectedBody
    });
  }
};

const isOk = (statusCode: number) => {
  return statusCode >= 200 && statusCode < 300;
};

export const loginUser = async (email: string, password: string) => {
  const response = await postJson('/api/login', {
    email,
    password
  });
  return handleJsonResponse<LoginResponse>(response);
};

export const sendTTL = async (TTL: TtlFormInput, statistics: TtlStatistics) => {
  const user = getUserDataStorage();
  const response = await postJson('/api/order', {
    ...TTL,
    userName: `${user.firstName} ${user.lastName}`,
    orderCopyEmails: selectOrderCopyEmail(TTL.orderCopyEmails),
    sourceSystem: 'ramiForms3',
    fillDurationSeconds: statistics.filledDurationSeconds.toString(),
    formOpenedAt: statistics.formOpenedAt
  });
  return handleJsonResponse<sendTTLResponse>(response);
};

export const getOrders = async (filters?: OrderFilterRequest[]) => {
  const filterToQuery = (filter: OrderFilterRequest) =>
    `${filter.param}=${filter.value}`;

  if (filters && filters.length > 0) {
    return await getJson<Order[]>(
      `/api/orders?exact=false&${filters.map(filterToQuery).join('&')}`
    );
  }
  return await getJson<Order[]>('/api/orders');
};

export const deleteList = async (id: number) => {
  return await fetch(`/api/list/${id}`, {
    method: 'DELETE',
    headers: {
      access_token: getAccessTokenStorage() ?? '',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ listId: id })
  });
};

export const getCustomList = async (id: number) => {
  const response = await getJson<DataList>(`/api/list/${id}`);
  return response;
};

export const postList = async (list: DataListPostRequest) => {
  const response = await postJson('/api/list', list);
  return handleJsonResponse<PostDataListResponse>(response);
};

export const getPdf = async (url: string) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      Accept: 'application/pdf',
      access_token: getAccessTokenStorage() ?? ''
    }
  });
  return await response.blob();
};

export const getOrderPdf = async (orderNumber: string) => {
  return await getPdf(`/api/pdf/${orderNumber}.pdf`);
};

export const getOrderCount = async () => {
  return await getJson<GetOrderCountResponse>(`/api/order/count`);
};

export const getEnvs = async () => {
  return await getJson<Envs>('/api/envs');
};

export const getForm = async <GetFormResponse>(
  formId: string,
  includeCommonLists: boolean = true
) => {
  return await getJson<GetFormResponse>(
    `/api/form/${formId}?includeCommonLists=${includeCommonLists}`
  );
};

const activeGroupsQs = (groups: FormGroup[]) => {
  let qs = '';
  if (groups.length > 0) {
    qs = '&groups=' + groups.map((g) => g.groupId).join(',');
  }
  return qs;
};

export const buildQueryString = ({
  query = '',
  start = 0,
  end = 100,
  orderBy = 'created_at',
  favoritesOnly = false,
  activeGroups = [],
  order = 'asc'
}: {
  query?: string;
  start?: number;
  end?: number;
  orderBy?: string;
  favoritesOnly?: boolean;
  activeGroups?: FormGroup[];
  order?: string;
}) => {
  return `/api/forms?${
    query !== '' ? 'query=' + query : ''
  }&start=${start}&end=${end}&orderBy=${orderBy}&favoritesOnly=${favoritesOnly}${activeGroupsQs(
    activeGroups
  )}&order=${order}`;
};

export const search = async (query: string): Promise<SearchResultsResponse> => {
  if (query.toLowerCase().startsWith('ttl')) {
    return {
      resultCount: 1,
      results: [
        {
          formId: 'TTL',
          formName: 'Tilaustietolomake',
          description: 'Ramirentin tilaustietolomake',
          createdAt: '2021-04-07T07:51:28.001Z',
          createdBy: 'aleksanteri.vode@gmail.com',
          submitCount: 0,
          isFavorite: false,
          visibility: 'private'
        }
      ]
    };
  }
  const json = await getJson<SearchResultsResponse>(
    buildQueryString({ query })
  );
  return json;
};

export const submitForm = async (formData: SubmitFormData) => {
  const response = await sendJson(
    `/api/submit/${formData.formId}`,
    formData,
    'POST'
  );
  return handleJsonResponse<SubmitFormResponse>(response);
};

export const deleteForm = async (formId: string) => {
  const response = await fetch('/api/form', {
    method: 'DELETE',
    body: JSON.stringify({ formId: formId }),
    headers: {
      access_token: getAccessTokenStorage() ?? '',
      'Content-Type': 'application/json'
    }
  });
  return handleJsonResponse(response);
};

export const saveFormBase = async (formBase: EditState) => {
  const response = await postJson('/api/form', formBase);
  return handleJsonResponse<PostFormResponse>(response);
};

export const updateFormBase = async (formBase: EditState) => {
  const response = await putJson('/api/form', formBase);
  return handleJsonResponse<PutFormResponse>(response);
};

export const sendFileStream = async (
  formData: FormData,
  onProgress?: (p: UploadProgressEvent) => void
) => {
  return await axios.request({
    method: 'post',
    url: '/api/file-stream',
    data: formData,
    onUploadProgress: onProgress,
    headers: { access_token: getAccessTokenStorage() ?? '' }
  });
};

export const addFavorite = async (formId: string) => {
  const response = await postJson('/api/favorite', { formId: formId });
  return handleJsonResponse(response);
};

export const removeFavorite = async (formId: string) => {
  const response = await fetch('/api/favorite', {
    method: 'DELETE',
    body: JSON.stringify({ formId: formId }),
    headers: {
      access_token: getAccessTokenStorage() ?? '',
      'Content-Type': 'application/json'
    }
  });
  return handleJsonResponse(response);
};

export const getText = async (url: string) => {
  const response = await fetch(url, {
    headers: {
      access_token: getAccessTokenStorage() ?? ''
    }
  });
  return await response.text();
};

export const getGroups = async () => {
  return getJson('/api/groups');
};

export const createGroup = async (groupName: string, parent: number) => {
  return postJson('/api/group', { groupName, parent });
};

export const updateGroup = async (
  id: number,
  groupName: string,
  parent: number
) => {
  return putJson(`/api/group/${id}`, { groupName, parent });
};

export const deleteGroup = async (id: number) => {
  return await fetch(`/api/group/${id}`, {
    method: 'DELETE',
    headers: {
      access_token: getAccessTokenStorage() ?? '',
      'Content-Type': 'application/json'
    }
  });
};

export const getVisibility = async <GetVisibilityResponse>(formId: string) => {
  return await getJson<GetVisibilityResponse>(`/api/visibility/${formId}`);
};

export const getProduct = async (
  productId: string,
  currentlyRented: boolean
) => {
  return getJson<ProductInfo>(`/api/product/${productId}/${currentlyRented}`);
};

export const getSubmit = async <GetSubmitsResponse>(submitId: string) => {
  return await getJson<GetSubmitsResponse>(`/api/submit/${submitId}`);
};

export const deleteSubmit = async (submitId: string) => {
  return await fetch(`/api/submit/${submitId}`, {
    method: 'DELETE',
    headers: {
      access_token: getAccessTokenStorage() ?? '',
      'Content-Type': 'application/json'
    }
  });
};

export const setFormActive = async (submitId: string) => {
  const response = await putJson('/api/submit/active', {
    submitId
  });
  return handleJsonResponse<PutFormResponse>(response);
};

export const removeFormActive = async (submitId: string) => {
  const response = await fetch('/api/submit/active', {
    method: 'DELETE',
    body: JSON.stringify({ submitId }),
    headers: {
      access_token: getAccessTokenStorage() ?? '',
      'Content-Type': 'application/json'
    }
  });
  return handleJsonResponse(response);
};

export const setGlobalSettingValue = async (key: string, value: string) => {
  const response = await postJson('/api/change-show-order-form', {
    key,
    value
  });
  return handleJsonResponse<PostFormResponse>(response);
};
