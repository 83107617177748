import { makeStyles } from '@material-ui/core';
import { FunctionComponent } from 'react';
import ContentWrapper from './ContentWrapper';
import { isMobile } from './utils';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    backgroundColor: 'white',
    marginTop: theme.spacing(4),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4)
  },
  contentWrapperMobile: {
    backgroundColor: 'white',
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  }
}));

const ContentContainer: FunctionComponent<{ className?: string }> = (props) => {
  const classes = useStyles();
  return (
    <ContentWrapper
      className={`${
        isMobile() ? classes.contentWrapperMobile : classes.contentWrapper
      } ${props.className ?? ''}`}
    >
      {props.children}
    </ContentWrapper>
  );
};

export default ContentContainer;
