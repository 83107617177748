import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  makeStyles,
  createStyles,
  Typography,
  Button
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { getUserDataStorage } from '../storageHelper';
import { FormSubmitResult } from '../types';
import { ramiColors, usePersistentScrollLocation } from '../utils';
import ExpandableTableCell from './ExpandableTableCell';

const useStyles = makeStyles(() =>
  createStyles({
    descMaxWidth: {
      maxWidth: 170
    },
    alignTop: {
      verticalAlign: 'top'
    },
    disabled: {
      cursor: 'not-allowed'
    },
    disabledRow: {
      background: ramiColors.ramiGray,
      opacity: 0.5
    }
  })
);

interface FormSubmitsProps {
  formSubmitList: FormSubmitResult[] | null;
  showPagination: () => void;
}

const FormIncompleteSubmits = ({
  formSubmitList,
  showPagination
}: FormSubmitsProps) => {
  const classes = useStyles();
  const location = useLocation();
  usePersistentScrollLocation();
  const userEmail = getUserDataStorage().email;
  return (
    <>
      {showPagination()}
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Lomake</TableCell>
              <TableCell>Täyttäjä</TableCell>
              <TableCell>Täytetty</TableCell>
              <TableCell>Sarjanumero</TableCell>
              <TableCell>Päivittäjä</TableCell>
              <TableCell>Päivitetty</TableCell>
              <TableCell>Kommentti</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formSubmitList?.map((row) => {
              const isRowDisabled =
                row.isCurrentlyLocked.length > 1 &&
                row.isCurrentlyLocked !== userEmail;
              return (
                <TableRow
                  key={row.id}
                  className={isRowDisabled ? classes.disabledRow : ''}
                >
                  <TableCell scope="row" className={classes.alignTop}>
                    <Link
                      onClick={(event: any) => {
                        if (isRowDisabled) {
                          event.preventDefault();
                        }
                      }}
                      component={RouterLink}
                      to={{
                        pathname: `/vastaus/${row.id}`,
                        search: location.search
                      }}
                    >
                      <Typography
                        className={isRowDisabled ? classes.disabled : ''}
                      >
                        {row.id}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell
                    scope="row"
                    className={classes.descMaxWidth + ' ' + classes.alignTop}
                  >
                    <Typography color="primary">
                      <Link
                        onClick={(event: any) => {
                          if (isRowDisabled) {
                            event.preventDefault();
                          }
                        }}
                        component={RouterLink}
                        to={`/vastaus/${row.id}`}
                      >
                        <Typography
                          className={isRowDisabled ? classes.disabled : ''}
                        >
                          {row.formId}
                        </Typography>
                      </Link>
                    </Typography>
                    <Typography variant="body2">{row.formName}</Typography>
                  </TableCell>
                  <TableCell scope="row">{row.filledByName}</TableCell>
                  <TableCell scope="row">
                    {DateTime.fromISO(row.createdAt).toFormat(
                      'dd.MM.yyyy HH:mm'
                    )}
                  </TableCell>
                  <TableCell scope="row">
                    {row.productId &&
                      row.productId
                        .filter((productId) => productId.value)
                        .map((productId) => productId.value)
                        .join(', ')}
                  </TableCell>
                  <TableCell scope="row">{row.updatedByName}</TableCell>
                  <TableCell scope="row">
                    {DateTime.fromISO(row.updatedAt).toFormat(
                      'dd.MM.yyyy HH:mm'
                    )}
                  </TableCell>
                  <ExpandableTableCell text={row.comment} maxCharCount={60} />
                  <TableCell>
                    <Link
                      onClick={(event: any) => {
                        if (isRowDisabled) {
                          event.preventDefault();
                        }
                      }}
                      component={RouterLink}
                      to={`/vastaus/${row.id}`}
                    >
                      <Button disabled={isRowDisabled} color="primary">
                        Näytä
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination()}
    </>
  );
};
export default FormIncompleteSubmits;
