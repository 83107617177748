import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions
} from '@material-ui/core';
import React from 'react';

interface PromptDialogProps {
  title: string;
  body: string;
  onAccept: (value?: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  defaultValue?: string;
  hasInput?: boolean;
}

const FormDialog: React.FC<PromptDialogProps> = ({
  title,
  body,
  onAccept,
  open,
  setOpen,
  defaultValue,
  hasInput = true
}) => {
  const ref = React.useRef<HTMLInputElement>();
  const onClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{body}</DialogContentText>
        {hasInput && (
          <TextField
            defaultValue={defaultValue}
            inputRef={ref}
            autoFocus
            label={title}
            type="text"
            fullWidth
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Peruuta</Button>
        <Button
          onClick={() => {
            onAccept(ref?.current?.value ?? '');
            onClose();
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
