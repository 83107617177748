import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

interface ExpandableTableCellProps {
  text: string;
  maxCharCount: number;
}

const ExpandableTableCell: React.FC<ExpandableTableCellProps> = ({
  text,
  maxCharCount
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };
  const textLength = text.length > maxCharCount;
  const displayText = expanded
    ? text
    : `${text.slice(0, maxCharCount)}${textLength ? '...' : ''}`;

  return (
    <TableCell style={{ width: '300px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="body2"
          onClick={handleClick}
          style={{
            cursor: textLength ? 'pointer' : 'default',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            overflowWrap: 'break-word'
          }}
        >
          {displayText}
        </Typography>
        {textLength ? (
          <IconButton size="small" onClick={handleClick}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        ) : null}
      </div>
    </TableCell>
  );
};

export default ExpandableTableCell;
