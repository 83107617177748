import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { getUserRolesStorage } from './storageHelper';
import { Role } from './types';

const hasAllowedRole = (allowedRoles: Role[], userRoles: Role[]) => {
  return userRoles.some((userRole) => allowedRoles.includes(userRole));
};

const OnlyRoles: FunctionComponent<{ allowedRoles: Role[] }> = (props) => {
  const location = useLocation();
  const userRoles = getUserRolesStorage();

  if (
    location.pathname !== '/login' &&
    hasAllowedRole(props.allowedRoles, userRoles)
  ) {
    return <>{props.children}</>;
  } else {
    return null;
  }
};

export default OnlyRoles;
