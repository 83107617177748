import React, { useState, useEffect } from 'react';

interface TimerProps {
  onTimerZero: () => void;
}

const Timer: React.FC<TimerProps> = ({ onTimerZero }) => {
  const [time, setTime] = useState<number>(120);

  useEffect(() => {
    if (time <= 0) {
      onTimerZero();
    }

    const interval = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time, onTimerZero]);

  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return <div>Jäljellä oleva aika: {formatTime(time)}</div>;
};

export default Timer;
