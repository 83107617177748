import jwt_decode from 'jwt-decode';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { FormGroup } from './Forms/FormGroups';
import {
  getAccessTokenStorage,
  getUserDataStorage,
  getUserRolesStorage
} from './storageHelper';
import {
  AccessToken,
  DataListEntry,
  DbGroup,
  FormGroupNode,
  LoadingState,
  SearchResult
} from './types';

export const isTokenExpired = (accessToken: string) => {
  const decodedToken = jwt_decode<AccessToken>(accessToken);
  const secondsSinceEpoch = Math.floor(new Date().getTime() / 1000);
  return decodedToken.exp < secondsSinceEpoch;
};

export const isAuthenticated = () => {
  return (
    getAccessTokenStorage() !== null &&
    Object.keys(getUserDataStorage()).length > 0
  );
};

// Workaround for chrome displayin 'Numero' as a credit card number field
// We add invisible  whitespace character between N and U to break chrome regex matching
// but show the label as if it was unedited
export const getLabel = (label: string) => {
  const zeroWidthWhiteSpaceHtmlChar = '\u200B';
  return label.replace('numero', `n${zeroWidthWhiteSpaceHtmlChar}umero`);
};

export const selectOrderCopyEmail = (value: DataListEntry) => {
  return value.value;
};

export const clone = <T = any>(obj: T) => {
  return JSON.parse(JSON.stringify(obj)) as T;
};
const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  // Chrome requires returnValue to be set
  event.returnValue = '';
};
export const usePreventWindowUnload = (shouldDisplayMessage: boolean) => {
  React.useEffect(() => {
    if (!shouldDisplayMessage) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      return;
    }

    //if (document.location.hostname !== 'localhost') {
    window.addEventListener('beforeunload', handleBeforeUnload);
    //}
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [shouldDisplayMessage]);
  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.location.reload();
  };
};

export const ramiSafeOptions = [
  { label: 'RamiTurva', value: 'ramiTurva' },
  { label: 'RamiTurva AOV', value: 'ramiTurvaAov' },
  { label: 'Ei RamiTurvaa', value: 'eiRamiTurvaa' }
];

export const useLongPress = (callback = () => {}, ms = 5000) => {
  const [startLongPress, setStartLongPress] = React.useState(false);

  React.useEffect(() => {
    let timerId: any;
    if (startLongPress) {
      timerId = setTimeout(callback, ms);
    } else {
      clearTimeout(timerId);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [callback, ms, startLongPress]);

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false)
  };
};

export const formatReleaseVersion = (releaseTime: string) =>
  DateTime.fromMillis(Number(releaseTime))
    .setZone('Europe/Helsinki')
    .toFormat('dd.MM.yyyy HH:mm:ss');

export const getUserFullName = () => {
  const user = getUserDataStorage();
  return `${user.firstName} ${user.lastName}`;
};

export const isDevUser = (allowedUsernameList: string[], username: string) => {
  return allowedUsernameList.includes(username);
};

export const isAdminUser = () => {
  return getUserRolesStorage().includes('RamiForms\\RamiForms admin');
};

export const isViewUser = () => {
  return getUserRolesStorage().includes('RamiForms\\RamiForms View');
};

export const isMobile = () => typeof window.orientation !== 'undefined';

export const ramiColors = {
  ramiBlue: '#003287',
  ramiOrange: '#FF963C',
  ramiOrangeLight: '#FF963D',
  ramiYellow: '#FFDC00',
  ramiGreen: '#50C8AA',
  ramiGray: '#D2D7DC',
  red: '#f44336'
};

export const useIsLoading = (): [LoadingState, () => void, () => void] => {
  const [isLoading, setIsLoading] = React.useState<LoadingState>(
    LoadingState.Initial
  );
  const setLoadingFinished = () => {
    setIsLoading(LoadingState.Initial);
  };
  const setLoadingStarted = () => {
    setIsLoading(LoadingState.IsLoading);
  };
  return [isLoading, setLoadingFinished, setLoadingStarted];
};

let searchTreeCache: { [key: string]: FormGroupNode } = {};

const asGroupNode = (dbGroup: DbGroup): FormGroupNode => {
  return {
    name: dbGroup.groupName,
    groupId: dbGroup.id.toString(),
    parent: dbGroup.parent?.toString() ?? null,
    children: []
  };
};

export const searchTree = (
  node: FormGroupNode,
  id: string | undefined | null,
  skipCache = false
): FormGroupNode | null => {
  if (id === null || id === undefined) {
    return null;
  }
  if (!skipCache && searchTreeCache[id]) {
    return searchTreeCache[id];
  }
  if (node.groupId === id) {
    searchTreeCache[id] = node;
    return node;
  } else if (node.children.length > 0) {
    for (let i = 0; i < node.children.length; i++) {
      const result = searchTree(node.children[i], id, skipCache);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const buildGroupHierarchy = (groupList: DbGroup[]) => {
  searchTreeCache = {}; // clear cache every iteration
  const rootNode = groupList.find((g) => g.parent === null);
  if (!rootNode) {
    throw new Error(
      'Form groups must contain a root node with parent set to `null`.'
    );
  }
  const formGroupNode: FormGroupNode = asGroupNode(rootNode);
  const childNodes = groupList.filter((g) => typeof g.parent === 'number');
  const skippedNodes: number[] = [];
  childNodes.sort((a, b) => {
    // by sorting child nodes by parentId we ensure that every parent is defied before it is needed
    return a.parent - b.parent;
  });
  childNodes.forEach((g) => {
    const nodeToAdd = searchTree(formGroupNode, g.parent.toString());
    if (nodeToAdd === null) {
      // todo what to do with nodes that do not yet have a parent?
      skippedNodes.push(g.parent);
      console.warn('Node with undefined parent', g);
    }
    nodeToAdd?.children.push(asGroupNode(g));
  });
  return formGroupNode;
};

export const buildPathLabel = (groups: FormGroup[]) => {
  return groups.map((g) => g.name).join(' / ');
};

export const sortBy = <T extends { [key: string]: any }>(
  arr: T[],
  key: string
) => {
  return clone(arr).sort((a, b) => {
    const textA = a[key].toUpperCase();
    const textB = b[key].toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const sortByNumeric = <T extends { [key: string]: any }>(
  arr: T[],
  key: string
) => {
  return clone(arr).sort((a, b) => {
    const valA = a[key];
    const valB = b[key];
    return valA < valB ? -1 : valA > valB ? 1 : 0;
  });
};

export const arrayHasSkippedEntries = (arr: number[]) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] !== i) {
      return i;
    }
  }
  return -1;
};

const saveScrollPosition = () => {
  const scrollY = document.documentElement.scrollTop;
  localStorage.setItem('prevScrollPositionY', scrollY.toString());
};
export const usePersistentScrollLocation = () => {
  React.useEffect(() => {
    // TODO throttle this
    window.addEventListener('scroll', saveScrollPosition);
    return () => {
      window.removeEventListener('scroll', saveScrollPosition);
    };
  }, []);
  const scrollValue = localStorage.getItem('prevScrollPositionY');
  const scrollPos = scrollValue ? Number(scrollValue) : 0;
  React.useEffect(() => {
    if (scrollPos > 0) {
      window.scrollTo({ top: scrollPos });
    }
  }, [scrollPos]);
};

export const formatDate = (value: string) => {
  if (DateTime.fromISO(value).isValid) {
    return DateTime.fromISO(value).toFormat('dd.MM.yyyy');
  } else {
    return '-';
  }
};

export type SortOrders = 'az' | 'za' | 'newest' | 'oldest' | 'most-responses';
export type FormSorter = (forms: SearchResult[]) => SearchResult[];

const alphabeticalSorterAz = (forms: SearchResult[]) => {
  return forms.sort((a, b) => {
    if (a.formName.toLowerCase() < b.formName.toLowerCase()) {
      return -1;
    }
    if (a.formName.toLowerCase() > b.formName.toLowerCase()) {
      return 1;
    }
    return 0;
  });
};

const alphabeticalSorterZa = (forms: SearchResult[]) => {
  return forms.sort((a, b) => {
    if (a.formName.toLowerCase() > b.formName.toLowerCase()) {
      return -11;
    }
    if (a.formName.toLowerCase() < b.formName.toLowerCase()) {
      return 1;
    }
    return 0;
  });
};

const newestSorter = (forms: SearchResult[]) => {
  return forms.sort((a, b) => {
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    if (a.createdAt > b.createdAt) {
      return 1;
    }
    return 0;
  });
};

const oldestSorter = (forms: SearchResult[]) => {
  return forms.sort((a, b) => {
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    return 0;
  });
};

export const getSorter = (sortOrder: SortOrders = 'az'): FormSorter => {
  switch (sortOrder) {
    case 'az':
      return alphabeticalSorterAz;
    case 'za':
      return alphabeticalSorterZa;
    case 'newest':
      return newestSorter;
    case 'oldest':
      return oldestSorter;
    default:
      return alphabeticalSorterAz;
  }
};

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = (value: T) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
};

export const changeObserver = (granularity: number) => {
  let prevFormState: object | null = null;
  let totalSeconds = 0;
  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  let intervalId: ReturnType<typeof setInterval>;
  const getTotalSeconds = () => totalSeconds;
  return [
    (nextFormState: object) => {
      if (!prevFormState) {
        prevFormState = nextFormState;
        return getTotalSeconds;
      }
      if (!isEqual(nextFormState, prevFormState)) {
        prevFormState = nextFormState;
        if (!timeoutId) {
          intervalId = setInterval(() => {
            totalSeconds++;
          }, 1000);
        }
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          clearInterval(intervalId);
          timeoutId = null;
        }, granularity);
      }
      return getTotalSeconds;
    },
    () => {
      clearInterval(intervalId);
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      totalSeconds = 0;
    }
  ] as const;
};

// Generic compare function, where strings are case insensitive
export const compareNumberOrString = (
  a: number | string,
  b: number | string
): number => {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  } else if (typeof a === 'string' && typeof b === 'string') {
    const lowerA = a.toLowerCase();
    const lowerB = b.toLowerCase();
    if (lowerA < lowerB) {
      return -1;
    } else if (lowerA > lowerB) {
      return 1;
    } else {
      return 0;
    }
  } else {
    throw new Error('Cannot compare values of different types.');
  }
};

export const getActivePage = (activePageName: string): number => {
  const activePage = sessionStorage.getItem(activePageName);
  if (activePage) {
    return parseInt(activePage);
  }
  return 1;
};

export const setActivePage = (activePageName: string, page: number) => {
  sessionStorage.setItem(activePageName, page.toString());
};
