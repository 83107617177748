import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSkeleton from '../LoadingSkeleton';
import { getOrders } from '../requests';
import { TtlFormInput } from '../types';
import OrderForm from './OrderForm';

const fetchOrders = async (
  setPrefilledValues: (order: TtlFormInput) => void,
  orderNumber: string
) => {
  try {
    const ordersResponse = await getOrders([
      {
        param: 'ramiRentOrderNumber',
        value: orderNumber
      }
    ]);
    const order = ordersResponse[0];
    const preFilledValues: TtlFormInput = {
      customerName: order?.customerName ?? '',
      customerNumber: order?.customerNumber ?? '',
      orderId: '',
      contractId: order?.contractId ?? '',
      siteId: order?.siteId ?? '',
      siteName: order?.siteName ?? '',
      deliveryAddress: order?.deliveryAddress ?? '',
      town: order?.town ?? '',
      deliveryInfo: '',
      orderer: order?.orderer ?? '',
      ordererPhone: order?.ordererPhone ?? '',
      ordererEmail: order?.ordererEmail ?? '',
      deliveryType: '',
      recipient: order?.recipient ?? '',
      recipientPhone: order?.recipientPhone ?? '',
      seller: '',
      sellerPhone: '',
      office: '',
      returnDate: order?.returnDate ?? '',
      capabilityConfirmed: false,
      deliveryPrice: order?.deliveryPrice ?? 'Kuljetushinnaston mukaisesti',
      fastDelivery: false,
      requiresInstallation: false,
      info: '',
      orderCopyEmails: {
        label: 'Asiakaspalvelu',
        value: 'asiakaspalvelu@ramirent.fi'
      },
      products: [],
      postalCode: order?.postalCode ?? '',
      siteKey: order?.siteKey ?? '',
      deliveryEarliestDate: '',
      deliveryEarliestTime: '',
      deliveryLatestDate: '',
      deliveryLatestTime: '',
      sellerEmail: '',
      confirmationToSeller: false,
      confirmationToCustomer: false,
      manualPrice: order?.manualPrice ?? ''
    };
    setPrefilledValues(preFilledValues);
  } catch (err) {
    console.error(err);
  }
};
const OrderFormExisting: React.FC = () => {
  const { orderId } = useParams<{ orderId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [preFilledValues, setPrefilledValues] = useState<TtlFormInput>();
  useEffect(() => {
    try {
      setIsLoading(true);
      fetchOrders(setPrefilledValues, orderId);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [orderId]);

  return (
    <>
      {!isLoading && preFilledValues ? (
        <OrderForm preFilledValues={preFilledValues} />
      ) : (
        <LoadingSkeleton />
      )}
      ;:
    </>
  );
};

export default OrderFormExisting;
