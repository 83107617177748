import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import ContentContainer from '../ContentContainer';
import LoadingSkeleton from '../LoadingSkeleton';
import {
  ElementType,
  EntriesResponse,
  EntryType,
  LoadingState
} from '../types';
import { formatDate, ramiColors } from '../utils';
import { TableElementType } from './DynamicForms/Editing/Components/EditTable';
import { useApi } from './DynamicForms/fetchHook';
import { isRenderableImage } from './DynamicForms/FormComponents/FileUpload';
const queryString = require('query-string');

const useStyles = makeStyles((theme) =>
  createStyles({
    entryValue: {
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(3)
    },
    element: {
      width: '100%',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    elementDivider: {
      borderTop: 'solid 1px #e0e0e0',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    elementLabel: {
      marginBottom: theme.spacing(2)
    },
    elementDefault: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    elementComment: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      fontSize: '0.9rem'
    },
    elementCommentLabel: {
      marginLeft: theme.spacing(1),
      color: ramiColors.ramiOrange
    },
    elementImage: {
      width: '100%',
      height: 300,
      objectFit: 'contain'
    },
    elementEmpty: {
      marginLeft: theme.spacing(1)
    },
    pdfHeader: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(-7)
    },
    fillerNamePdf: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(4)
    },
    downloadPdfButton: { marginTop: theme.spacing(1) },
    pdfPictureUrl: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    }
  })
);

interface EntryProps {
  elementType: ElementType;
  entryType: EntryType;
  value: any;
  props: any;
  label: string;
  comment?: string;
  pdfView: boolean;
}

const Entry = ({
  elementType,
  value,
  props,
  label,
  comment,
  pdfView
}: EntryProps) => {
  return (
    <>
      <EntryLabel elementType={elementType} label={label} props={props} />
      <EntryValue
        elementType={elementType}
        value={value}
        comment={comment}
        props={props}
        pdfView={pdfView}
      />
    </>
  );
};

interface EntryLabelProps {
  elementType: ElementType;
  props: any;
  label: string;
}

const EntryLabel = ({ elementType, label, props }: EntryLabelProps) => {
  if (elementType === ElementType.Header) {
    return (
      <Typography variant={props.fontSize} color="primary">
        {label}
      </Typography>
    );
  } else {
    return <Typography color="primary">{label}</Typography>;
  }
};

interface CommentFieldProps {
  comment: string;
  commentLabel: string;
}

const CommentField = ({ comment, commentLabel }: CommentFieldProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.elementCommentLabel}>
        {commentLabel}
      </Typography>
      <Typography className={classes.elementComment} color="textSecondary">
        {comment}
      </Typography>
    </>
  );
};

interface EntryValueProps {
  elementType: ElementType;
  value: any;
  comment?: string;
  props: any;
  pdfView: boolean;
}

const EntryValue = ({
  elementType,
  value,
  comment,
  props,
  pdfView
}: EntryValueProps) => {
  const classes = useStyles();
  if (
    (elementType !== ElementType.Divider &&
      elementType !== ElementType.Header &&
      elementType !== ElementType.UserInfo &&
      !value) ||
    (elementType === ElementType.File &&
      Array.isArray(value) &&
      value.length === 0)
  ) {
    return <Typography className={classes.elementEmpty}>-</Typography>;
  }
  switch (elementType) {
    case ElementType.File:
      return value ? (
        <>
          <Grid container direction="row" spacing={2}>
            {value.map(
              (file: { url: string; fileType: string }, index: number) => {
                if (isRenderableImage(file.fileType)) {
                  if (pdfView) {
                    return (
                      <Grid className={classes.pdfPictureUrl}>
                        <a href={file.url} target="_blank" rel="noreferrer">
                          {index + 1}: Kuva
                        </a>
                      </Grid>
                    );
                  }
                  return (
                    <Grid key={'imagegrid' + file.url} item>
                      <a href={file.url} target="_blank" rel="noreferrer">
                        <img
                          key={file.url}
                          alt=""
                          src={file.url}
                          className={classes.elementImage}
                        ></img>
                      </a>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item>
                      <a href={file.url} target="_blank" rel="noreferrer">
                        {index + 1}: Tiedosto
                      </a>
                    </Grid>
                  );
                }
              }
            )}
          </Grid>
          {comment && (
            <CommentField comment={comment} commentLabel={props.commentLabel} />
          )}
        </>
      ) : (
        <div />
      );
    case ElementType.Divider:
      return <Divider className={classes.elementDivider} />;
    case ElementType.Header:
      return <Typography className={classes.elementLabel}>{value}</Typography>;
    case ElementType.Checkbox:
      return (
        <>
          <FormGroup>
            <Grid container direction="column" justifyContent="flex-start">
              {value.map((val: any) => {
                return (
                  <FormControlLabel
                    key={val.optionId}
                    color="primary"
                    control={<Checkbox color="primary" checked={val.value} />}
                    label={val.label}
                  />
                );
              })}
            </Grid>
          </FormGroup>
          {comment && (
            <CommentField comment={comment} commentLabel={props.commentLabel} />
          )}
        </>
      );
    case ElementType.Date:
      return (
        <>
          <Typography>{formatDate(value)}</Typography>
          {comment && (
            <CommentField comment={comment} commentLabel={props.commentLabel} />
          )}
        </>
      );
    case ElementType.LinkedList:
      return (
        <Typography className={classes.elementLabel}>{value.label}</Typography>
      );
    case ElementType.Drawing:
      return <img alt="Drawing by user" src={value} />;
    case ElementType.UserInfo:
      return null;
    case ElementType.Table:
      return (
        <table>
          <thead>
            <tr>
              {value?.[0]?.map(
                (header: {
                  label: string;
                  value: string;
                  type: TableElementType;
                }) => {
                  return (
                    <th style={{ paddingRight: 10, textAlign: 'left' }}>
                      {header.label}
                    </th>
                  );
                }
              )}
            </tr>
          </thead>
          <tbody>
            {value?.map(
              (
                row: { label: string; value: string; type: TableElementType }[]
              ) => {
                return (
                  <tr>
                    {row.map((column) => {
                      if (column.type === 'date') {
                        return (
                          <td style={{ paddingRight: 10 }}>
                            {formatDate(column.value)}
                          </td>
                        );
                      }
                      return (
                        <td style={{ paddingRight: 10 }}>{column.value}</td>
                      );
                    })}
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      );
    default:
      return (
        <>
          <Typography className={classes.elementDefault}>
            {String(value)
              .split('\n')
              .map((line) => {
                return (
                  <span>
                    {line}
                    <br />
                  </span>
                );
              })}
          </Typography>
          {comment && (
            <CommentField comment={comment} commentLabel={props.commentLabel} />
          )}
        </>
      );
  }
};

const EntriesView = () => {
  const { submitId } = useParams<{ submitId: string }>();
  const [entriesResponse, isLoading, error] = useApi<EntriesResponse>(
    `/api/entry/${submitId}`
  );
  const classes = useStyles();
  const location = useLocation();
  const pdf = queryString.parse(location.search).pdfView;
  const pdfView = pdf === 'true';

  if (isLoading === LoadingState.IsLoading) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return (
      <ContentContainer>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          Vastausta {submitId} ei löytynyt
        </Grid>
      </ContentContainer>
    );
  }

  return (
    <>
      {pdfView ? (
        <div className={classes.pdfHeader}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Typography>{entriesResponse?.formId}</Typography>
            <Typography
              className={classes.fillerNamePdf}
            >{`Täyttänyt: ${entriesResponse?.updatedByName}`}</Typography>
          </Grid>
        </div>
      ) : (
        <ContentContainer>
          <Box>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography>{entriesResponse?.formId}</Typography>
                <Typography>{entriesResponse?.updatedByName}</Typography>
                <Typography>{entriesResponse?.updatedBy}</Typography>
                <Typography>
                  {DateTime.fromISO(entriesResponse?.updatedAt ?? '').toFormat(
                    'dd.MM.yyyy HH:mm'
                  )}
                </Typography>
              </div>
              <div>
                {!entriesResponse?.submitted ? (
                  <Link
                    component={RouterLink}
                    to={{
                      pathname: `/lomake/${entriesResponse?.formId}`,
                      search: location.search,
                      state: { submitId: submitId }
                    }}
                  >
                    <Button type="button" variant="contained" color="secondary">
                      Muokkaa
                    </Button>
                  </Link>
                ) : null}
              </div>
            </div>
            {entriesResponse?.pdfUrl && (
              <div className={classes.downloadPdfButton}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={entriesResponse?.pdfUrl}
                >
                  <Button variant="contained" color="primary" size="small">
                    Lataa PDF
                  </Button>
                </a>
              </div>
            )}
          </Box>
        </ContentContainer>
      )}

      <ContentContainer>
        <Typography variant="h4" color="primary">
          {entriesResponse?.formName}
        </Typography>
        {entriesResponse?.entries.map((entry) => (
          <div key={entry.elementsId}>
            <Entry
              elementType={entry.elementType}
              entryType={entry.entryType}
              value={entry.value}
              props={entry.props}
              label={entry.label}
              comment={entry.comment}
              pdfView={pdfView}
            />
          </div>
        ))}
      </ContentContainer>
    </>
  );
};

export default EntriesView;
