import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import React from 'react';
import { clone } from '../utils';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { Alert, Autocomplete } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import { DataListEntry } from '../types';
interface RentableProduct {
  id: string;
  name: string;
  ramisafe: string;
  amount: number;
  unit: string;
  price: number;
  permonth: boolean;
  info: string;
  offerprice: boolean;
}

interface ProductTableProps {
  handleNewProductClick: () => void;
  onChange: (currentProducts: RentableProduct[]) => void;
  value: RentableProduct[];
  error: boolean;
  ramisafeOptions: DataListEntry[];
  unitOptions: string[];
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiTableCell-sizeSmall': {
        margin: 0,
        padding: 0
      }
    }
  })
);

const orEmptyString = (value: string | null) => value ?? '';

const ProductTable: React.FC<ProductTableProps> = ({
  value,
  onChange,
  handleNewProductClick,
  error,
  ramisafeOptions,
  unitOptions
}) => {
  const classes = useStyles();
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5" color="primary">
          Tuotteet
        </Typography>
        <Button
          type="button"
          onClick={handleNewProductClick}
          startIcon={<AddIcon />}
          data-cy="add-row"
        >
          Lisää tuote
        </Button>
      </Box>
      <div style={{ overflowX: 'auto' }}>
        {error && (
          <Alert severity="error">
            Kaikilla tuotteilla on oltava yksikkö, tuotemäärä eri kuin 0 sekä
            tuotenumero tai nimi
          </Alert>
        )}
        {value?.length > 0 ? (
          <TableContainer style={{ minWidth: 1000, overflowX: 'scroll' }}>
            <Table
              size="small"
              className={classes.root}
              data-cy="product-table"
              style={{ height: '100%' }}
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Kuukausihinta</TableCell>
                  <TableCell align="center">Tarjouksen mukaan</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ height: '100%' }}>
                {value.map((row, i) => {
                  const rowSpecificChangeHandler = (field: string) => {
                    return (e: any) => {
                      const newState = clone<any>(value);
                      if (field === 'permonth' || field === 'offerprice') {
                        newState[i][field] = e.target.checked;
                      } else {
                        newState[i][field] = e.target.value;
                      }
                      onChange(newState);
                    };
                  };
                  const deleteRow = () => {
                    const newState = clone(value);
                    newState.splice(i, 1);
                    onChange(newState);
                  };
                  const cloneRow = () => {
                    const newState = clone(value);
                    newState.push(clone(value)[i]);
                    onChange(newState);
                  };
                  return (
                    <TableRow key={i} style={{ height: '100%' }}>
                      <TableCell style={{ width: 110 }}>
                        <TextField
                          multiline
                          autoFocus={value?.length > 1}
                          label="Tuotenumero"
                          value={row.id}
                          onChange={rowSpecificChangeHandler('id')}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          label="Tuotenimi"
                          multiline={true}
                          value={row.name}
                          onChange={rowSpecificChangeHandler('name')}
                        />
                      </TableCell>
                      <TableCell>
                        <FormControl style={{ minWidth: 150 }}>
                          <InputLabel shrink id="id-ramisafe">
                            RamiTurva
                          </InputLabel>
                          <Select
                            labelId="id-ramisafe"
                            value={row.ramisafe}
                            onChange={rowSpecificChangeHandler('ramisafe')}
                          >
                            {ramisafeOptions.map((ramisafe) => (
                              <MenuItem
                                key={ramisafe.value}
                                value={ramisafe.value}
                              >
                                {ramisafe.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField
                          InputProps={{
                            inputProps: {
                              min: 1
                            }
                          }}
                          onWheel={(e: any) => {
                            e.target.blur();
                          }}
                          label="Määrä"
                          type="number"
                          value={row.amount}
                          onChange={rowSpecificChangeHandler('amount')}
                        />
                      </TableCell>
                      <TableCell style={{ paddingRight: 16 }}>
                        <Autocomplete
                          value={row.unit}
                          freeSolo
                          options={unitOptions}
                          onChange={(e, newInputValue) => {
                            rowSpecificChangeHandler('unit')({
                              target: { value: orEmptyString(newInputValue) }
                            });
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              onChange={rowSpecificChangeHandler('unit')}
                              label="Yksikkö"
                              margin="normal"
                            />
                          )}
                        />
                      </TableCell>
                      <Divider
                        orientation="vertical"
                        style={{ height: '100%' }}
                      />
                      <TableCell>
                        <TextField
                          onWheel={(e: any) => {
                            e.target.blur();
                          }}
                          type="number"
                          label="Hinta, alv 0%"
                          value={row.price}
                          onChange={rowSpecificChangeHandler('price')}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox
                          checked={row.permonth}
                          color="primary"
                          onChange={rowSpecificChangeHandler('permonth')}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox
                          checked={row.offerprice}
                          color="primary"
                          onChange={rowSpecificChangeHandler('offerprice')}
                        />
                      </TableCell>
                      <Divider
                        orientation="vertical"
                        style={{ height: '100%' }}
                      />
                      <TableCell>
                        <TextField
                          label="Huomiot"
                          value={row.info}
                          multiline={true}
                          onChange={rowSpecificChangeHandler('info')}
                        />
                      </TableCell>
                      <TableCell>
                        <Box display="flex">
                          <IconButton onClick={deleteRow} data-cy="delete-row">
                            <DeleteOutlineRoundedIcon />
                          </IconButton>
                          <IconButton onClick={cloneRow} data-cy="copy-row">
                            <FilterNoneIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box marginTop={1}>
            <Alert severity="info">
              Ei tuotteita. Lisää tuotteita painamalla "Lisää tuote"
            </Alert>
          </Box>
        )}
      </div>
    </>
  );
};

export default ProductTable;
