import {
  Grid,
  Link,
  makeStyles,
  Typography,
  IconButton,
  Button,
  Drawer,
  Box,
  Chip,
  Paper
} from '@material-ui/core';
import React from 'react';
import {
  DbGroup,
  FormGroupNode,
  LoadingState,
  SearchResultsResponse
} from '../types';
import { Link as RouterLink } from 'react-router-dom';
import {
  ChevronLeft,
  Favorite,
  FavoriteBorderOutlined
} from '@material-ui/icons';
import {
  buildGroupHierarchy,
  buildPathLabel,
  getSorter,
  ramiColors,
  searchTree
} from '../utils';
import MenuIcon from '@material-ui/icons/Menu';
import FormGroups, { FormGroup } from './FormGroups';
import FormListCategorySelector, {
  CategorySelectorFormGroup
} from './FormListCategorySelector';
import CircularProgressIndicator from './CircularProgressIndicator';

interface FormListMobileProps {
  formsList: SearchResultsResponse | null;
  handleToggleFavorite: (
    formId: string,
    isFavorite: boolean,
    formName: string
  ) => void;
  formGroups: DbGroup[];
  activeGroups: FormGroup[];
  setActiveGroups: (gs: FormGroup[]) => void;
  isLoading: LoadingState;
  showPagination: () => void;
}

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    marginBottom: 16,
    [theme.breakpoints.down(400)]: {
      maxWidth: 200
    }
  },
  paper: {
    width: '80%'
  },
  groupName: {},
  drawerHeading: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2)
  },
  drawerBody: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1)
  },
  drawerHeadingFont: {
    fontSize: 26
  },
  listFormContainer: {
    flexWrap: 'nowrap'
  }
}));

const toSelectorCategories = (
  tree: FormGroupNode,
  currentTree: FormGroupNode,
  activeGroupId: string
) => {
  let target: FormGroupNode | null = null;
  if (currentTree.children.length > 0) {
    target = currentTree;
  } else {
    target = searchTree(tree, currentTree.parent);
  }
  return (
    target?.children.map((c) => ({
      name: c.name,
      groupId: c.groupId,
      isActive: activeGroupId === c.groupId
    })) ?? []
  );
};

const FormListMobile = ({
  formsList,
  handleToggleFavorite,
  formGroups,
  activeGroups,
  setActiveGroups,
  isLoading,
  showPagination
}: FormListMobileProps) => {
  const classes = useStyles();
  const [drawer, setDrawer] = React.useState(false);
  const toggleDrawer = () => {
    setDrawer(!drawer);
  };
  const categoryHierachy = React.useMemo(() => {
    return buildGroupHierarchy(formGroups);
  }, [formGroups]);
  const activeGroupId = activeGroups[0]?.groupId ?? '1';
  const currentTree = searchTree(categoryHierachy, activeGroupId);
  const sorter = getSorter();
  return (
    <>
      <Drawer
        anchor="left"
        open={drawer}
        onClose={toggleDrawer}
        classes={{
          paper: classes.paper
        }}
      >
        <Paper elevation={4}>
          <Box className={classes.drawerHeading}>
            <Typography className={classes.drawerHeadingFont}>
              Kansiot
            </Typography>
          </Box>
        </Paper>
        <Box className={classes.drawerBody}>
          <FormGroups
            selected={activeGroups[0]?.groupId}
            formGroups={formGroups}
            onGroupSelected={(g) => {
              if (g.closeWhenSelected) {
                toggleDrawer();
              }
              const tree = searchTree(categoryHierachy, g.groupId);
              const childrenLength = tree?.children?.length ?? 0;
              if (childrenLength === 0) {
                toggleDrawer();
              }
              setActiveGroups([g]);
            }}
          />
        </Box>
      </Drawer>
      <Button onClick={toggleDrawer} startIcon={<MenuIcon />}>
        Kansiot
      </Button>
      <Box marginTop={1}>
        {activeGroupId !== '1' && (
          <IconButton size="small">
            <ChevronLeft
              onClick={() => {
                const parentTreeNode = searchTree(
                  categoryHierachy,
                  currentTree?.parent
                );
                if (parentTreeNode) {
                  setActiveGroups([
                    {
                      groupId: parentTreeNode.groupId,
                      name: parentTreeNode.name
                    }
                  ]);
                }
              }}
            />
          </IconButton>
        )}
        {activeGroups.length > 0 && (
          <Chip label={buildPathLabel(activeGroups)} />
        )}
      </Box>
      <FormListCategorySelector
        availableGroups={toSelectorCategories(
          categoryHierachy,
          currentTree as FormGroupNode,
          activeGroupId
        )}
        setSelectedGroupId={(g: CategorySelectorFormGroup) => {
          setActiveGroups([{ name: g.name, groupId: g.groupId }]);
        }}
      />
      <CircularProgressIndicator isLoading={isLoading} />
      <Box paddingBottom={6}>
        {showPagination()}
        {sorter(formsList?.results ?? []).map((row) => (
          <Grid
            key={row.formId}
            container
            className={classes.listFormContainer}
          >
            <IconButton
              style={{ marginLeft: -8, marginRight: 16, marginTop: -16 }}
              onClick={() => {
                handleToggleFavorite(row.formId, row.isFavorite, row.formName);
              }}
            >
              {row.isFavorite ? (
                <Favorite style={{ color: ramiColors.ramiOrangeLight }} />
              ) : (
                <FavoriteBorderOutlined />
              )}
            </IconButton>
            <Link
              key={row.formId}
              component={RouterLink}
              to={`/lomake/${row.formId}`}
            >
              <div className={classes.linkContainer}>
                <Typography color="textPrimary">{row.formId}</Typography>
                <Typography color="primary">{`${row.formName}`}</Typography>
                <Typography color="textSecondary">{row.description}</Typography>
              </div>
            </Link>
          </Grid>
        ))}
        {showPagination()}
      </Box>
    </>
  );
};

export default FormListMobile;
