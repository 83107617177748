import {
  BottomNavigation,
  BottomNavigationAction,
  makeStyles,
  Tab,
  Tabs,
  useMediaQuery
} from '@material-ui/core';
import { Assignment, Favorite, LocalShipping } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { getNavigationStorage, setNavigationStorage } from './storageHelper';

const useStyles = makeStyles(() => ({
  navigateActive: {
    backgroundColor: 'white',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8
  }
}));

export const NavigationContext = React.createContext<number>(1);

const enabledRoutes = ['/', '/vastaukset', '/lomakkeet', '/tilaukset'];

const getInitialNavigation = (path: string) => {
  const navigationStorage = getNavigationStorage();
  if (navigationStorage) {
    return navigationStorage;
  }
  // Navigate to forms
  if (['/vastaukset', '/lomakkeet', '/lomake'].some((p) => path.includes(p))) {
    return 0;
  }
  // Navigate to ttl
  if (['/tilaukset', '/tilaukset'].some((p) => path.includes(p))) {
    return 1;
  }
  return 0;
};

const NavigationBar = (props: any) => {
  const location = useLocation();
  const initialNavigation = getInitialNavigation(location.pathname);
  const [bottomBarNavigation, setBottomBarNavigation] =
    React.useState(initialNavigation);
  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);
  const isMobileView = useMediaQuery('(max-width:640px)');

  useEffect(() => {
    if (redirectToReferrer) {
      setRedirectToReferrer(false);
    }
  }, [redirectToReferrer]);

  const classes = useStyles();

  if (redirectToReferrer && location.pathname !== '/') {
    return <Redirect to={'/'} />;
  }
  return (
    <>
      {enabledRoutes.includes(location.pathname) ? (
        <>
          {!isMobileView && (
            <>
              <Tabs
                style={{ marginTop: 24, marginBottom: '-32px' }}
                centered
                value={bottomBarNavigation}
                indicatorColor="primary"
                textColor="primary"
                TabIndicatorProps={{
                  style: {
                    display: 'none'
                  }
                }}
                onChange={(event, newValue) => {
                  setBottomBarNavigation(newValue);
                  setNavigationStorage(newValue);
                  setRedirectToReferrer(true);
                }}
              >
                <Tab
                  className={
                    bottomBarNavigation === 0 ? classes.navigateActive : ''
                  }
                  label="RamiForms"
                />
                <Tab
                  className={
                    bottomBarNavigation === 1 ? classes.navigateActive : ''
                  }
                  label="Tilaustietolomake"
                />
                <Tab
                  className={
                    bottomBarNavigation === 2 ? classes.navigateActive : ''
                  }
                  label="Suosikit"
                />
              </Tabs>
              <NavigationContext.Provider value={bottomBarNavigation}>
                {props.children}
              </NavigationContext.Provider>
            </>
          )}
          {isMobileView && (
            <>
              <NavigationContext.Provider value={bottomBarNavigation}>
                {props.children}
              </NavigationContext.Provider>
              <BottomNavigation
                value={bottomBarNavigation}
                showLabels
                style={{
                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  boxShadow:
                    '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
                }}
                onChange={(event, newValue) => {
                  setBottomBarNavigation(newValue);
                  setNavigationStorage(newValue);
                  setRedirectToReferrer(true);
                }}
              >
                <BottomNavigationAction label="Forms" icon={<Assignment />} />
                <BottomNavigationAction label="TTL" icon={<LocalShipping />} />
                <BottomNavigationAction label="Suosikit" icon={<Favorite />} />
              </BottomNavigation>
            </>
          )}
        </>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
};

export default NavigationBar;
