import {
  Button,
  createStyles,
  Grid,
  Link,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core';
import React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { LoadingState } from '../../../types';
import CircularProgressIndicator from '../../CircularProgressIndicator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formHeader: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    formDescription: { width: '100%' },
    button: { marginLeft: theme.spacing(2) }
  })
);

interface FormHeaderProps {
  onClickSettings: (show: boolean) => void;
  onUpdateFormTitle: (e: any) => void;
  onUpdateFormDescription: (e: any) => void;
  onSave: () => void;
  formTitle: string;
  formDescription: string;
  isLoading: LoadingState;
}

const FormHeader: React.FC<FormHeaderProps> = ({
  onClickSettings,
  onUpdateFormTitle,
  onSave,
  onUpdateFormDescription,
  formTitle,
  formDescription,
  isLoading
}) => {
  const formId = useParams<{ formId: string }>().formId ?? null;
  const formEditView = window.location.pathname.includes(`/lomake/muokkaus/`);
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={4}
      >
        <Grid md={6} xs={12} item>
          <TextField
            placeholder="Lomakkeen otsikko"
            className={classes.formHeader}
            size="medium"
            onChange={onUpdateFormTitle}
            value={formTitle}
          ></TextField>
          <TextField
            placeholder="Lomakkeen kuvaus"
            className={classes.formDescription}
            size="small"
            onChange={onUpdateFormDescription}
            value={formDescription}
          ></TextField>
        </Grid>
        <Grid md={6} xs={12} item>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button onClick={() => onClickSettings(true)}>Asetukset</Button>
            {formEditView ? (
              <Link
                component={RouterLink}
                to={{
                  pathname: `/lomake/kopio/`,
                  state: { formId: formId }
                }}
              >
                <Button
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                >
                  Luo kopio
                </Button>
              </Link>
            ) : null}
            <Button
              className={classes.button}
              onClick={onSave}
              variant="contained"
              color="primary"
              disabled={isLoading === LoadingState.IsLoading}
            >
              Tallenna
              <CircularProgressIndicator isLoading={isLoading} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FormHeader;
