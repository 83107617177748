import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Fab,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import ContentContainer from '../../ContentContainer';
import { useApi } from './fetchHook';
import {
  FormKeyValuePairs,
  FormElement,
  FormElementsApiResponse
} from './FormComponents/ComponentTypes';
import { jsonComponentMapper } from './JsonToComponentMapper';
import { Prompt, Redirect, useLocation, useParams } from 'react-router-dom';
import {
  DataListEntry,
  EntriesResponse,
  EntryResponse,
  FormSubmitResult,
  LoadingState,
  PostEntry,
  SubmitFormData
} from '../../types';
import {
  setFormActive,
  submitForm,
  removeFormActive,
  getSubmit,
  deleteSubmit
} from '../../requests';
import { getUserDataStorage } from '../../storageHelper';
import SubmitDialog from '../SubmitDialog';
import { isAdminUser, ramiColors, usePreventWindowUnload } from '../../utils';
import { CheckboxGroupState } from './FormComponents/MultipleChoiceCheckbox';
import CircularProgressIndicator from '../CircularProgressIndicator';
import NotFound from '../NotFound';
import { Save } from '@material-ui/icons';
import { DateTime } from 'luxon';
import Timer from '../Timer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    element: {
      width: '100%',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    buttonMobile: { width: '100%', marginTop: 10 },
    errorDialogContainer: { marginLeft: theme.spacing(3) },
    errorListContainer: { padding: theme.spacing(1) },
    errorListItem: { marginLeft: theme.spacing(1) }
  })
);
export interface FormProps {
  elements: FormElement[];
}

let timeoutId: ReturnType<typeof setTimeout>;

const FormHeader = ({
  formName,
  formId
}: {
  formName: string;
  formId: string;
}) => {
  return (
    <>
      <Typography variant="h4" color="primary">
        {formName}
      </Typography>
      <Typography variant="h6" color="textSecondary">
        {formId}
      </Typography>
    </>
  );
};

const inputFields = [
  'text',
  'email',
  'textarea',
  'number',
  'date',
  'radio',
  'dropdown',
  'file',
  'linkedlist',
  'autocomplete',
  'checkbox',
  'productId',
  'drawing',
  'table'
];
export const isInputElement = (element: FormElement) =>
  inputFields.indexOf(element.elementType) > -1;

const nonInputFieldsIncludedInSubmit = ['divider', 'header'];

const isIncludedInSubmit = (element: FormElement) =>
  nonInputFieldsIncludedInSubmit.indexOf(element.elementType) > -1;

const mapFormDataToEntries = (
  data: any,
  allElements: FormElement[]
): PostEntry[] => {
  return Object.keys(data).map((entryId) => {
    const numEntry = Number(entryId);
    const formElement = allElements.find((elem) => elem.id === numEntry);
    const label = formElement?.label ?? '';
    const orderId = formElement?.orderId ?? null;
    return {
      elementId: numEntry,
      orderId,
      label,
      value: data[entryId].value,
      comment: data[entryId].comment,
      ...(data[entryId].optionId && {
        // if response containsn optionId, add it to response
        optionId: Number(data[entryId].optionId)
      })
    };
  });
};

const generateEmptyCheckboxState = (
  options: DataListEntry[] | undefined
): CheckboxGroupState => {
  return (
    options?.map((option) => ({
      label: option.label,
      value: false,
      optionId: option.id
    })) ?? []
  );
};

const generateDefaults = (
  formData: FormElementsApiResponse | null,
  entries: EntryResponse[] | undefined
) => {
  return (
    formData?.elements.reduce((acc, cur) => {
      const entry = entries?.find((entry) => {
        return cur.id === entry.elementsId;
      });
      switch (cur.elementType) {
        case 'checkbox':
          return {
            ...acc,
            [cur.id]: {
              value: entry
                ? entry.value
                : generateEmptyCheckboxState(cur.options)
            }
          };
        case 'table':
          if (entry?.value && Array.isArray(entry.value)) {
            return {
              ...acc,
              [cur.id]: {
                value: entry.value.map((outerArray) => {
                  if (Array.isArray(outerArray)) {
                    return outerArray.map((innerArray) => {
                      return {
                        label: innerArray.label,
                        value: innerArray.value,
                        type: innerArray.type
                      };
                    });
                  } else {
                    return [];
                  }
                })
              }
            };
          } else {
            return {
              ...acc,
              [cur.id]: {
                value: [
                  cur.props?.tableOptions?.map((tableOptions) => {
                    return {
                      label: tableOptions.label,
                      value: '',
                      type: tableOptions.elementType
                    };
                  })
                ]
              }
            };
          }
        default:
          return {
            ...acc,
            [cur.id]: {
              value: entry ? entry.value : cur?.props?.defaultValue ?? ''
            }
          };
      }
    }, {}) ?? {}
  );
};

const validateAllowDecimals = (element: FormElement, value: any) => {
  return (
    !(
      element.elementType === 'number' &&
      !Number.isInteger(Number(value)) &&
      !element.props?.allowDecimals
    ) || 'Desimaalit ei ole sallittuja'
  );
};

const validateIsRequired = (element: FormElement, value: any) => {
  if (element.required) {
    return !(!value || value.length === 0) || 'Kenttä on pakollinen';
  } else return true;
};

const validateElement = (element: FormElement, value: any) => {
  return (
    [validateIsRequired, validateAllowDecimals]
      .map((f) => {
        const validationResult = f(element, value);
        if (!validationResult) {
          console.info('Validation failed for', f.name);
        }
        return validationResult;
      })
      .find((validationResult) => typeof validationResult === 'string') ?? true
  );
};

const FormBody: React.FC = () => {
  const [isSubmitLoading, setIsSubmitLoading] = useState<LoadingState>(
    LoadingState.Initial
  );
  const location = useLocation<{
    submitId: string;
    submit: FormSubmitResult;
  }>();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showSavedDialog, setShowSavedDialog] = useState(false);
  const [showDeletedDialog, setShowDeletedDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showAlreadyInUseDialog, setShowAlreadyInUseDialog] = useState(false);
  const [showStillThereDialog, setShowStillThereDialog] = useState(false);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [saveAndRedirect, setSaveAndRedirect] = useState(false);
  const [sendForm, setSendForm] = useState(false);
  const [beforeSave, setBeforeSave] = useState(false);
  const [submit, setSubmit] = useState<FormSubmitResult>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [submitId, setSubmitId] = useState(location.state?.submitId ?? '');
  const [commentValue, setCommentValue] = useState('');

  const { formId } = useParams<{
    formId: string;
  }>();

  const [formData, loading, formLoadingError] = useApi<FormElementsApiResponse>(
    `/api/form/${formId}`
  );

  const [entriesResponse, isLoading] = useApi<EntriesResponse>(
    `/api/entry/${submitId}`
  );

  const user = getUserDataStorage();
  const isNotPublic = formData?.visibility !== 'public';

  useEffect(() => {
    if (submitId) {
      (async () => {
        try {
          setActive();
        } catch (error: any) {
          setShowAlreadyInUseDialog(true);
          console.log(error);
        }
      })();
    }

    return () => {
      if (submitId) {
        removeFormActive(submitId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitId]);

  useEffect(() => {
    if (submitId) {
      (async () => {
        try {
          const submit = await getSubmit<FormSubmitResult>(submitId);
          setSubmit(submit);
          setCommentValue(submit.comment);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [submitId]);

  const setActive = async () => {
    clearTimeout(timeoutId);
    try {
      timeoutId = setTimeout(() => {
        setSendForm(false);
        setSaveAndRedirect(true);
        setShowStillThereDialog(true);
      }, 60 * 60 * 1000 + 55 * 60 * 1000); // 1h 55 minutes
      await setFormActive(submitId.toString());
    } catch (error) {
      setShowAlreadyInUseDialog(true);
      console.log(error);
    }
  };

  const defaultValues = generateDefaults(formData, entriesResponse?.entries);
  const methods = useForm({ defaultValues });
  const classes = useStyles();
  const {
    handleSubmit,
    control,
    errors,
    reset,
    formState,
    setValue,
    getValues
  } = methods;
  const { isDirty } = formState;
  const resetUiState = () => {
    setShowSuccessDialog(false);
    setShowErrorDialog(false);
    setShowSavedDialog(false);
  };

  const resetState = () => {
    reset(defaultValues);
    resetUiState();
  };

  useEffect(() => {
    reset(generateDefaults(formData, entriesResponse?.entries));
  }, [formData, reset, entriesResponse]);

  useEffect(() => {
    const errorIds = Object.keys(errors);
    if (errorIds && errorIds.length > 0) {
      document
        .getElementById('input-element-id-' + errorIds[0])
        ?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    if (saveAndRedirect) {
      const values = getValues();
      onSubmit(values);
      setSaveAndRedirect(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAndRedirect]);

  const isMobileView = useMediaQuery('(max-width:640px)');

  const onSubmit = async (data: FormKeyValuePairs) => {
    if (submitId) {
      setActive();
    }
    const user = getUserDataStorage();
    const toSubmit: SubmitFormData = {
      formId,
      filledBy: user.email ?? '',
      filledByName: `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim(),
      phoneNumber: user.phoneNumber ?? '',
      entries: mapFormDataToEntries(data, formData?.elements ?? []),
      submitted: sendForm,
      updatedBy: user.email ?? '',
      updatedByName: `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim(),
      submitId: submitId ? submitId.toString() : '',
      comment: commentValue
    };
    try {
      setIsSubmitLoading(LoadingState.IsLoading);
      const newSubmitId = await submitForm(toSubmit);
      setSubmitId(newSubmitId.submitId);

      if (!saveAndRedirect) {
        sendForm ? setShowSuccessDialog(true) : setShowSavedDialog(true);
      }
    } catch (err: any) {
      setShowErrorDialog(true);
    } finally {
      setIsSubmitLoading(LoadingState.Initial);
      setSendForm(false);
    }
  };

  const onDelete = async () => {
    await deleteSubmit(submitId);
    setShowDeletedDialog(true);
  };
  usePreventWindowUnload(LoadingState.IsLoading === isSubmitLoading || isDirty);

  if (
    loading === LoadingState.IsLoading ||
    isLoading === LoadingState.IsLoading
  ) {
    return (
      <>
        <Box display="flex" justifyContent="center">
          <CircularProgress size={48} />
        </Box>
        <Box className={classes.element}>
          <Skeleton width={200} />
          <Skeleton height={50} />
        </Box>
        <Box className={classes.element}>
          <Skeleton width={200} />
          <Skeleton height={50} />
        </Box>
        <Box className={classes.element}>
          <Skeleton width={200} />
          <Skeleton height={50} />
        </Box>
      </>
    );
  }
  if (redirectToReferrer) {
    return <Redirect to="/" />;
  }

  if (formLoadingError?.statusCode === 404) {
    return <NotFound />;
  }

  return (
    <>
      <Prompt
        when={isDirty}
        message="Lomakkeella on tallentamattomia muutoksia. Oletko varma, että haluat poistua?"
      />
      {isNotPublic ? (
        <Fab
          variant="circular"
          size={isMobileView ? 'small' : 'large'}
          color="secondary"
          onClick={() => {
            setSendForm(false);
            setBeforeSave(true);
          }}
          style={{
            position: 'fixed',
            bottom: 20,
            [isMobileView ? 'left' : 'right']: 20,
            zIndex: 100
          }}
          aria-label="add"
        >
          <Save />
        </Fab>
      ) : null}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SubmitDialog
            show={showSuccessDialog}
            handleDismiss={() => {
              setRedirectToReferrer(true);
            }}
            handleStay={async () => {
              if (submitId) {
                reset({ isDirty: false });
                await removeFormActive(submitId);
                window.history.replaceState({}, '');
                await new Promise((resolve) => setTimeout(resolve, 0));
                window.location.reload();
              } else {
                resetState();
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                });
              }
            }}
            headerText="Lomake lähetetty onnistuneesti!"
            leavePageButtonText="Palaa etusivulle"
            stayPageButtonText="Täytä uusi lomake"
            status="success"
          >
            <Typography>
              <p>Täyttämäsi lomake on lähetetty onnistuneesti!</p>
              <p>
                Voit aloittaa uuden lomakkeen täyttämisen tai siirtyä
                etusivulle.
              </p>
              <p>
                Sinut ohjataan kahden minuutin päästä automaattisesti
                etusivulle.
              </p>
              <Timer onTimerZero={() => setRedirectToReferrer(true)} />
            </Typography>
          </SubmitDialog>
          <SubmitDialog
            show={showDeletedDialog}
            handleDismiss={() => {
              setRedirectToReferrer(true);
            }}
            headerText="Lomake poistettu onnistuneesti!"
            leavePageButtonText="Palaa etusivulle"
            status="success"
          >
            <Typography>
              <p>{`Lomake ${submitId} on poistettu onnistuneesti!`}</p>
            </Typography>
          </SubmitDialog>
          <SubmitDialog
            show={showStillThereDialog}
            handleDismiss={() => {
              setRedirectToReferrer(true);
            }}
            handleStay={() => {
              setShowStillThereDialog(false);
            }}
            headerText="Jatkatko vielä lomakkeen tekoa?"
            leavePageButtonText="Palaa etusivulle"
            stayPageButtonText="Jatka"
            status="success"
          >
            <Typography>
              <p>
                Lomake on tallennettu automaattisesti, jatkatko vielä lomakkeen
                tekoa vai haluatko palata etusivulle
              </p>
              <p>
                Sinut ohjataan kahden minuutin päästä automaattisesti
                etusivulle.
              </p>
              <Timer onTimerZero={() => setRedirectToReferrer(true)} />
            </Typography>
          </SubmitDialog>
          <SubmitDialog
            show={showSavedDialog}
            handleDismiss={() => {
              setRedirectToReferrer(true);
            }}
            handleStay={() => {
              setShowSavedDialog(false);
            }}
            handleTernary={async () => {
              reset({ isDirty: false });
              await removeFormActive(submitId);
              window.history.replaceState({}, '');
              await new Promise((resolve) => setTimeout(resolve, 0));
              window.location.reload();
            }}
            headerText="Lomake tallennettu keskeneräisenä onnistuneesti!"
            leavePageButtonText="Palaa etusivulle"
            stayPageButtonText="Jatka täyttämistä"
            ternaryButtonText="Täytä uusi lomake"
            status="success"
          >
            <Typography>
              <p>
                Sähköposteja ei ole lähetetty keskeneräisenä tallennetusta
                lomakkeesta.
              </p>
              <p>
                {`Lomakkeen näkyvyys: ${
                  formData?.showSavedSubmitToUserOnly ? 'käyttäjä' : 'kaikki'
                }`}
              </p>
              <p>
                Sinut ohjataan kahden minuutin päästä automaattisesti
                etusivulle.
              </p>
              <Timer onTimerZero={() => setRedirectToReferrer(true)} />
            </Typography>
          </SubmitDialog>
          <SubmitDialog
            show={showConfirmation}
            headerText="Oletko varma?"
            leavePageButtonText="Lähetä"
            stayPageButtonText="Peruuta"
            status="success"
            handleClose={() => {
              setShowConfirmation(false);
            }}
            handleDismiss={() => {
              setShowConfirmation(false);
              handleSubmit(onSubmit)();
            }}
            handleStay={() => {
              setShowConfirmation(false);
            }}
          >
            <Typography>
              <p>Haluatko varmasti lähettää lomakkeen?</p>
              <p>Lomaketta ei voi muokata enää lähetyksen jälkeen</p>
            </Typography>
          </SubmitDialog>
          <SubmitDialog
            show={showDeleteConfirmation}
            headerText="Oletko varma?"
            leavePageButtonText="Poista"
            stayPageButtonText="Peruuta"
            status="success"
            handleClose={() => {
              setShowDeleteConfirmation(false);
            }}
            handleDismiss={() => {
              onDelete();
            }}
            handleStay={() => {
              setShowDeleteConfirmation(false);
            }}
          >
            <Typography>
              <p>Haluatko varmasti poistaa lomakkeen?</p>
            </Typography>
          </SubmitDialog>
          <SubmitDialog
            show={beforeSave}
            handleClose={() => {
              setBeforeSave(false);
            }}
            handleDismiss={() => {
              setBeforeSave(false);
              handleSubmit(onSubmit)();
            }}
            handleStay={() => {
              setBeforeSave(false);
            }}
            headerText="Ennen kuin tallennat..."
            leavePageButtonText="Tallenna"
            stayPageButtonText="Peruuta"
            status="success"
          >
            <div className={classes.errorDialogContainer}>
              <Typography>
                Kirjoita tarvittaessa selventävä kommentti:
              </Typography>
              <TextField
                value={commentValue}
                onChange={(event) => setCommentValue(event.target.value)}
                fullWidth
                id="outlined-multiline-static"
                multiline
                minRows={6}
                variant="outlined"
              />
            </div>
          </SubmitDialog>
          <SubmitDialog
            show={showErrorDialog}
            handleDismiss={() => {
              setRedirectToReferrer(true);
            }}
            handleStay={resetUiState}
            headerText="Lomakkeen lähettämisessä tapahtui virhe"
            leavePageButtonText="Palaa etusivulle"
            stayPageButtonText="Palaa lomakkeelle"
            status="error"
          >
            <div className={classes.errorDialogContainer}>
              <Typography>Lomakkeen lähettämisessä tapahtui virhe!</Typography>

              <Typography>
                Voit yrittää lähettää lomaketta uudelleen tai siirtyä
                etusivulle.
              </Typography>
            </div>
          </SubmitDialog>
          <SubmitDialog
            show={showAlreadyInUseDialog}
            handleDismiss={() => {
              setRedirectToReferrer(true);
            }}
            headerText="Jokin meni pieleen"
            leavePageButtonText="Ok"
            status="error"
          >
            <div className={classes.errorDialogContainer}>
              <Typography>
                Keskeneräisellä lomakkeella tapahtui jokin virhe.
              </Typography>

              <Typography>
                On mahdollista että lomake on toisella käyttäjällä työn alla.
                Sinut viedään takaisin etusivulle
              </Typography>
            </div>
          </SubmitDialog>
          <FormHeader
            formName={formData?.formName ?? '-'}
            formId={formData?.formId ?? '-'}
          />
          {submit ? (
            <Box mt={1}>
              <Typography>Lomake ID: {submit.id}</Typography>
              <Typography>
                {`Päivitetty:
                ${DateTime.fromISO(submit.updatedAt).toFormat(
                  'dd.MM.yyyy HH:mm'
                )}
                - ${submit.updatedByName}`}
              </Typography>
              <Typography style={{ fontStyle: 'italic' }}>
                {submit.comment}
              </Typography>
            </Box>
          ) : null}
          {formData?.elements?.map((element) => {
            return (
              <Box className={classes.element} key={element.id}>
                {isSubmittableElement(element) ? (
                  <Controller
                    rules={{
                      validate: ({ value }) => {
                        if (
                          isIncludedInSubmit(element as FormElement) ||
                          !sendForm
                        ) {
                          return true;
                        }
                        return validateElement(element, value);
                      }
                    }}
                    name={element.id.toString()}
                    control={control}
                    render={(renderProps) => {
                      const onChange = (key: string) => {
                        return (value: any) => {
                          const newValues = {
                            ...renderProps.value, // preserve values from previous change event, e.g. a comment
                            [key]: value
                          };
                          if (
                            ['dropdown', 'radio'].includes(element.elementType)
                          ) {
                            const maybeOptionId = element?.options?.find(
                              (opt) => opt.value === value
                            )?.id;
                            if (maybeOptionId) {
                              newValues.optionId = maybeOptionId;
                            }
                          }
                          renderProps.onChange(newValues);
                        };
                      };
                      return (
                        <>
                          <div id={'input-element-id-' + element.id.toString()}>
                            {jsonComponentMapper(
                              element,
                              onChange('value'),
                              renderProps.value?.value ?? '',
                              errors,
                              setValue,
                              formData
                            )}
                          </div>
                          {element.props?.enableComments && (
                            <TextField
                              style={{ marginTop: 8 }}
                              className="form-dynamic-comment"
                              fullWidth
                              multiline
                              value={renderProps.value.comment ?? ''}
                              onChange={(e) => {
                                const onChangeHandler = onChange('comment');
                                onChangeHandler(e.target.value);
                              }}
                              label={element.props?.commentLabel}
                              variant="outlined"
                            />
                          )}
                        </>
                      );
                    }}
                  />
                ) : (
                  jsonComponentMapper(element as FormElement)
                )}
              </Box>
            );
          })}
          <div
            style={
              !isMobileView ? { display: 'flex', columnGap: 20 } : undefined
            }
          >
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => {
                setSendForm(true);
                setShowConfirmation(true);
              }}
              disabled={isSubmitLoading === LoadingState.IsLoading}
              className={`${isMobileView && classes.buttonMobile}`}
            >
              Lähetä lomake
              <CircularProgressIndicator isLoading={isSubmitLoading} />
            </Button>
            {isNotPublic ? (
              <Button
                type="button"
                variant="contained"
                color="secondary"
                onClick={() => {
                  setSendForm(false);
                  setBeforeSave(true);
                }}
                disabled={isSubmitLoading === LoadingState.IsLoading}
                className={`${isMobileView && classes.buttonMobile}`}
              >
                Tallenna keskeneräisenä
                <CircularProgressIndicator isLoading={isSubmitLoading} />
              </Button>
            ) : null}
            {submit && (user.email === submit.filledBy || isAdminUser()) ? (
              <Button
                type="button"
                variant="outlined"
                onClick={() => setShowDeleteConfirmation(true)}
                className={`${isMobileView && classes.buttonMobile}`}
                style={{ color: ramiColors.red }}
              >
                Poista lomake
                <CircularProgressIndicator isLoading={isSubmitLoading} />
              </Button>
            ) : null}
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const Form: React.FC<FormProps> = () => {
  return (
    <ContentContainer>
      <FormBody />
    </ContentContainer>
  );
};

export { Form };
function isSubmittableElement(element: FormElement) {
  return (
    isInputElement(element as FormElement) ||
    isIncludedInSubmit(element as FormElement)
  );
}
