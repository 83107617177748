import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import ContentContainer from './ContentContainer';
import { useApi } from './Forms/DynamicForms/fetchHook';
import { setGlobalSettingValue } from './requests';

const AdminSettings = () => {
  const [showOrderForm, loading] = useApi<any>(
    `/api/show-order-form?key=showOrderForm`
  );
  const [checked, setChecked] = useState<boolean>();

  useEffect(() => {
    setChecked(showOrderForm);
  }, [showOrderForm]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setChecked(newValue);
    setGlobalSettingValue('showOrderForm', newValue.toString());
  };

  return (
    <ContentContainer>
      <Typography variant="h5" color="textPrimary">
        Asetukset
      </Typography>
      {!loading ? (
        <CircularProgress />
      ) : (
        <Box mt={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={handleChange}
                />
              }
              label="Näytä tilaustietolomake"
            />
          </FormGroup>
        </Box>
      )}
    </ContentContainer>
  );
};

export default AdminSettings;
