import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileSuccessImg: {
      marginLeft: -15
    },
    mobileHeaderText: {
      marginLeft: theme.spacing(1)
    },
    mobileContent: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    }
  })
);

type Status = 'success' | 'error';
interface SubmitDialogProps {
  handleDismiss: () => void;
  handleStay?: () => void;
  handleTernary?: () => void;
  show: boolean;
  headerText: string;
  leavePageButtonText: string;
  stayPageButtonText?: string;
  ternaryButtonText?: string;
  status: Status;
  handleClose?: () => void;
}

const getPictureUrl = (status: Status) => {
  switch (status) {
    case 'success':
      return '/static/rami-success.jpg';
    case 'error':
      return '/static/rami-error.svg';
    default:
      return '/static/rami-error.svg';
  }
};

const SubmitDialog: React.FC<SubmitDialogProps> = ({
  show,
  handleDismiss,
  handleStay,
  handleTernary,
  children,
  headerText,
  stayPageButtonText,
  leavePageButtonText,
  ternaryButtonText,
  status,
  handleClose
}) => {
  // TODO: This dialog is getting a bit messy. Maybe should make more different type of dialogs
  // and clean up form.tsx logic with the dialogs.
  const isMobileView = useMediaQuery('(max-width:640px)');
  const classes = useStyles();
  const onClose = handleClose ? handleClose : handleDismiss;
  const verticalButtons = isMobileView && handleTernary;

  return (
    <Dialog open={show} onClose={onClose}>
      {isMobileView ? (
        <>
          <DialogTitle data-cy="order-success-header">
            <Box display="flex" alignItems="center">
              <Box>
                <img
                  className={classes.mobileSuccessImg}
                  width={status === 'success' ? 70 : 50}
                  src={getPictureUrl(status)}
                  alt={status}
                />
              </Box>
              <Typography className={classes.mobileHeaderText} variant="h6">
                {headerText}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Box display="flex">
                <Box flex={3} className={classes.mobileContent}>
                  {children}
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle data-cy="order-success-header">{headerText}</DialogTitle>
          <DialogContent>
            <Box>
              <Box display="flex" minWidth={500}>
                <Box style={status === 'error' ? { paddingRight: '20px' } : {}}>
                  <img
                    width={status === 'success' ? 150 : 100}
                    src={getPictureUrl(status)}
                    alt={status}
                  />
                </Box>
                <Box flex={3}>{children}</Box>
              </Box>
            </Box>
          </DialogContent>
        </>
      )}
      <DialogActions style={verticalButtons ? { flexDirection: 'column' } : {}}>
        {handleStay ? (
          <Button
            fullWidth={!!verticalButtons}
            onClick={handleStay}
            color="primary"
            style={verticalButtons ? { marginBottom: '10px' } : {}}
          >
            {stayPageButtonText}
          </Button>
        ) : null}
        {handleTernary ? (
          <Button
            fullWidth={!!verticalButtons}
            onClick={handleTernary}
            color="secondary"
            variant="contained"
            style={verticalButtons ? { marginBottom: '10px' } : {}}
          >
            {ternaryButtonText}
          </Button>
        ) : null}
        <Button
          fullWidth={!!verticalButtons}
          onClick={handleDismiss}
          color="primary"
          variant="contained"
          style={verticalButtons ? { marginBottom: '10px' } : {}}
        >
          {leavePageButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitDialog;
