import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { clone, sortBy } from '../../../../utils';
import DeleteIcon from '@material-ui/icons/Delete';
import { ListItem } from '../../../../types';

interface EditTableProps {
  onUpdateElementLabel: (e: any) => void;
  onSetTableProps: (tableOptions: TableRowOption[]) => void;
  label: string;
  tableProps: TableRowOption[];
  lists: ListItem[] | undefined;
}

export type TableElementType =
  | 'text'
  | 'number'
  | 'date'
  | 'email'
  | 'radio'
  | 'select'
  | 'checkbox'
  | 'radio'
  | 'select'
  | 'checkbox';

export type TableRowOption = {
  listId?: number;
  elementType: TableElementType;
  label: string;
};

type TableRowContainerProps = {
  elementType: TableElementType;
  fieldLabel: string;
  onChange: (type: 'label' | 'elementType' | 'listId', value: string) => void;
  onDelete: () => void;
  lists: ListItem[];
  listId: number | undefined;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120
  }
}));

export const TableRowContainer: React.FC<TableRowContainerProps> = ({
  elementType,
  fieldLabel,
  onChange,
  onDelete,
  lists,
  listId
}) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      <TextField
        label="Sarake"
        value={fieldLabel}
        style={{ minWidth: 300 }}
        onChange={(e) => {
          onChange('label', e.target.value);
        }}
      />
      <Select
        onChange={(e) => {
          onChange('elementType', e.target.value as string);
        }}
        value={elementType}
        style={{ minWidth: 150, marginLeft: 8 }}
      >
        <MenuItem value="text">Teksti</MenuItem>
        <MenuItem value="number">Numero</MenuItem>
        <MenuItem value="date">Päivämäärä</MenuItem>
        <MenuItem value="radio">Radio</MenuItem>
        <MenuItem value="checkbox">Checkbox</MenuItem>
        <MenuItem value="select">Pudotusvalikko</MenuItem>
      </Select>

      {['radio', 'checkbox', 'select'].includes(elementType) && (
        <FormControl className={classes.formControl}>
          <InputLabel shrink id="list-picker-label">
            Lista
          </InputLabel>
          <Select
            value={listId ?? ''}
            labelId="list-picker-label"
            displayEmpty
            onChange={(e) => {
              onChange('listId', e.target.value as string);
            }}
          >
            {sortBy(lists, 'name').map((listElement: ListItem, i: number) => (
              <MenuItem
                color="primary"
                value={listElement.id}
                key={i + listElement.name}
              >
                {listElement.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <IconButton onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export const EditTable = ({
  onUpdateElementLabel,
  onSetTableProps,
  label,
  tableProps,
  lists
}: EditTableProps) => {
  return (
    <>
      <Box>
        <TextField
          label="Taulukon otsikko"
          value={label}
          onChange={onUpdateElementLabel}
          style={{ minWidth: 300 }}
        />
      </Box>
      <Box>
        {tableProps.map((row, i) => {
          const onChange = (
            type: 'label' | 'elementType' | 'listId',
            value: string
          ) => {
            const newRows = clone(tableProps);
            newRows[i] = { ...newRows[i], [type]: value };
            onSetTableProps(newRows);
          };
          return (
            <TableRowContainer
              elementType={row.elementType}
              fieldLabel={row.label}
              listId={row.listId}
              key={i}
              onChange={onChange}
              onDelete={() => {
                const newRows = clone(tableProps);
                newRows.splice(i, 1);
                onSetTableProps(newRows);
              }}
              lists={lists ?? []}
            />
          );
        })}
        <Button
          onClick={() => {
            onSetTableProps([
              ...tableProps,
              { elementType: 'text', label: '' }
            ]);
          }}
        >
          Uusi rivi
        </Button>
      </Box>
    </>
  );
};
export default EditTable;
