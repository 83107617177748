import { UserData } from './types';

// Delete first subdomain level, because the cookie is set on main ramiforms domain.
const formatDomain = () =>
  '.' + window.location.hostname.split('.').slice(1)?.join('.');

const getCookieByName = (name: string) => {
  return document.cookie
    .split('; ')
    .find((cookie) => cookie.startsWith(`${name}=`))
    ?.split('=')[1];
};

export const getAccessTokenCookie = () => {
  return getCookieByName('access_token');
};

export const deleteAccessTokenCookie = () => {
  const domain = formatDomain();
  document.cookie = `access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}`;
};

export const getUserCookie = () => {
  const user = getCookieByName('user');
  return user && (JSON.parse(atob(user)) as UserData);
};

export const deleteUserCookie = () => {
  const domain = formatDomain();
  document.cookie = `user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}`;
};
