import {
  Box,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
  TextField
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { ArrowForward } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

interface FormFilterViewProps {
  displayName?: string;
  columnName: string | null;
  handleInputFinished: (newValue: string) => void;
  handleChange: (newValue: string) => void;
  options?: any;
  loading?: boolean;
}

const getInputType = (columnName: string) =>
  columnName.includes('createdAt') ? 'date' : 'text';

export const FormFilterInput: React.FC<FormFilterViewProps> = ({
  columnName,
  displayName,
  handleInputFinished,
  handleChange,
  options,
  loading
}) => {
  const ref = useRef<any>();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (columnName && ref && ref.current) {
      ref?.current.focus();
    }
  }, [columnName]);
  if (!columnName) {
    return null;
  }

  const columnType = getInputType(columnName);

  const handleInputChange = (event: any, newInputValue?: any) => {
    setInputValue(newInputValue ? newInputValue : event.target.value);
    if (columnName === 'formName') {
      handleChange(event.target.value);
    }
  };

  const handleInputBlur = (value: any) => {
    handleInputFinished(value);
    setInputValue('');
  };

  const handleHighlightChange = (event: any, option: any) => {
    setInputValue(option ? option.formName : inputValue);
  };

  const onCloseHandler = (event: any) => {
    if (event.type === 'click') {
      setInputValue(event.target.value);
      submit();
      setInputValue('');
    }
  };

  const submit = () => {
    handleInputFinished(inputValue);
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography color="textPrimary">{displayName ?? columnName}:</Typography>
      {columnName !== 'formName' ? (
        <Input
          inputRef={ref}
          type={columnType}
          style={{ marginLeft: 8 }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter' || ev.key === 'Escape') {
              handleInputFinished((ev.target as any).value);
            }
          }}
          onBlur={(e: any) => {
            handleInputFinished(e.target.value);
          }}
          endAdornment={
            columnType === 'date' ? (
              <Tooltip title="Suodata">
                <InputAdornment position="end">
                  <IconButton onClick={submit}>
                    <ArrowForward />
                  </IconButton>
                </InputAdornment>
              </Tooltip>
            ) : null
          }
        />
      ) : (
        <Box>
          <Autocomplete
            {...{
              fullWidth: true,
              forcePopupIcon: false,
              disableClearable: true,
              options,
              loading,
              loadingText: 'Haetaan lomakkeita...',
              getOptionLabel: (option: any) => option.formName,
              selectOnFocus: true,
              onClose: onCloseHandler,
              noOptionsText:
                inputValue.length < 3
                  ? 'Hakuun vaaditaan vähintää 3 merkkiä'
                  : `${inputValue} nimistä lomaketta ei löytynyt`,
              onInputChange: handleInputChange,
              onBlur: handleInputBlur,
              onHighlightChange: handleHighlightChange,
              renderInput: (params) => (
                <TextField
                  {...params}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter' || ev.key === 'Escape') {
                      submit();
                    }
                  }}
                  value={inputValue}
                  inputRef={ref}
                  style={{ marginLeft: 8, width: '200px' }}
                  variant="standard"
                />
              )
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default FormFilterInput;
