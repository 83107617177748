import { Chip } from '@material-ui/core';
import { DateTime } from 'luxon';
import React from 'react';
import { availableFilters } from './FormSubmitsContainer';

export interface FormFilter {
  columnName: string;
  value: string;
}

interface FormFilterViewProps {
  filters: FormFilter[];
  handleDelete: (index: number) => void;
}

const formatFilterValue = (filterColumnName: string, value: string) => {
  return ['createdAtStart', 'createdAtEnd'].includes(filterColumnName)
    ? DateTime.fromISO(value).toFormat('dd.MM.yyyy')
    : value;
};

export const FormFilterView: React.FC<FormFilterViewProps> = ({
  filters,
  handleDelete
}) => {
  return (
    <>
      {filters.map((filter, i) => {
        return (
          <Chip
            style={{ marginRight: 8 }}
            label={`${
              availableFilters.find((f) => f.column === filter.columnName)
                ?.label ?? filter.columnName
            }: ${formatFilterValue(filter.columnName, filter.value)}`}
            key={i + filter.columnName}
            onDelete={() => {
              handleDelete(i);
            }}
          />
        );
      })}
    </>
  );
};
