import { Box, Button, ButtonGroup, Grid, Typography } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import ContentContainer from '../ContentContainer';
import { DbGroup, FormGroupNode } from '../types';
import { buildGroupHierarchy, searchTree } from '../utils';
import { useApi } from './DynamicForms/fetchHook';
import {
  ExpandMore,
  ChevronRight,
  CreateNewFolder,
  Edit,
  ArrowRightAlt,
  Delete
} from '@material-ui/icons';
import { Alert, TreeView } from '@material-ui/lab';
import PromptDialog from './PromptDialog';
import { createGroup, deleteGroup, updateGroup } from '../requests';
import CircularProgressIndicator from './CircularProgressIndicator';
import { AlertSnackbar } from '../Alert';
import GroupTree from './GroupTree';

const GroupEditView: React.FC = () => {
  const [reqCount, setReqCount] = React.useState(0);
  const [groupsResponse, isLoading, error] = useApi<{
    groups: DbGroup[];
  }>(`/api/groups?_cache_clearer=${reqCount}`);
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string>('');
  const [newGroupDialogOpen, setNewGroupDialogOpen] = React.useState(false);
  const [renameGroupDialogOpen, setRenameGroupDialogOpen] =
    React.useState(false);
  const [moveGroupSource, setMoveGroupSource] = React.useState<string | null>(
    null
  );
  const [moveGroupTarget, setMoveGroupTarget] = React.useState<string | null>(
    null
  );
  const [isMoveTargetActive, setIsMoveTargetActive] = React.useState(false);
  const [deleteGroupDialogOpen, setDeleteGroupDialogOpen] =
    React.useState(false);
  const [showFeedback, setShowFeedback] = React.useState<string | null>(null);

  const reloadGroups = () => {
    setReqCount(reqCount + 1);
  };
  const handleSelect = (event: ChangeEvent<{}>, nodeId: string) => {
    setSelected(nodeId);
    if (isMoveTargetActive) {
      setMoveGroupTarget(nodeId);
    }
  };
  const handleToggle = (event: ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  React.useEffect(() => {
    if (groupsResponse?.groups) {
      setExpanded(groupsResponse.groups.map((g) => g.id.toString()));
    }
  }, [groupsResponse]);
  const tree: FormGroupNode = groupsResponse
    ? buildGroupHierarchy((groupsResponse as any).groups)
    : { name: '', groupId: '', children: [], parent: null };

  const buildAlertText = () => {
    if (isMoveTargetActive) {
      if (!moveGroupTarget) {
        return `Valitse kansio johon halua siirtää kansion ${
          searchTree(tree, moveGroupSource)?.name
        }`;
      } else {
        return `Olet siirtämässä kansiota ${
          searchTree(tree, moveGroupSource)?.name
        } kansioon ${searchTree(tree, moveGroupTarget)?.name}`;
      }
    }
  };
  const reset = () => {
    setSelected('');
    setMoveGroupSource(null);
    setMoveGroupTarget(null);
    setIsMoveTargetActive(false);
  };
  return (
    <>
      <PromptDialog
        open={newGroupDialogOpen}
        setOpen={setNewGroupDialogOpen}
        title="Uusi kansio"
        body={`Lisää uusi kansio. Kansio liitetään kansioon ${
          searchTree(tree, selected)?.name
        } `}
        onAccept={async (s) => {
          if (s) {
            await createGroup(s, Number(selected));
            reloadGroups();
          }
        }}
      />
      <PromptDialog
        open={renameGroupDialogOpen}
        setOpen={setRenameGroupDialogOpen}
        title="Nimeä kansio uudelleen"
        body="Syötä uusi nimi kansiolle"
        defaultValue={searchTree(tree, selected)?.name ?? ''}
        onAccept={async (s) => {
          const current = searchTree(tree, selected);
          await updateGroup(Number(selected), s ?? '', Number(current?.parent));
          reloadGroups();
        }}
      />
      <PromptDialog
        open={deleteGroupDialogOpen}
        setOpen={setDeleteGroupDialogOpen}
        title="Poista kansio"
        body={`Haluatko varmasti poistaa kansion ${
          searchTree(tree, selected)?.name
        }?`}
        onAccept={async () => {
          await deleteGroup(Number(selected));
          reloadGroups();
          reset();
        }}
        hasInput={false}
      />
      <AlertSnackbar
        severity="error"
        open={showFeedback !== null}
        message={showFeedback ?? ''}
        handleClose={() => {
          setShowFeedback(null);
        }}
      />
      <ContentContainer>
        <Typography variant="h5" color="textPrimary">
          Kansioiden muokkaus
        </Typography>
        <Box marginTop={2} marginBottom={2} overflow="auto">
          <ButtonGroup>
            <Button
              color="primary"
              startIcon={<CreateNewFolder />}
              disabled={isMoveTargetActive || selected === ''}
              onClick={() => {
                setNewGroupDialogOpen(true);
              }}
            >
              Lisää kansio
            </Button>
            <Button
              color="primary"
              startIcon={<Edit />}
              disabled={isMoveTargetActive || selected === ''}
              onClick={() => {
                setRenameGroupDialogOpen(true);
              }}
            >
              Nimeä kansio
            </Button>
            <Button
              color="primary"
              startIcon={<ArrowRightAlt />}
              disabled={isMoveTargetActive || selected === ''}
              onClick={() => {
                setMoveGroupSource(selected);
                setSelected('');
                setIsMoveTargetActive(true);
              }}
            >
              Siirrä kansio
            </Button>
            <Button
              color="primary"
              startIcon={<Delete />}
              disabled={isMoveTargetActive || selected === ''}
              onClick={async () => {
                setDeleteGroupDialogOpen(true);
              }}
            >
              Poista kansio
            </Button>
          </ButtonGroup>
        </Box>
        {isMoveTargetActive && (
          <Alert severity="info">
            {buildAlertText()}
            <Box marginTop={2}>
              <Button
                onClick={() => {
                  reset();
                }}
              >
                Peruuta siirto
              </Button>
              <Button
                disabled={moveGroupSource === null || moveGroupTarget === null}
                onClick={async () => {
                  const sourceNode = searchTree(tree, moveGroupSource);
                  if (sourceNode) {
                    // prevent moving a parent into a child
                    const maybeChildNode = searchTree(
                      sourceNode,
                      moveGroupTarget,
                      true
                    );
                    if (maybeChildNode) {
                      setShowFeedback(
                        'Kansiota ei voi siirtää sen suoraan alikansioon'
                      );
                      return;
                    }
                  }

                  await updateGroup(
                    Number(moveGroupSource),
                    sourceNode?.name ?? '',
                    Number(moveGroupTarget)
                  );
                  reloadGroups();
                  reset();
                }}
              >
                Hyväksy siirto
              </Button>
            </Box>
          </Alert>
        )}
        <Grid container>
          {error && <Alert severity="error">Virhe ladattaessa kansoita.</Alert>}
          <Grid item style={{ position: 'relative' }}>
            <CircularProgressIndicator isLoading={isLoading} size={48} />
            <TreeView
              defaultCollapseIcon={<ExpandMore />}
              defaultExpandIcon={<ChevronRight />}
              expanded={expanded}
              selected={selected}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
            >
              {groupsResponse?.groups && <GroupTree group={tree} />}
            </TreeView>
          </Grid>
        </Grid>
      </ContentContainer>
    </>
  );
};

export default GroupEditView;
