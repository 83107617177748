import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { LoadingState } from '../types';

interface CircularProgressIndicatorProps {
  isLoading: LoadingState;
  size?: number;
}

const useStyles = makeStyles(() => {
  return createStyles({
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    }
  });
});

const CircularProgressIndicator: React.FC<CircularProgressIndicatorProps> = ({
  isLoading,
  size = 24
}) => {
  const classes = useStyles();
  return isLoading === LoadingState.IsLoading ? (
    <CircularProgress size={size} className={classes.buttonProgress} />
  ) : null;
};

export default CircularProgressIndicator;
