import { Box, Button, IconButton, MenuItem, Select } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';
import { FillWithUserSettingsChocies } from '../../../../types';
import { clone } from '../../../../utils';
import { Element } from '../../AddForm';
interface SettingsProps {
  userInfoFields: FillWithUserSettingsChocies;
  onChange: (
    fieldName: string,
    userInfoFields: FillWithUserSettingsChocies
  ) => void;
  allElements: Element[];
}

const hasTextElementsWithoutIds = (elements: Element[]) => {
  return elements.some((elem) => !elem.id && elem.elementType === 'text');
};

const EditFillWithUserInfoSettings: React.FC<SettingsProps> = ({
  userInfoFields,
  onChange,
  allElements
}) => {
  return (
    <>
      <Box width="100%">
        {userInfoFields.map((userInfoField, i: number) => {
          return (
            <Box display="flex" key={userInfoField.value + i}>
              <Box flex={1}>
                <Select
                  style={{ width: '100%' }}
                  value={userInfoField.key}
                  onChange={(a) => {
                    const newFields = clone(userInfoFields);
                    newFields[i].key = a.target.value as string;
                    onChange('userInfo', newFields);
                  }}
                >
                  {allElements
                    .filter(
                      (elem) =>
                        ['email', 'number', 'text'].includes(
                          elem.elementType
                        ) && !!elem.id
                    )
                    .map((elem) => {
                      return (
                        <MenuItem value={elem.id} key={elem.id + elem.label}>
                          {elem.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Box>
              <Box flex={1}>
                <Select
                  style={{ width: '100%' }}
                  value={userInfoField.value}
                  onChange={(a) => {
                    const newFields = clone(userInfoFields);
                    newFields[i].value = a.target.value as string;
                    onChange('userInfo', newFields);
                  }}
                >
                  <MenuItem value="firstName">Etunimi</MenuItem>
                  <MenuItem value="firstName_lastName">Koko nimi</MenuItem>
                  <MenuItem value="phoneNumber">Puhelinnumero</MenuItem>
                  <MenuItem value="lastName">Sukunimi</MenuItem>
                  <MenuItem value="email">Sähköposti</MenuItem>
                </Select>
              </Box>
              <IconButton
                onClick={() => {
                  const newFields = clone(userInfoFields);
                  newFields.splice(i, 1);
                  onChange('userInfo', newFields);
                }}
                size="small"
              >
                <Delete />
              </IconButton>
            </Box>
          );
        })}
      </Box>
      <Button
        onClick={() => {
          const newFields = clone(userInfoFields);
          newFields.push({ key: '', value: '' });
          onChange('userInfo', newFields);
        }}
      >
        Lisää
      </Button>
      {hasTextElementsWithoutIds(allElements) && (
        <Alert severity="warning">
          Tallenna lomake ennen viitteiden muokkausta. Tallentamattomat viitteet
          eivät ole valittavissa.
        </Alert>
      )}
    </>
  );
};

export default EditFillWithUserInfoSettings;
