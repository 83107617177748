import {
  Box,
  Typography,
  Button,
  MenuItem,
  Menu,
  Divider
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useRef, useState } from 'react';
import ContentContainer from '../ContentContainer';
import { FormSubmitsGetResponse } from '../types';
import { clone, isAdminUser, isViewUser } from '../utils';
import { useApi } from './DynamicForms/fetchHook';
import FormFilterInput from './FormFilterInput';
import { FormFilter, FormFilterView } from './FormFilterView';
import FilterListIcon from '@material-ui/icons/FilterList';
import { getUserDataStorage } from '../storageHelper';
import { useHistory, useLocation } from 'react-router-dom';
import FormSubmitsMobile from './FormSubmitsMobile';
import FormSubmits from './FormSubmits';
import { isMobile, getActivePage, setActivePage } from '../utils';
import OnlyRoles from '../OnlyRoles';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { getJson, getText } from '../requests';
import FormIdPickerDialog from './FormIdPickerDialog';
import { DateTime } from 'luxon';
import CircularProgressIndicator from './CircularProgressIndicator';
import CustomPagination from '../CustomPagination';
import { debounce } from 'lodash';

const pageSize = 100;
const pageName = 'activeSubmitsPage';

const queryString = require('query-string');

const updateLocationSearch = (filters: FormFilter[]) => {
  const searchValues = filters.map((filter) => {
    return `${filter.columnName}=${filter.value}`;
  });
  return '?' + searchValues.join('&');
};

const parseLocationSearch = (search: string) => {
  if (search.length === 0) {
    return [];
  }
  const searchObj = queryString.parse(search);
  return Object.entries(searchObj).map(([key, value]) => {
    const orderFilter = {
      value: value,
      columnName: key
    } as FormFilter;
    return orderFilter;
  });
};

const useFilters = (): [
  FormFilter[],
  (f: FormFilter) => void,
  (filterIndex: number) => void
] => {
  const location = useLocation();
  const [filters, setFilters] = useState<FormFilter[]>(
    parseLocationSearch(location.search)
  );
  const deleteFilter = (index: number) => {
    setFilters((prevFilters) => {
      const newFilters = clone(prevFilters);
      newFilters.splice(index, 1);
      return newFilters;
    });
  };
  const addFilter = (filter: FormFilter) => {
    setFilters((prevFilters) => {
      const newFilters = clone(prevFilters);
      newFilters.push(filter);
      return newFilters;
    });
  };
  return [filters, addFilter, deleteFilter];
};

const buildSubmitsQueryString = ({
  base = '',
  filters = [],
  start,
  end,
  submitted
}: {
  base: string;
  filters: FormFilter[];
  start?: number;
  end?: number;
  submitted?: boolean;
}) => {
  const qs = filters.map((f) => `${f.columnName}=${f.value.trim()}`).join('&');
  let queryString = `${base}?${qs}`;

  if (start !== undefined) {
    queryString += `&start=${start}`;
  }

  if (end !== undefined) {
    queryString += `&end=${end}`;
  }

  if (submitted !== undefined) {
    queryString += `&submitted=${submitted}`;
  }

  return queryString;
};

const allSubmitsHaveSameFormId = (submits: FormSubmitsGetResponse | null) => {
  if (submits && submits.results.length > 0) {
    const firstFormId = submits.results[0].formId;
    return submits.results.every((r) => r.formId === firstFormId);
  }
};

export const availableFilters = [
  { column: 'formId', label: 'Lomaketunniste', viewRole: 'user' },
  { column: 'formName', label: 'Lomakkeen nimi', viewRole: 'user' },
  { column: 'filledBy', label: 'Täyttäjä (s-posti)', viewRole: 'admin' },
  { column: 'filledByName', label: 'Täyttäjä (nimi)', viewRole: 'admin' },
  { column: 'createdAtStart', label: 'Alkava täyttöpäivä', viewRole: 'user' },
  { column: 'createdAtEnd', label: 'Viimeisin täyttöpäivä', viewRole: 'user' },
  { column: 'productId', label: 'Sarjanumero', viewRole: 'user' }
];

const getUniqueForms = (formList: FormSubmitsGetResponse | null): any => {
  if (!formList) {
    return [];
  }
  const formIds = new Set<string>();
  formList.results.forEach((f) => {
    formIds.add(f.formId);
  });
  const formIdsArray = Array.from(formIds);
  return formIdsArray
    .map(((f: any) => formList.results.find((x) => x.formId === f)) as any)
    .map((f: any) => ({ name: f?.formName, formId: f?.formId ?? '' }));
};

const FormSubmitsContainer: React.FC = () => {
  const [filters, addFilter, deleteFilter] = useFilters();
  const [activeFilterColumn, setColumn] = useState<string>('');
  const [isFilterMenuOpen, setMenuOpen] = useState(false);
  const [showFormIdPicker, setShowFormIdPicker] = useState(false);
  const [pagination, setPagination] = useState({
    count: 1,
    start: (getActivePage(pageName) - 1) * pageSize,
    end: getActivePage(pageName) * pageSize
  });
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any>([]);
  const menuButtonRef = useRef(null);
  const history = useHistory();
  const [formSubmitList, isLoading, error] = useApi<FormSubmitsGetResponse>(
    buildSubmitsQueryString({
      base: '/api/submits',
      filters: filters.concat({
        columnName: 'includeValuesByType',
        value: 'productId'
      }),
      start: pagination.start,
      end: pagination.end,
      submitted: true
    })
  );

  useEffect(() => {
    if (formSubmitList) {
      setPagination({
        ...pagination,
        count: formSubmitList?.totalCount ? formSubmitList?.totalCount : 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmitList]);

  useEffect(() => {
    setPagination({
      ...pagination,
      start: 0,
      end: pageSize
    });
    setActivePage(pageName, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem(pageName);
    };
  }, []);

  const handlePageChange = (event: any, page: any) => {
    const start = (page - 1) * pageSize;
    const end = (page - 1) * pageSize + pageSize;

    setPagination({ ...pagination, start: start, end: end });
    setActivePage(pageName, page);
  };

  const showPagination = () => {
    return (
      <CustomPagination
        count={pagination.count}
        activePage={getActivePage(pageName)}
        pageSize={pageSize}
        onPageChange={handlePageChange}
      />
    );
  };

  const toggleMenu = () => {
    setMenuOpen(!isFilterMenuOpen);
  };

  const fetchOptions = async (value: string) => {
    try {
      const options: FormSubmitsGetResponse = await getJson(
        buildSubmitsQueryString({
          base: '/api/submits',
          filters: filters.concat({
            columnName: 'formName',
            value
          }),
          submitted: true
        })
      );

      const filteredOptions = options.results.filter((item, index, self) => {
        return index === self.findIndex((t) => t.formName === item.formName);
      });

      return filteredOptions;
    } catch (error) {
      console.error(error);
    }
  };

  const getFormByFormId = (formId: string | null) => {
    return formSubmitList?.results.find((form) => form.formId === formId);
  };

  const getFirstFormId = () => {
    return formSubmitList?.results[0].formId;
  };

  const handleCsvRequest = async (targetFormId: string | null) => {
    if (!allSubmitsHaveSameFormId(formSubmitList) && targetFormId === null) {
      setShowFormIdPicker(true);
      return;
    }
    const _formId = targetFormId ?? getFirstFormId() ?? '';
    const csvFilterColumns = ['createdAtStart', 'createdAtEnd', 'formId'];
    const csvFilters = [...filters];
    if (!csvFilters.find((f) => f.columnName === 'formId')) {
      // no formId filter
      const formIdFilter: FormFilter = {
        columnName: 'formId',
        value: _formId
      };
      csvFilters.push(formIdFilter);
    }
    const queryString = buildSubmitsQueryString({
      base: '/api/csv',
      filters: csvFilters.filter((f) => csvFilterColumns.includes(f.columnName))
    });
    const csvString = await getText(queryString);
    const link = document.createElement('a');
    link.setAttribute(
      'href',
      encodeURI('data:text/csv;charset=utf-8,\uFEFF' + csvString)
    );
    link.setAttribute(
      'download',
      getFormByFormId(_formId)?.formName +
        '_' +
        DateTime.local().toFormat('dd-MM-yyyy-HH-mm') +
        '.csv'
    );
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatFilterDateString = (value: string) => {
    if (activeFilterColumn === 'createdAtStart')
      return new Date(value.replace(/-/g, '.')).toISOString();
    else return new Date(value.replace(/-/g, '.') + '.23:59:59').toISOString();
  };

  const debouncedOptions = debounce(async (value) => {
    setLoading(true);
    if (activeFilterColumn === 'formName' && value.length > 2) {
      const result = await fetchOptions(value);
      setOptions(result);
    }
    setLoading(false);
  }, 500);

  useEffect(() => {
    history.replace({
      search: updateLocationSearch(filters)
    });
  }, [filters, history]);

  if (error) {
    return (
      <ContentContainer>
        <Alert severity="error">Virhe ladattaessa lomakelistaa: {error}</Alert>
      </ContentContainer>
    );
  }
  const targetFilterName = availableFilters.find(
    (f) => f.column === activeFilterColumn
  )?.label;
  return (
    <>
      {showFormIdPicker && (
        <FormIdPickerDialog
          forms={getUniqueForms(formSubmitList)}
          isOpen={true}
          handleClose={() => {
            setShowFormIdPicker(false);
          }}
          handleFormPick={(formId) => {
            setShowFormIdPicker(false);
            handleCsvRequest(formId);
          }}
        />
      )}
      <ContentContainer>
        <Box marginBottom={4}>
          <Typography variant="h5" color="textPrimary">
            Lomakevastaukset
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            style={{ paddingLeft: 0 }}
            ref={menuButtonRef}
            aria-controls="filter-menu"
            aria-haspopup="true"
            onClick={toggleMenu}
            endIcon={<FilterListIcon />}
          >
            Suodata
          </Button>
          <Menu
            open={isFilterMenuOpen}
            id="filter-menu"
            anchorEl={menuButtonRef.current}
            onClose={() => setMenuOpen(false)}
          >
            {availableFilters
              .filter(
                (possibleLabel) =>
                  !filters
                    .map((f) => f.columnName)
                    .includes(possibleLabel.column)
              )
              .filter(
                (availableFilter) =>
                  isAdminUser() ||
                  isViewUser() ||
                  availableFilter.viewRole === 'user'
              )
              .map((val) => (
                <MenuItem
                  onClick={() => {
                    toggleMenu();
                    setColumn(val.column);
                  }}
                  value={val.column}
                  key={val.column}
                >
                  {val.label}
                </MenuItem>
              ))}
            <Divider />
            <OnlyRoles
              allowedRoles={[
                'RamiForms\\RamiForms admin',
                'RamiForms\\RamiForms View'
              ]}
            >
              <MenuItem
                onClick={() => {
                  const maybeActiveFiller = filters.findIndex(
                    (f) => f.columnName === 'filledBy'
                  );
                  if (maybeActiveFiller !== -1) {
                    deleteFilter(maybeActiveFiller);
                  }
                  const user = getUserDataStorage();
                  addFilter({
                    columnName: 'filledBy',
                    value: user.email
                  });
                  toggleMenu();
                }}
              >
                Omat täytöt
              </MenuItem>
            </OnlyRoles>
          </Menu>
          <FormFilterView
            filters={filters}
            handleDelete={(deleteIndex: number) => {
              deleteFilter(deleteIndex);
            }}
          />
          <FormFilterInput
            options={options}
            displayName={targetFilterName}
            columnName={activeFilterColumn}
            handleChange={debouncedOptions}
            loading={loading}
            handleInputFinished={(value) => {
              if (value.length > 0) {
                setOptions([]);
                addFilter({
                  columnName: activeFilterColumn as string,
                  value: ['createdAtStart', 'createdAtEnd'].includes(
                    activeFilterColumn
                  )
                    ? formatFilterDateString(value)
                    : value
                });
              }
              setColumn('');
            }}
          />
          <Box marginLeft="auto">
            <Button
              disabled={formSubmitList?.results.length === 0}
              startIcon={<DownloadIcon />}
              onClick={() => {
                handleCsvRequest(null);
              }}
            >
              .csv
            </Button>
          </Box>
        </Box>
        {formSubmitList ? (
          isMobile() ? (
            <FormSubmitsMobile
              isLoading={isLoading}
              formSubmitList={formSubmitList}
              showPagination={showPagination}
            />
          ) : (
            <FormSubmits
              isLoading={isLoading}
              formSubmitList={formSubmitList}
              showPagination={showPagination}
            />
          )
        ) : (
          <CircularProgressIndicator isLoading={isLoading} />
        )}
      </ContentContainer>
    </>
  );
};
export default FormSubmitsContainer;
