import {
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core';
import { isMobile } from '../../../utils';
import { MultipleChoiceProps } from './ComponentTypes';
import InfoTooltip from './InfoTooltip';

const useStyles = makeStyles(() =>
  createStyles({
    width: {
      width: '100%'
    }
  })
);

const MultipleChoiceRadio: React.FC<MultipleChoiceProps> = ({
  label,
  info,
  options,
  onChange,
  className,
  error,
  name,
  value
}) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset" className={classes.width}>
      <FormLabel component="legend">
        <Typography
          color={error ? 'error' : 'primary'}
          style={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'nowrap'
          }}
        >
          {label}
          <InfoTooltip info={info} />
        </Typography>
      </FormLabel>
      <RadioGroup
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onChange={onChange}
        className={className}
        name={name}
        value={value}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems={isMobile() ? 'flex-end' : 'flex-start'}
        >
          {options.map((entry, i) => (
            <FormControlLabel
              key={entry.id}
              color="primary"
              value={entry.value}
              control={<Radio color="primary" />}
              label={entry.label}
              labelPlacement={isMobile() ? 'start' : 'end'}
            />
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
};

export { MultipleChoiceRadio };
