import {
  createStyles,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import React from 'react';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { ElementType, EntryType, ListItem } from '../../../types';
import EditText from './Components/EditText';
import { Option, Element } from '../AddForm';
import EditNumber from './Components/EditNumber';
import EditDate from './Components/EditDate';
import EditFile from './Components/EditFile';
import EditHeader from './Components/EditHeader';
import EditDivider from './Components/EditDivider';
import EditElementSettings from './EditElementSettings';
import EditRadio from './Components/EditMultipleChoice';
import EditMultipleChoice from './Components/EditMultipleChoice';
import EditEmail from './Components/EditEmail';
import EditTextarea from './Components/EditTextarea';
import EditLinkedList from './Components/EditLinkedList';
import EditDrawing from './Components/EditDrawing';
import EditFillWithUserInfo from './Components/EditFillWithUserInfo';
import EditTable from './Components/EditTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    idField: { marginBottom: theme.spacing(1) },
    commonControlsContainer: {
      marginTop: theme.spacing(4)
    },
    removePlaceholder: { width: 48 },
    elementSelect: { marginBottom: theme.spacing(3), width: '100%' },
    updateOrderIdSelect: { marginBottom: theme.spacing(3), width: 50 }
  })
);

interface CommonControlsProps {
  onClickRemoveElement: () => void;
  onCopyElement: () => void;
  onToggleRequired: () => void;
  required: boolean;
  hideDelete: boolean;
  hideRequired: boolean;
}

const CommonControls = ({
  onClickRemoveElement,
  onCopyElement,
  onToggleRequired,
  required,
  hideDelete,
  hideRequired
}: CommonControlsProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.commonControlsContainer}
    >
      {hideRequired && (
        <FormControlLabel
          control={
            <Switch
              checked={required}
              onChange={onToggleRequired}
              color="primary"
            />
          }
          label="Pakollinen"
        />
      )}
      <IconButton onClick={onCopyElement}>
        <FilterNoneIcon />
      </IconButton>
      {hideDelete ? (
        <div className={classes.removePlaceholder} />
      ) : (
        <IconButton onClick={onClickRemoveElement}>
          <DeleteOutlineRoundedIcon />
        </IconButton>
      )}
    </Grid>
  );
};

type ElementSelect = {
  label: string;
  value: ElementType;
};

const elements: ElementSelect[] = [
  { label: 'Checkbox', value: ElementType.Checkbox },
  { label: 'Email', value: ElementType.Email },
  { label: 'Käyttäjän tiedot', value: ElementType.UserInfo },
  { label: 'Linkitetty lista', value: ElementType.LinkedList },
  { label: 'Numero', value: ElementType.Number },
  { label: 'Otsikko', value: ElementType.Header },
  { label: 'Piirtoalue', value: ElementType.Drawing },
  { label: 'Poikkiviiva', value: ElementType.Divider },
  { label: 'Pudotusvalikko', value: ElementType.Dropdown },
  { label: 'Päivämäärä', value: ElementType.Date },
  { label: 'Radio', value: ElementType.Radio },
  { label: 'Sarjanumero', value: ElementType.ProductId },
  { label: 'Taulukko', value: ElementType.Table },
  { label: 'Teksti', value: ElementType.Text },
  { label: 'Tekstialue', value: ElementType.Textarea },
  { label: 'Tiedosto', value: ElementType.File }
];

interface ElementTypeSelectProps {
  onUpdateElementType: (e: any) => void;
  elementType: ElementType;
  entryCount: number;
}
const ElementTypeSelect = ({
  onUpdateElementType,
  elementType,
  entryCount
}: ElementTypeSelectProps) => {
  const classes = useStyles();
  const disabled = entryCount > 0;
  return (
    <Tooltip
      title={
        disabled
          ? 'Elementillä on vastauksia, joten sen tyyppiä ei voi vaihtaa.'
          : ''
      }
    >
      <Select
        onChange={onUpdateElementType}
        disabled={disabled}
        value={elementType}
        className={classes.elementSelect}
      >
        {elements.map((element) => (
          <MenuItem key={element.label} value={element.value}>
            {element.label}
          </MenuItem>
        ))}
      </Select>
    </Tooltip>
  );
};

interface ElementContentProps {
  onUpdateElementLabel: (e: any) => void;
  onUpdateElementInfo: (e: any) => void;
  onAddOption: () => void;
  onRemoveOption: (index: number) => void;
  onUpdateOptionValue: (optionIndex: number, value: string) => void;
  onUpdateCommonList: (e: any) => void;
  onSetElementProp: (propKey: string, value: string | boolean | any[]) => void;
  label: string;
  info: string;
  listId?: number;
  lists?: ListItem[];
  elementType: ElementType;
  options?: Option[];
  props?: any;
}
const ElementContent = ({
  onUpdateElementLabel,
  onUpdateElementInfo,
  label,
  info,
  options,
  listId,
  lists,
  elementType,
  props,
  onAddOption,
  onRemoveOption,
  onUpdateOptionValue,
  onUpdateCommonList,
  onSetElementProp
}: ElementContentProps) => {
  switch (elementType) {
    case ElementType.Text:
      return (
        <EditText onUpdateElementLabel={onUpdateElementLabel} label={label} />
      );
    case ElementType.Number:
      return (
        <EditNumber onUpdateElementLabel={onUpdateElementLabel} label={label} />
      );
    case ElementType.Date:
      return (
        <EditDate onUpdateElementLabel={onUpdateElementLabel} label={label} />
      );
    case ElementType.File:
      return (
        <EditFile onUpdateElementLabel={onUpdateElementLabel} label={label} />
      );
    case ElementType.Header:
      return (
        <EditHeader onUpdateElementLabel={onUpdateElementLabel} label={label} />
      );
    case ElementType.LinkedList:
      return (
        <EditLinkedList
          onUpdateCommonList={onUpdateCommonList}
          onUpdateElementLabel={onUpdateElementLabel}
          label={label}
          listId={listId}
          commonLists={lists || []}
        />
      );
    case ElementType.Divider:
      return <EditDivider />;
    case ElementType.Dropdown:
      return (
        <EditMultipleChoice
          onUpdateElementLabel={onUpdateElementLabel}
          onUpdateElementInfo={onUpdateElementInfo}
          onUpdateOptionValue={onUpdateOptionValue}
          onAddOption={onAddOption}
          onRemoveOption={onRemoveOption}
          options={options ?? []}
          label={label}
          info={info}
        />
      );
    case ElementType.Radio:
      return (
        <EditRadio
          onUpdateElementLabel={onUpdateElementLabel}
          onUpdateElementInfo={onUpdateElementInfo}
          onUpdateOptionValue={onUpdateOptionValue}
          onAddOption={onAddOption}
          onRemoveOption={onRemoveOption}
          options={options ?? []}
          label={label}
          info={info}
        />
      );
    case ElementType.Email:
      return (
        <EditEmail onUpdateElementLabel={onUpdateElementLabel} label={label} />
      );
    case ElementType.Textarea:
      return (
        <EditTextarea
          onUpdateElementLabel={onUpdateElementLabel}
          label={label}
        />
      );
    case ElementType.Checkbox:
      return (
        <EditMultipleChoice
          onUpdateElementLabel={onUpdateElementLabel}
          onUpdateElementInfo={onUpdateElementInfo}
          onUpdateOptionValue={onUpdateOptionValue}
          onAddOption={onAddOption}
          onRemoveOption={onRemoveOption}
          options={options ?? []}
          label={label}
          info={info}
        />
      );
    case ElementType.ProductId:
      return (
        <EditNumber onUpdateElementLabel={onUpdateElementLabel} label={label} />
      );
    case ElementType.Drawing:
      return (
        <EditDrawing
          onUpdateElementLabel={onUpdateElementLabel}
          label={label}
        />
      );
    case ElementType.UserInfo:
      return <EditFillWithUserInfo />;
    case ElementType.Table:
      const onSetTableProps = (tableOptions: any[]) => {
        onSetElementProp('tableOptions', tableOptions);
      };
      return (
        <EditTable
          onUpdateElementLabel={onUpdateElementLabel}
          onSetTableProps={onSetTableProps}
          label={label}
          lists={lists}
          tableProps={
            props?.tableOptions ?? [{ label: '', elementType: 'text' }]
          }
        />
      );
    default:
      return <Typography>Unsupported element type: {elementType}</Typography>;
  }
};

interface ElementControlsProps {
  onClickRemoveElement: () => void;
  onUpdateElementType: (e: any) => void;
  onSetElementProp: (propKey: string, value: string | boolean | any[]) => void;
  onCopyElement: () => void;
  onToggleRequired: () => void;
  allElements: Element[];
  options?: Option[];
  elementType: ElementType;
  props: any;
  entryCount: number;
  required: boolean;
  hideDelete: boolean;
  hideRequired: boolean;
}
const ElementControls = ({
  onClickRemoveElement,
  onUpdateElementType,
  onSetElementProp,
  onCopyElement,
  onToggleRequired,
  allElements,
  options,
  elementType,
  props,
  entryCount,
  required,
  hideDelete,
  hideRequired
}: ElementControlsProps) => {
  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="space-around"
        alignItems="flex-start"
      >
        <ElementTypeSelect
          entryCount={entryCount}
          elementType={elementType}
          onUpdateElementType={onUpdateElementType}
        />
        <EditElementSettings
          options={options}
          props={props}
          elementType={elementType}
          onSetElementProp={onSetElementProp}
          allElements={allElements}
        />
      </Grid>
      <Grid item>
        <CommonControls
          onClickRemoveElement={onClickRemoveElement}
          onCopyElement={onCopyElement}
          onToggleRequired={onToggleRequired}
          required={required}
          hideDelete={hideDelete}
          hideRequired={hideRequired}
        />
      </Grid>
    </>
  );
};

interface FormElementProps {
  label: string;
  info: string;
  entryType: EntryType;
  required: boolean;
  elementType: ElementType;
  options?: Option[];
  entryCount: number;
  props: any;
  hideDelete: boolean;
  hideRequired: boolean;
  orderId: number;
  listId?: number;
  lists?: ListItem[];
  elementId?: number;
  elementCount: number;
  allElements: Element[];
  onClickRemoveElement: () => void;
  onUpdateElementLabel: (e: any) => void;
  onUpdateElementInfo: (e: any) => void;
  onUpdateElementType: (e: any) => void;
  onAddOption: () => void;
  onRemoveOption: (index: number) => void;
  onUpdateOptionValue: (optionIndex: number, value: string) => void;
  onSetElementProp: (propKey: string, value: string | boolean | any[]) => void;
  onCopyElement: () => void;
  onToggleRequired: () => void;
  onAddNewElement: () => void;
  onUpdateOrderId: (e: any) => void;
  onUpdateCommonList: (e: any) => void;
}

const FormElement: React.FC<FormElementProps> = ({
  label,
  info,
  entryType,
  required,
  elementType,
  options,
  props,
  hideDelete,
  hideRequired,
  orderId,
  listId,
  lists,
  entryCount,
  elementCount,
  elementId,
  allElements,
  onToggleRequired,
  onClickRemoveElement,
  onUpdateElementLabel,
  onUpdateElementInfo,
  onUpdateElementType,
  onAddOption,
  onRemoveOption,
  onUpdateOptionValue,
  onSetElementProp,
  onCopyElement,
  onAddNewElement,
  onUpdateOrderId,
  onUpdateCommonList
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={4}
    >
      <Grid md={5} xs={12} item>
        <Typography className={classes.idField} color="textSecondary">
          #{elementId ?? ' - '}
        </Typography>
        <Select
          onChange={onUpdateOrderId}
          value={orderId}
          className={classes.updateOrderIdSelect}
        >
          {Array.from(Array(elementCount).keys()).map((number) => (
            <MenuItem key={number} value={number}>
              {number}
            </MenuItem>
          ))}
        </Select>
        <ElementContent
          onUpdateElementLabel={onUpdateElementLabel}
          onUpdateElementInfo={onUpdateElementInfo}
          label={label}
          info={info}
          elementType={elementType}
          listId={listId}
          lists={lists}
          props={props}
          onAddOption={onAddOption}
          onRemoveOption={onRemoveOption}
          options={options}
          onUpdateOptionValue={onUpdateOptionValue}
          onUpdateCommonList={onUpdateCommonList}
          onSetElementProp={onSetElementProp}
        />
      </Grid>
      <Grid md={4} xs={12} item>
        <ElementControls
          onSetElementProp={onSetElementProp}
          onClickRemoveElement={onClickRemoveElement}
          onUpdateElementType={onUpdateElementType}
          onCopyElement={onCopyElement}
          onToggleRequired={onToggleRequired}
          elementType={elementType}
          props={props}
          entryCount={entryCount}
          required={required}
          hideDelete={hideDelete}
          hideRequired={hideRequired}
          options={options}
          allElements={allElements}
        />
      </Grid>
    </Grid>
  );
};

export default FormElement;
