import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  makeStyles,
  createStyles,
  Typography,
  IconButton,
  Box,
  Chip,
  SvgIcon,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  DbGroup,
  LoadingState,
  SearchResultsResponse,
  Visibility
} from '../types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Favorite,
  Public,
  Visibility as VisibilityIcon,
  VisibilityOff
} from '@material-ui/icons';
import { FavoriteBorderOutlined } from '@material-ui/icons';
import {
  buildPathLabel,
  getSorter,
  isAdminUser,
  isViewUser,
  ramiColors,
  SortOrders,
  useLocalStorage
} from '../utils';
import OnlyRoles from '../OnlyRoles';
import FormGroups, { FormGroup } from './FormGroups';
import CircularProgressIndicator from './CircularProgressIndicator';
const useStyles = makeStyles((theme) =>
  createStyles({
    smallWidth: {
      width: 150
    },
    descMaxWidth: {
      maxWidth: 170
    },
    alignTop: {
      verticalAlign: 'top'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 180
    }
  })
);

interface FormListProps {
  formsList: SearchResultsResponse | null;
  activeGroups: FormGroup[];
  isLoading: LoadingState;
  handleToggleFavorite: (
    formId: string,
    isFavorite: boolean,
    formName: string
  ) => void;
  setActiveGroups: (groups: FormGroup[]) => void;
  formGroups: DbGroup[];
  orderHandler: (value: SortOrders) => void;
  showPagination: () => void;
}

const VisibilityIndicator: React.FC<{ variant: Visibility }> = ({
  variant
}) => {
  const visibilityText = {
    public: 'Näkyy kenelle vain koko internetissä',
    private: '',
    draft: 'Näkyy vain sinulle'
  };
  const visibilityIcons: any = {
    public: (
      <SvgIcon
        style={{ color: '#6e6e6e' }}
        color="inherit"
        fontSize="small"
        component={Public}
      />
    ),
    draft: (
      <SvgIcon
        style={{ color: '#6e6e6e' }}
        color="inherit"
        fontSize="small"
        component={VisibilityOff}
      />
    ),
    private: <></>
  };
  return (
    <>
      {visibilityIcons[variant]}
      <Typography
        color="textSecondary"
        variant="body2"
        style={{ marginLeft: 4 }}
      >
        {visibilityText[variant]}
      </Typography>
    </>
  );
};

const getHideGroupTreeOrDefault = () => {
  const stored = localStorage.getItem('hideGroupTree');
  if (stored === null) {
    return true;
  } else {
    return stored === 'true';
  }
};

const FormList: React.FC<FormListProps> = ({
  formsList,
  activeGroups,
  handleToggleFavorite,
  setActiveGroups,
  isLoading,
  formGroups,
  orderHandler,
  showPagination
}) => {
  const classes = useStyles();
  const [hideGroupTree, setHideGroupTree] = React.useState(
    getHideGroupTreeOrDefault()
  );
  const toggleFormGroupVisiblity = () => {
    const newVis = !hideGroupTree;
    setHideGroupTree(newVis);
    localStorage.setItem('hideGroupTree', newVis ? 'true' : '');
  };
  const [formSortOrder, setSortOrder] = useLocalStorage<SortOrders>(
    'sortOrder',
    'az'
  );

  useEffect(() => {
    orderHandler(formSortOrder);
    // eslint-disable-next-line
  }, [formSortOrder]);

  const handleSortChange = (e: any) => {
    setSortOrder(e.target.value as SortOrders);
    orderHandler(e.target.value);
  };

  const sorter = getSorter(formSortOrder);
  return (
    <div style={{ display: 'flex' }}>
      <Box style={{ borderRight: '1px solid #e0e0e0' }}>
        <Box paddingRight={hideGroupTree ? 4 : 2} style={{ overflowX: 'auto' }}>
          <IconButton onClick={toggleFormGroupVisiblity} size="small">
            {hideGroupTree ? <VisibilityOff /> : <VisibilityIcon />}
          </IconButton>
          {hideGroupTree && (
            <FormGroups
              selected={activeGroups[0]?.groupId}
              formGroups={formGroups}
              onGroupSelected={(g) => {
                setActiveGroups([g]);
              }}
            />
          )}
        </Box>
      </Box>
      <Box paddingLeft={4} width="100%">
        {activeGroups.length > 0 ? (
          <Chip
            label={buildPathLabel(activeGroups)}
            onDelete={() => {
              setActiveGroups([]);
            }}
          />
        ) : null}
        <Box display="flex" justifyContent="flex-end">
          <FormControl className={classes.formControl}>
            <InputLabel shrink id="order-by-sorter-label">
              Järjestä lomakkeet
            </InputLabel>
            <Select
              onChange={(e) => {
                handleSortChange(e);
              }}
              labelId="order-by-sorter-label"
              id="order-by-sorter"
              value={formSortOrder}
            >
              <MenuItem value="az">Aakkosjärjestys (A-Z)</MenuItem>
              <MenuItem value="za">Aakkosjärjestys (Z-A)</MenuItem>
              <MenuItem value="oldest">Uusin ensin</MenuItem>
              <MenuItem value="newest">Vanhin ensin</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {showPagination()}
        <TableContainer style={{ minHeight: 300 }}>
          <CircularProgressIndicator isLoading={isLoading} />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">ID</TableCell>
                <TableCell>Lomake</TableCell>
                <TableCell align="center">
                  {isAdminUser() || isViewUser()
                    ? 'Vastaukset'
                    : 'Omat vastaukset'}
                </TableCell>
                <OnlyRoles allowedRoles={['RamiForms\\RamiForms admin']}>
                  <TableCell />
                </OnlyRoles>
              </TableRow>
            </TableHead>
            <TableBody style={{ position: 'relative' }}>
              {sorter(formsList?.results ?? []).map((row) => (
                <TableRow key={row.formId}>
                  <TableCell style={{ width: 50 }}>
                    <IconButton
                      onClick={() => {
                        handleToggleFavorite(
                          row.formId,
                          row.isFavorite,
                          row.formName
                        );
                      }}
                    >
                      {row.isFavorite ? (
                        <Favorite
                          style={{ color: ramiColors.ramiOrangeLight }}
                        />
                      ) : (
                        <FavoriteBorderOutlined />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell
                    align="center"
                    scope="row"
                    className={classes.smallWidth}
                  >
                    <Link component={RouterLink} to={`/lomake/${row.formId}`}>
                      <Typography>{row.formId}</Typography>
                    </Link>
                  </TableCell>
                  <TableCell
                    scope="row"
                    className={classes.descMaxWidth + ' ' + classes.alignTop}
                  >
                    <Typography component="span" color="primary">
                      <Link component={RouterLink} to={`/lomake/${row.formId}`}>
                        {row.formName}
                      </Link>
                    </Typography>
                    <Typography variant="body2">{row.description}</Typography>
                    <Box display="flex" alignItems="center">
                      <VisibilityIndicator variant={row.visibility} />
                    </Box>
                  </TableCell>

                  <TableCell align="center" className={classes.smallWidth}>
                    <Link
                      component={RouterLink}
                      to={`/vastaukset?formId=${row.formId}`}
                    >
                      {row.submitCount}
                    </Link>
                  </TableCell>
                  <OnlyRoles allowedRoles={['RamiForms\\RamiForms admin']}>
                    <TableCell className={classes.smallWidth}>
                      <Link
                        component={RouterLink}
                        to={`/lomake/muokkaus/${row.formId}`}
                      >
                        Muokkaa
                      </Link>
                    </TableCell>
                  </OnlyRoles>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {showPagination()}
      </Box>
    </div>
  );
};
export default FormList;
