import { Box, Button } from '@material-ui/core';

const EditFillWithUserInfo = () => {
  return (
    <Box>
      <Button disabled variant="outlined">
        Täytä käyttäjän tiedoilla
      </Button>
    </Box>
  );
};
export default EditFillWithUserInfo;
