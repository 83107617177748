import {
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    elementPreview: { marginTop: theme.spacing(4), width: '70%' }
  })
);

const EditDivider = () => {
  const classes = useStyles();
  return (
    <>
      <Typography color="textSecondary" variant="h6">
        Poikkiviiva
      </Typography>
      <Divider className={classes.elementPreview} />
    </>
  );
};
export default EditDivider;
