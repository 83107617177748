import { Pagination } from '@material-ui/lab';
import { Box } from '@material-ui/core';

interface CustomPaginationProps {
  count: number;
  activePage: number;
  pageSize: number;
  onPageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  count,
  activePage,
  pageSize,
  onPageChange
}) => {
  const pageCount = Math.ceil(count / pageSize);
  if (pageCount > 1) {
    return (
      <Box display={'flex'} justifyContent={'center'} my={2}>
        <Pagination
          count={pageCount}
          color="primary"
          onChange={onPageChange}
          page={activePage}
        />
      </Box>
    );
  }
  return null;
};

export default CustomPagination;
