import {
  Typography,
  Link,
  Button,
  Grid,
  useMediaQuery
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AlertSnackbar } from '../Alert';
import React, { useState } from 'react';
import ContentContainer from '../ContentContainer';
import { addFavorite, buildQueryString, removeFavorite } from '../requests';
import { DbGroup, LoadingState, SearchResultsResponse } from '../types';
import { useApi } from './DynamicForms/fetchHook';
import { Link as RouterLink } from 'react-router-dom';
import { clone, getActivePage, setActivePage, SortOrders } from '../utils';
import FormListMobile from './FormListMobile';
import FormList from './FormList';
import { useEffect } from 'react';
import OnlyRoles from '../OnlyRoles';
import { FormGroup } from './FormGroups';
import CircularProgressIndicator from './CircularProgressIndicator';
import CustomPagination from '../CustomPagination';

const pageSize = 100;
const pageName = 'activeFormListPage';

const persistActiveGroups = (groups: FormGroup[]) => {
  localStorage.setItem('activeGroups', JSON.stringify(groups));
};

const getActiveGroups = (): FormGroup[] => {
  const activeGroups = localStorage.getItem('activeGroups');
  if (activeGroups) {
    return JSON.parse(activeGroups);
  }
  return [{ groupId: '1', name: 'Pääkansio' }];
};

const FormListContainer: React.FC = () => {
  const isMobileView = useMediaQuery('(max-width:640px)');
  const [orderBy, setOrderBy] = useState('title');
  const [order, setOrder] = useState('asc');
  const [pagination, setPagination] = useState({
    count: 1,
    start: (getActivePage(pageName) - 1) * pageSize,
    end: getActivePage(pageName) * pageSize
  });

  const getOrderParams = (value: SortOrders) => {
    if (value === 'az' || value === 'za') {
      setOrderBy('title');
      setOrder(value === 'az' ? 'asc' : 'desc');
    } else if (value === 'newest' || value === 'oldest') {
      setOrderBy('created_at');
      setOrder(value === 'newest' ? 'asc' : 'desc');
    }
  };

  const showPagination = () => {
    return (
      <CustomPagination
        count={pagination.count}
        activePage={getActivePage(pageName)}
        pageSize={pageSize}
        onPageChange={handlePageChange}
      />
    );
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem(pageName);
    };
  }, []);

  const [activeGroups, _setActiveGroups] = useState<FormGroup[]>(
    getActiveGroups()
  );
  const [formsResponse, formsIsLoading, formsError] =
    useApi<SearchResultsResponse>(
      buildQueryString({
        activeGroups,
        start: pagination.start,
        end: pagination.end,
        orderBy,
        order
      })
    );
  const [groupsResponse] = useApi<{
    groups: DbGroup[];
  }>('/api/groups');
  const [errorFavorite, setErrorFavorite] = useState('');
  const [formList, setformList] = useState<SearchResultsResponse | null>(null);

  useEffect(() => {
    if (formsResponse) {
      setformList(formsResponse);
      setPagination({
        ...pagination,
        count: formsResponse?.totalCount ? formsResponse?.totalCount : 0
      });
    }
    // eslint-disable-next-line
  }, [formsResponse]);

  const handlePageChange = (event: any, page: any) => {
    const start = (page - 1) * pageSize;
    const end = (page - 1) * pageSize + pageSize;

    setPagination({ ...pagination, start: start, end: end });
    setActivePage(pageName, page);
  };

  const handleToggleFavorite = async (
    formId: string,
    isFavorite: boolean,
    formName: string
  ) => {
    if (formList && formList.results) {
      const newFormList = clone(formList);
      const updatedList = {
        resultCount: newFormList.resultCount,
        results: newFormList.results.map((result) => {
          if (result.formId !== formId) {
            return result;
          }

          return { ...result, isFavorite: !result.isFavorite };
        })
      };
      setformList(updatedList);
      const favoriteToggleRollback = () => {
        const rollbackList = clone(updatedList);
        const objectToRollback = rollbackList.results.find(
          (result) => result.formId === formId
        );
        if (objectToRollback) {
          objectToRollback.isFavorite = !objectToRollback.isFavorite;
          setformList(rollbackList);
        }
      };
      try {
        if (isFavorite) {
          await removeFavorite(formId);
        } else {
          await addFavorite(formId);
        }
      } catch (error) {
        const errorRemoveText = `Lomakkeen "${formName}" poistaminen suosikeista epäonnistui.`;
        const errorAddText = `Lomakkeen "${formName}" lisääminen suosikiksi epäonnistui.`;
        setErrorFavorite(isFavorite ? errorRemoveText : errorAddText);
        favoriteToggleRollback();
      }
    }
  };

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorFavorite('');
  };

  const isFavoriteError = errorFavorite.length > 0;

  if (formsError) {
    return (
      <ContentContainer>
        <Alert severity="error">Virhe ladattaessa lomakelistaa</Alert>
      </ContentContainer>
    );
  }

  const setActiveGroups = (gs: FormGroup[]) => {
    persistActiveGroups(gs);
    _setActiveGroups(gs);
  };

  return (
    <>
      <AlertSnackbar
        severity="error"
        open={isFavoriteError}
        handleClose={handleCloseAlert}
        message={errorFavorite}
      />
      <ContentContainer>
        <Grid
          style={{ marginBottom: 32 }}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" color="textPrimary">
            Lomakelista
          </Typography>
          <OnlyRoles allowedRoles={['RamiForms\\RamiForms admin']}>
            <Link component={RouterLink} to="/lomake/uusi">
              <Button color="primary" variant="contained" size="small">
                Luo uusi
              </Button>
            </Link>
          </OnlyRoles>
        </Grid>
        {groupsResponse ? (
          isMobileView ? (
            <FormListMobile
              isLoading={formsIsLoading}
              activeGroups={activeGroups}
              setActiveGroups={setActiveGroups}
              formGroups={groupsResponse.groups}
              formsList={formList}
              handleToggleFavorite={handleToggleFavorite}
              showPagination={showPagination}
            />
          ) : (
            <FormList
              formGroups={groupsResponse.groups}
              isLoading={formsIsLoading}
              activeGroups={activeGroups}
              setActiveGroups={setActiveGroups}
              formsList={formList}
              handleToggleFavorite={handleToggleFavorite}
              orderHandler={getOrderParams}
              showPagination={showPagination}
            />
          )
        ) : (
          <CircularProgressIndicator isLoading={LoadingState.IsLoading} />
        )}
      </ContentContainer>
    </>
  );
};
export default FormListContainer;
