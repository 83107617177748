import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Theme,
  createStyles,
  Fade,
  Collapse,
  Box,
  Chip,
  Typography
} from '@material-ui/core';
import { ChevronRight, ExpandMore } from '@material-ui/icons';

import { Alert, Autocomplete, TreeView } from '@material-ui/lab';
import { DateTime } from 'luxon';
import React, { ChangeEvent, useEffect } from 'react';
import {
  DbGroup,
  ElementType,
  FormGroupNode,
  LoadingState,
  Visibility
} from '../../../types';
import { buildGroupHierarchy, ramiColors, searchTree } from '../../../utils';
import CircularProgressIndicator from '../../CircularProgressIndicator';
import GroupTree from '../../GroupTree';
import { Element } from '../AddForm';
import { useApi } from '../fetchHook';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visibilityContainer: {
      marginTop: theme.spacing(1)
    },
    settingField: {
      marginTop: theme.spacing(3)
    },
    dialogActions: {
      marginTop: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between'
    },
    fullWidth: { width: '100%' },
    shareLinkContainer: {
      padding: 8,
      maxWidth: 300,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    buttonColor: { color: ramiColors.red }
  })
);

interface FormSettingsProps {
  showFormSettings: boolean;
  elements: Element[];
  setShowFormSettings: (show: boolean) => void;
  onToggleIncludePdf: () => void;
  onToggleCreatePdf: () => void;
  onToggleSubjectOnlyValues: () => void;
  onSetEmailTo: (e: any) => void;
  onChangeVisibility: (e: any) => void;
  onSetIncludeInEmailSubject: (indices: number[]) => void;
  onSetGroups: (gropus: string[]) => void;
  onArchive: () => void;
  attachPdfToEmail: boolean;
  createPdf: boolean;
  visibility: Visibility;
  emailTo: string[];
  emailSubjectOnlyValues: boolean;
  groups: string[];
  isLoading: LoadingState;
  showSavedSubmitToUserOnly: boolean;
  onToggleShowSavedSubmitToUserOnly: () => void;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
}
const skipHeaderElements: Set<ElementType> = new Set([
  ElementType.Drawing,
  ElementType.Header,
  ElementType.File,
  ElementType.Divider,
  ElementType.Textarea
]);

const filterIncludeInEmailSubject = (element: Element) => {
  return !skipHeaderElements.has(element.elementType);
};

const NewAndCurrentFormGroupList: React.FC<{
  newGroups: string[];
  currentGroups: string[];
  tree: FormGroupNode;
}> = ({ newGroups, currentGroups, tree }) => {
  const current =
    newGroups.length > 0
      ? currentGroups.map((g) => (
          <Chip
            label={
              <span style={{ textDecoration: 'line-through' }}>
                {searchTree(tree, g)?.name}
              </span>
            }
          />
        ))
      : currentGroups.map((g) => <Chip label={searchTree(tree, g)?.name} />);
  const _new = newGroups.map((g) => <Chip label={searchTree(tree, g)?.name} />);
  return (
    <Box display="flex" alignItems="center">
      {current}
      {newGroups.length > 0 ? <ChevronRight /> : null}
      {_new}
    </Box>
  );
};

const FormSettings: React.FC<FormSettingsProps> = ({
  showFormSettings,
  elements,
  setShowFormSettings,
  onToggleIncludePdf,
  onToggleCreatePdf,
  onSetEmailTo,
  onChangeVisibility,
  onToggleSubjectOnlyValues,
  onSetIncludeInEmailSubject,
  onSetGroups,
  onArchive,
  attachPdfToEmail,
  createPdf,
  visibility,
  emailTo,
  emailSubjectOnlyValues,
  groups,
  isLoading,
  createdAt,
  createdBy,
  updatedBy,
  updatedAt,
  onToggleShowSavedSubmitToUserOnly,
  showSavedSubmitToUserOnly
}) => {
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState<string>('');
  const [showTree, setShowTree] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    React.useState(false);
  const toggleTree = () => {
    setShowTree(!showTree);
  };
  const handleSelect = (event: ChangeEvent<{}>, nodeId: string) => {
    setSelected(nodeId);
    setNewGroups([nodeId]);
  };
  const handleToggle = (event: ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  const [newGroups, setNewGroups] = React.useState<string[]>([]);
  const [groupsResponse] = useApi<{
    groups: DbGroup[];
  }>(`/api/groups`);
  React.useEffect(() => {
    if (groupsResponse?.groups) {
      setExpanded(groupsResponse.groups.map((g) => g.id.toString()));
    }
  }, [groupsResponse]);
  React.useEffect(() => {
    if (groups.length > 0) {
      setSelected(groups[0]);
    }
  }, [groups]);

  useEffect(() => {
    if (visibility === 'draft' && !showSavedSubmitToUserOnly) {
      onToggleShowSavedSubmitToUserOnly();
    }
    if (visibility === 'public' && showSavedSubmitToUserOnly) {
      onToggleShowSavedSubmitToUserOnly();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibility]);

  const tree: FormGroupNode = groupsResponse
    ? buildGroupHierarchy(groupsResponse.groups)
    : { name: '', groupId: '', children: [], parent: null };
  const classes = useStyles();
  return (
    <Dialog fullWidth maxWidth={'sm'} open={showFormSettings}>
      <DialogTitle>Lomakkeen asetukset</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="flex-start"
        >
          <Grid className={classes.visibilityContainer} container>
            <Grid item style={{ flex: 1 }}>
              <InputLabel> Näkyvyys</InputLabel>
              <Select
                onChange={onChangeVisibility}
                value={visibility}
                className={classes.fullWidth}
              >
                <MenuItem value="private">Kirjautuneet käyttäjät</MenuItem>
                <MenuItem value="draft">Vain sinä</MenuItem>
                <MenuItem value="public">
                  Julkinen (Kuka vain internet-käyttäjä)
                </MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              style={{
                alignItems: 'flex-end',
                marginLeft: 8,
                flex: 1,
                visibility: visibility === 'public' ? 'visible' : 'hidden'
              }}
            >
              <Alert severity="warning">
                Lomake on julkinen. Kuka tahansa kirjautumaton internet käyttäjä
                voi nähdä lomakkeen ja lähettää sen.
              </Alert>
            </Grid>
          </Grid>
          <Grid className={classes.settingField} item>
            <InputLabel>
              Lähetä vastaus osoitteisiin (erota pilkulla)
            </InputLabel>
            <TextField
              onChange={onSetEmailTo}
              value={emailTo.join(',')}
              className={classes.fullWidth}
            ></TextField>
          </Grid>
          <Grid className={classes.settingField} item>
            <InputLabel> Valitse sähköpostiotsikon kentät</InputLabel>
            <Autocomplete
              className={classes.fullWidth}
              multiple
              disableClearable={true}
              value={elements
                .map((element, index) => ({
                  label: element.label,
                  value: index,
                  includeInEmailSubject: element.includeInEmailSubject
                }))
                .filter((element) => element.includeInEmailSubject)}
              onChange={(e, allSelected) => {
                const indices = allSelected.map((selected) => selected.value);
                onSetIncludeInEmailSubject(indices ?? []);
              }}
              getOptionSelected={(o, value) => {
                return value.label === o.label && value.value === o.value;
              }}
              options={elements
                .map((element, index) => {
                  return { element, internalIndex: index };
                })
                .filter((mappableElement) => {
                  return filterIncludeInEmailSubject(mappableElement.element);
                })
                .map((elementWithIndex) => {
                  return {
                    label: elementWithIndex.element.label,
                    value: elementWithIndex.internalIndex
                  };
                })}
              getOptionLabel={(o) => {
                return o.label?.length > 0 ? o.label : `(Ei otsikkoa)`;
              }}
              renderInput={(params: any) => (
                <TextField {...params} margin="normal" />
              )}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              label="Näytetään valituista kentistä ainoastaan arvo"
              control={
                <Checkbox
                  onChange={onToggleSubjectOnlyValues}
                  checked={emailSubjectOnlyValues}
                  color="primary"
                />
              }
            ></FormControlLabel>
          </Grid>
          <Grid item>
            <FormControlLabel
              label="Luo vastauksesta PDF"
              control={
                <Checkbox
                  onChange={onToggleCreatePdf}
                  checked={createPdf}
                  color="primary"
                />
              }
            ></FormControlLabel>
          </Grid>
          <Fade in={createPdf}>
            <Grid item>
              <FormControlLabel
                label="Liitä PDF sähköpostiin"
                control={
                  <Checkbox
                    onChange={onToggleIncludePdf}
                    checked={attachPdfToEmail}
                    color="primary"
                  />
                }
              ></FormControlLabel>
            </Grid>
          </Fade>
          <Grid item>
            <FormControlLabel
              label="Näytä keskeneräinen lomake vain käyttäjälle"
              control={
                <Checkbox
                  onChange={onToggleShowSavedSubmitToUserOnly}
                  checked={showSavedSubmitToUserOnly}
                  color="primary"
                  disabled={['draft', 'public'].includes(visibility)}
                />
              }
            ></FormControlLabel>
          </Grid>
          <Box>
            <Typography>Lomake esiintyy kansioissa:</Typography>
            <NewAndCurrentFormGroupList
              newGroups={newGroups}
              currentGroups={groups}
              tree={tree}
            />
          </Box>
          <Box marginTop={1}>
            <Button onClick={toggleTree}>
              {showTree ? 'Piilota kansiorakenne' : 'Näytä kansiorakenne'}
            </Button>
          </Box>
          <Collapse in={showTree}>
            <Box marginTop={1}>
              <Button
                size="small"
                onClick={() => {
                  onSetGroups([selected]);
                  setNewGroups([]);
                }}
                color="primary"
                disabled={newGroups.length === 0}
              >
                Hyväksy muutos
              </Button>
              <Button
                size="small"
                onClick={() => setNewGroups([])}
                disabled={newGroups.length === 0}
              >
                Hylkää muutos
              </Button>
            </Box>
            <TreeView
              defaultCollapseIcon={<ExpandMore />}
              defaultExpandIcon={<ChevronRight />}
              expanded={expanded}
              selected={selected}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
            >
              {groupsResponse?.groups && <GroupTree group={tree} />}
            </TreeView>
          </Collapse>
        </Grid>
        <Box marginTop={4}>
          {(createdBy || createdAt || updatedBy || updatedAt) && (
            <Alert severity="info">
              {createdAt && createdBy && (
                <Typography>
                  {`Luotu: ${createdBy} ${DateTime.fromISO(createdAt)
                    .setZone('Europe/Helsinki')
                    .toFormat('dd.MM.yyyy HH:mm:ss')}`}
                </Typography>
              )}
              {updatedAt && updatedBy && (
                <Typography>
                  {`Viimeksi muokattu: ${updatedBy} ${DateTime.fromISO(
                    updatedAt
                  )
                    .setZone('Europe/Helsinki')
                    .toFormat('dd.MM.yyyy HH:mm:ss')}`}
                </Typography>
              )}
            </Alert>
          )}
        </Box>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.buttonColor}
            onClick={() => setShowDeleteConfirmation(true)}
          >
            Arkistoi lomake
          </Button>
          <Button onClick={() => setShowFormSettings(false)} color="primary">
            Sulje
          </Button>
        </DialogActions>
      </DialogContent>
      <Dialog open={showDeleteConfirmation}>
        <DialogTitle>Lomakkeen arkistointi</DialogTitle>
        <DialogContent>
          Haluatko varmasti arkistoida lomakkeen? Lomake poistuu näkyvistä
          kaikilta käyttäjiltä, eikä siihen voi enää vastata. Vastaukset ja
          lomakkeeseen liitetyt listat jäävät yhä näkyviin.
        </DialogContent>
        <DialogActions className={classes.settingField}>
          <Button
            onClick={() => setShowDeleteConfirmation(false)}
            color="primary"
          >
            Peruuta
          </Button>
          <Button
            className={classes.buttonColor}
            onClick={onArchive}
            disabled={!isLoading}
          >
            Arkistoi lomake
            <CircularProgressIndicator isLoading={isLoading} />
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default FormSettings;
