import {
  Button,
  Chip,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentContainer from '../ContentContainer';
import { getOrders } from '../requests';

import { Order } from '../types';
import { downloadOrderPdf } from './OrdersContainer';
import { formatRamiTurva, priceText, showDownloadButton } from './OrderTable';
import { OpenInNewDialog } from './OpenInNewDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginTop: theme.spacing(4)
    },
    divider: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
    downloadPdfButton: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1)
    }
  })
);

const fetchOrders = async (
  setOrders: (order: Order[]) => void,
  orderNumber: string
) => {
  try {
    setOrders([]);
    const ordersResponse = await getOrders([
      {
        param: 'ramiRentOrderNumber',
        value: orderNumber
      }
    ]);
    setOrders(ordersResponse);
  } catch (err) {
    console.error(err);
  }
};

const OrderRow = ({ label, value }: { label: string; value: string }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Typography>{label}</Typography>
        <Typography>{value}</Typography>
      </Grid>
      <Divider className={classes.divider} />
    </>
  );
};

const OrderView = () => {
  const { orderNumber } = useParams<{ orderNumber: string }>();
  const [orders, setOrders] = useState<Order[]>([]);
  const showOrderForm = localStorage.getItem('showOrderForm') === 'true';

  useEffect(() => {
    fetchOrders(setOrders, orderNumber);
  }, [orderNumber]);
  const order = orders[0];
  const [showDialog, setShowDialog] = React.useState(false);

  const handleClickOpen = () => {
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleCopyClick = () => {
    setShowDialog(false);
  };
  const classes = useStyles();
  return (
    <ContentContainer>
      <OpenInNewDialog
        show={showDialog}
        handleClose={handleClose}
        handleCopyClick={handleCopyClick}
        customerName={order?.customerName ?? ''}
        orderNumber={order?.ramiRentOrderNumber ?? ''}
        town={order?.town ?? ''}
        postalCode={order?.postalCode ?? ''}
        deliveryAddress={order?.deliveryAddress ?? ''}
      />

      <Grid container direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          className={classes.downloadPdfButton}
          size="small"
          onClick={handleClickOpen}
          disabled={!showOrderForm}
        >
          Kopioi tilaus
        </Button>
        {showDownloadButton(order?.created_at ?? '') && (
          <>
            <Button
              variant="contained"
              color="primary"
              className={classes.downloadPdfButton}
              size="small"
              onClick={() => {
                downloadOrderPdf(order?.ramiRentOrderNumber ?? '');
              }}
            >
              Lataa PDF
            </Button>
          </>
        )}
      </Grid>
      <OrderRow label="Tilausnumero" value={order?.ramiRentOrderNumber ?? ''} />
      <OrderRow label="Asiakas" value={order?.customerName ?? ''} />
      <OrderRow label="Toimipiste" value={order?.office ?? ''} />
      <OrderRow
        label="Päivämäärä"
        value={
          DateTime.fromISO(order?.created_at ?? '').toFormat(
            'dd.MM.yyyy HH:mm'
          ) ?? ''
        }
      />
      <OrderRow label="Työmaan nimi" value={order?.siteName ?? ''} />
      <OrderRow label="Työnumero" value={order?.siteId ?? ''} />
      <OrderRow
        label="Toimitusosoite"
        value={`${order?.deliveryAddress ?? ''}, ${order?.postalCode ?? ''} ${
          order?.town ?? ''
        }`}
      />
      <OrderRow label="Tilaaja" value={order?.orderer ?? ''} />
      <OrderRow label="Myyjä" value={order?.seller ?? ''} />

      <h3 className={classes.header}>Sähköpostit lähetetty</h3>
      <Typography>Myyjälle</Typography>
      <Chip style={{ overflowWrap: 'break-word' }} label={order?.sellerEmail} />

      <Typography>Asiakkaalle</Typography>
      <Chip label={order?.ordererEmail} />

      <h3 className={classes.header}>Tuotteet</h3>
      {order?.products.map((product) => {
        return (
          <div key={product.id}>
            <Grid container direction="column" justifyContent="flex-start">
              <Typography color="textPrimary">{product.productId}</Typography>
              <Typography color="primary">{`${product.name}`}</Typography>
              <Typography color="textPrimary">
                {priceText(product.price, product.perMonth)}
              </Typography>
              <Typography color="textPrimary">
                {`${product.amount ?? ''} ${product.unit}`}
              </Typography>
              <Typography color="textPrimary">
                {formatRamiTurva(product.ramiSafe)}
              </Typography>
              <Typography color="textSecondary">{product.info}</Typography>
            </Grid>
            <Divider className={classes.divider} />
          </div>
        );
      })}
    </ContentContainer>
  );
};

export default OrderView;
