import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import EditCommonLabel from './EditCommonLabel';

interface EditFileProps {
  onUpdateElementLabel: (e: any) => void;
  label: string;
}

const EditFile = ({ onUpdateElementLabel, label }: EditFileProps) => {
  return (
    <>
      <EditCommonLabel onChange={onUpdateElementLabel} value={label} />
      <Box marginTop={4}>
        <Button disabled>
          <Typography color="textSecondary"> + Valitse tiedosto</Typography>
        </Button>
      </Box>
    </>
  );
};
export default EditFile;
