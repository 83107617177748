import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { DataListEntry } from '../types';
import { clone, getLabel } from '../utils';

interface MobileProductAddProps {
  handleDismiss: () => void;
  show: boolean;
  handleProductAdd: (newProduct: any) => void;
  newRowTemplate: any;
  ramisafeOptions: DataListEntry[];
  unitOptions: string[];
}

const MobileProductAdd: React.FC<MobileProductAddProps> = ({
  show,
  handleProductAdd,
  newRowTemplate,
  handleDismiss,
  ramisafeOptions,
  unitOptions
}) => {
  const [formState, setFormState] = React.useState(clone(newRowTemplate));
  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    let valueToSet: string | boolean = '';
    if (name === 'permonth' || name === 'offerprice') {
      // permonth is a checkbox value (check)
      valueToSet = checked;
    } else {
      valueToSet = value;
    }
    setFormState((prevState: any) => ({ ...prevState, [name]: valueToSet }));
  };
  return (
    <Dialog
      fullScreen
      open={show}
      onClose={() => {
        handleDismiss();
      }}
    >
      <DialogTitle>Lisää rivi</DialogTitle>
      <DialogContent>
        <form>
          <Box display="flex" flexDirection="column">
            <TextField
              name="id"
              autoComplete="off"
              label={getLabel('Tuotenumero')}
              onChange={handleChange}
            />
            <TextField
              name="name"
              autoComplete="off"
              label="Tuotenimi"
              onChange={handleChange}
            />
            <Box display="flex" justifyContent="center">
              <FormControl component="fieldset">
                <Box marginTop={5} marginBottom={5}>
                  <FormLabel component="legend">RamiTurva</FormLabel>
                </Box>
                <RadioGroup
                  name="ramisafe"
                  defaultValue="eiRamiTurvaa"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFormState((prevstate: any) => ({
                      ...prevstate,
                      ramisafe: e.target.value
                    }));
                  }}
                >
                  {ramisafeOptions.map((ramisafe) => (
                    <FormControlLabel
                      key={ramisafe.value}
                      value={ramisafe.value}
                      control={<Radio color="primary" />}
                      label={ramisafe.label}
                      labelPlacement="start"
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <TextField
              autoComplete="off"
              name="amount"
              label="Määrä"
              onChange={handleChange}
            />
            <Autocomplete
              defaultValue="kpl"
              freeSolo
              options={unitOptions}
              onInputChange={(event, newInputValue) => {
                handleChange({
                  target: { name: 'unit', value: newInputValue }
                });
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Yksikkö"
                  margin="normal"
                  name="unit"
                  onChange={handleChange}
                />
              )}
            />
            <TextField
              name="price"
              type="text"
              label="Hinta, alv 0%"
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="permonth"
                  color="primary"
                  onChange={handleChange}
                />
              }
              label="Kuukausihinta"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="offerprice"
                  color="primary"
                  onChange={handleChange}
                />
              }
              label="Tarjouksen mukaan"
            />

            <TextField
              name="info"
              label="Huomiot"
              multiline={true}
              onChange={handleChange}
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDismiss} color="primary">
          Sulje lisäämättä
        </Button>
        <Button
          onClick={() => {
            handleProductAdd(formState);
            setFormState(clone(newRowTemplate));
            handleDismiss();
          }}
          color="primary"
          variant="contained"
        >
          Lisää rivi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MobileProductAdd;
