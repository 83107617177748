import {
  Box,
  Link,
  Typography,
  Grid,
  makeStyles,
  createStyles,
  IconButton
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { FormSubmitResult, LoadingState } from '../types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ramiColors, usePersistentScrollLocation } from '../utils';
import { Skeleton } from '@material-ui/lab';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useState } from 'react';
import { getUserDataStorage } from '../storageHelper';

interface FormSubmitsMobileProps {
  formSubmitList: FormSubmitResult[] | null;
  showPagination: () => void;
  isLoading?: LoadingState;
}

const useStyles = makeStyles(() =>
  createStyles({
    disabledRow: {
      background: ramiColors.ramiGray,
      opacity: 0.5
    }
  })
);

const FormIncompleteSubmitsMobile = ({
  formSubmitList,
  showPagination,
  isLoading
}: FormSubmitsMobileProps) => {
  const { search } = useLocation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const userEmail = getUserDataStorage().email;
  const rows = Array.from(Array(formSubmitList?.length).keys());
  usePersistentScrollLocation();

  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      {showPagination()}
      {isLoading === LoadingState.IsLoading
        ? rows.map((row) => (
            <Box key={row} mb={2}>
              <Skeleton variant="rect" height={70} />
            </Box>
          ))
        : formSubmitList?.map((row: FormSubmitResult) => {
            const isRowDisabled =
              row.isCurrentlyLocked.length > 1 &&
              row.isCurrentlyLocked !== userEmail;
            const textLength = row.comment.length > 60;
            const displayText = expanded
              ? row.comment
              : `${row.comment.slice(0, 60)}${textLength ? '...' : ''}`;

            const productIds = row.productId
              ?.filter(({ value }) => value)
              .map(({ value }) => value)
              .join(', ');
            return (
              <Box mb={2} className={isRowDisabled ? classes.disabledRow : ''}>
                <Link
                  onClick={(event: any) => {
                    if (isRowDisabled) {
                      event.preventDefault();
                    }
                  }}
                  key={`vastaus-${row.id}`}
                  component={RouterLink}
                  to={{
                    pathname: `/vastaus/${row.id}`,
                    search: search
                  }}
                >
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography color="textPrimary">{`${row.id} ${
                      productIds ? ` | ${productIds}` : ''
                    }`}</Typography>
                    <Typography color="textSecondary">
                      {DateTime.fromISO(row.updatedAt).toFormat(
                        'dd.MM.yyyy HH:mm'
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography color="primary">{`${row.formId} | ${row.formName}`}</Typography>
                  </Grid>

                  <Typography color="textPrimary">
                    {row.updatedByName}
                  </Typography>
                </Link>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Typography
                    color="textSecondary"
                    style={{ fontStyle: 'italic', textAlign: 'left' }}
                  >
                    {displayText}
                  </Typography>
                  {textLength ? (
                    <IconButton size="small" onClick={handleClick}>
                      {expanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  ) : null}
                </Box>
              </Box>
            );
          })}
      {showPagination()}
    </>
  );
};

export default FormIncompleteSubmitsMobile;
