import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps
} from 'react-router-dom';
import Main from './Main';
import Login from './Login';
import { isAuthenticated } from './utils';
import Topbar from './Topbar';
import OrderForm from './Forms/OrderForm';
import Orders from './Orders/OrdersContainer';
import ErrorBoundary from './ErrorBoundary';
import { setAccessTokenStorage, setUserDataStorage } from './storageHelper';
import { getAccessTokenCookie, getUserCookie } from './cookieHelper';
import { Form } from './Forms/DynamicForms/Form';
import FormListContainer from './Forms/FormListContainer';
import FormSubmitsContainer from './Forms/FormSubmitsContainer';
import EntriesView from './Forms/EntriesView';
import Order from './Orders/Order';
import NavigationBar from './NavigationBar';
import AddForm from './Forms/DynamicForms/AddForm';
import UpdateForm from './Forms/DynamicForms/UpdateForm';
import CopyForm from './Forms/DynamicForms/CopyForm';
import ListsView from './Forms/Lists/ListsView';
import EditListView from './EditListView';
import GroupEditView from './Forms/GroupEditView';
import { getVisibility } from './requests';
import { GetVisibilityResponse } from './types';
import OrderFormPreFilled from './Forms/OrderFormPreFilled';
import FormIncompleteSubmitsContainer from './Forms/FormIncompleteSubmitsContainer';
import AdminSettingsView from './AdminSettingsView';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  exact?: boolean;
  path: string;
}

const isFormPath = (path: string) => path.includes('/lomake/FI');

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const pathname = rest.location?.pathname;
  const [isPublicForm, setIsPublicForm] = useState(
    isFormPath(pathname ?? '') ? true : false
  );

  useEffect(() => {
    (async () => {
      if (isFormPath(pathname ?? '')) {
        try {
          const pathParts = pathname?.split('/') ?? [];
          const formId = pathParts[pathParts?.length - 1];
          const response = await getVisibility<GetVisibilityResponse>(formId);
          if (response.visibility !== 'public') {
            setIsPublicForm(false);
          }
        } catch (error) {
          console.log(error);
          setIsPublicForm(false);
        }
      } else {
        setIsPublicForm(false);
      }
    })();
  }, [pathname]);

  return isAuthenticated() || isPublicForm ? (
    <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        search:
          pathname && pathname.length > 1
            ? `?redirect=${rest.location?.pathname}`
            : undefined
      }}
    />
  );
};

const Routes = () => {
  // Old ramiforms auth integration. Load access token cookie if old ramiforms has set one.
  const token = getAccessTokenCookie();
  const user = getUserCookie();
  if (token && user) {
    setAccessTokenStorage(token);
    setUserDataStorage(user);
  }

  return (
    <>
      <Router>
        <Topbar />
        <ErrorBoundary>
          <NavigationBar>
            <Switch>
              <PrivateRoute exact path="/" component={Main} />
              <PrivateRoute exact path="/tilaustieto" component={OrderForm} />
              <PrivateRoute
                exact
                path="/tilaustieto/:orderId"
                component={OrderFormPreFilled}
              />
              <PrivateRoute exact path="/lomake/TTL" component={OrderForm} />
              <PrivateRoute exact path="/tilaukset" component={Orders} />
              <PrivateRoute
                exact
                path="/tilaus/:orderNumber"
                component={Order}
              />
              <PrivateRoute exact path="/lomake/uusi" component={AddForm} />
              <PrivateRoute exact path="/lomake/kopio/" component={CopyForm} />
              <PrivateRoute
                exact
                path="/lomake/muokkaus/:formId"
                component={UpdateForm}
              />

              <PrivateRoute exact path="/lomake/:formId" component={Form} />
              <PrivateRoute
                exact
                path="/lomakkeet"
                component={FormListContainer}
              />
              <PrivateRoute
                exact
                path="/vastaukset"
                component={FormSubmitsContainer}
              />
              <PrivateRoute
                exact
                path="/keskeneraiset"
                component={FormIncompleteSubmitsContainer}
              />
              <PrivateRoute
                exact
                path="/vastaus/:submitId"
                component={EntriesView}
              />
              <PrivateRoute exact path="/listat" component={ListsView} />
              <PrivateRoute
                exact
                path="/kansiomuokkaus"
                component={GroupEditView}
              />
              <PrivateRoute
                exact
                path="/listat/:listId"
                component={EditListView}
              />
              <PrivateRoute
                exact
                path="/asetukset"
                component={AdminSettingsView}
              />
              <Route component={Login} />
            </Switch>
          </NavigationBar>
        </ErrorBoundary>
      </Router>
    </>
  );
};

export default Routes;
