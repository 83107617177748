import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { clone } from 'lodash';
import React from 'react';
import { DataListEntry } from '../../../types';
import { isMobile } from '../../../utils';
import { MultipleChoiceProps } from './ComponentTypes';
import InfoTooltip from './InfoTooltip';

const useStyles = makeStyles(() =>
  createStyles({
    width: {
      width: '100%'
    }
  })
);

export type CheckboxGroupState = {
  optionId?: number;
  label: string;
  value: boolean;
}[];

const isChecked = (
  selectedValues: CheckboxGroupState,
  entry: DataListEntry
) => {
  const selected = selectedValues.find((i) => i.optionId === entry.id);
  return selected?.value ?? false;
};

const MultipleChoiceCheckbox: React.FC<MultipleChoiceProps> = ({
  label,
  info,
  options,
  onChange,
  className,
  error,
  value
}) => {
  const classes = useStyles();
  return (
    <FormControl component="fieldset" className={classes.width}>
      <FormLabel component="legend">
        <Typography
          color={error ? 'error' : 'primary'}
          style={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'nowrap'
          }}
        >
          {label}
          <InfoTooltip info={info} />
        </Typography>
      </FormLabel>
      <FormGroup className={className}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems={isMobile() ? 'flex-end' : 'flex-start'}
        >
          {options.map((entry, i) => {
            return (
              <FormControlLabel
                key={entry.value}
                color="primary"
                value={entry.value}
                control={
                  <Checkbox
                    color="primary"
                    checked={isChecked(value, entry)}
                    onChange={(e: any) => {
                      const newValues = clone(value);
                      const valueToChange = newValues.find(
                        (f: any) => f.label === e.target.value
                      );
                      valueToChange && (valueToChange.value = e.target.checked);
                      onChange && onChange(newValues);
                    }}
                  />
                }
                label={entry.label}
                labelPlacement={isMobile() ? 'start' : 'end'}
              />
            );
          })}
        </Grid>
      </FormGroup>
    </FormControl>
  );
};

export { MultipleChoiceCheckbox };
