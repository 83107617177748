import { Divider, makeStyles, TextField, Typography } from '@material-ui/core';
import {
  FormElement,
  FormElementsApiResponse
} from './FormComponents/ComponentTypes';
import { AutocompleteField } from './FormComponents/AutocompleteField';
import { Dropdown } from './FormComponents/Dropdown';
import { FileUpload } from './FormComponents/FileUpload';
import { MultipleChoiceRadio } from './FormComponents/MultiplceChoiceRadio';
import { MultipleChoiceCheckbox } from './FormComponents/MultipleChoiceCheckbox';
import Drawing from './FormComponents/Drawing';
import { FillWithUserInfo } from './FormComponents/FillWithUserInfo';
import { ElementType } from '../../types';
import UserFillableTable from './FormComponents/UserFillableTable';
import { ProductId } from './FormComponents/ProductId';

const buildLabel = (
  label: string | undefined,
  required: boolean | undefined
) => {
  if (label) {
    if (required) {
      return label + '*';
    } else {
      return label;
    }
  } else {
    return '';
  }
};

const mapElementType = (et: ElementType) => {
  switch (et) {
    case ElementType.ProductId:
      return 'number';
    default:
      return et;
  }
};

const defaultInputProps = {};

const mapInputProps = (et: ElementType) => {
  switch (et) {
    case ElementType.ProductId:
      return { min: 0 };
    default:
      return defaultInputProps;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 2,
    backgroundColor: theme.palette.primary.main
  }
}));

const RamiFormsDivider = () => {
  const classes = useStyles();
  return <Divider classes={{ root: classes.root }} />;
};

export const jsonComponentMapper = (
  prop: FormElement,
  onChange?: (...event: any[]) => void,
  value?: any,
  errors?: { [key: string]: any },
  setValue?: any,
  formData?: FormElementsApiResponse
) => {
  const label = buildLabel(prop.label, prop.required);
  switch (prop.elementType) {
    case ElementType.Number:
    case ElementType.Text:
    case ElementType.Email:
      return (
        <TextField
          onKeyPress={(e) => {
            // multiline components bind this prevent default automatically
            if (e.key === 'Enter' && !prop?.props?.multiline) {
              e.preventDefault();
            }
          }}
          onWheel={(e: any) => {
            e.target.blur();
          }}
          value={value}
          inputProps={mapInputProps(prop.elementType)}
          multiline={prop?.props?.multiline}
          className="form-dynamic-textfield"
          fullWidth
          label={label}
          type={mapElementType(prop.elementType)}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          name={prop.id.toString()}
          error={!!errors?.[prop.id]}
          helperText={errors?.[prop.id]?.message}
        />
      );
    case ElementType.ProductId:
      return (
        <ProductId
          label={label}
          onChange={onChange}
          errors={errors}
          value={value}
          prop={prop}
          setValue={setValue}
          formData={formData}
        />
      );
    case ElementType.Date:
      return (
        <TextField
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          className="form-dynamic-date"
          fullWidth
          label={label}
          type={prop.elementType}
          key={prop.id}
          InputLabelProps={{
            shrink: true
          }}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          name={prop.id.toString()}
          error={!!errors?.[prop.id]}
          value={value}
        />
      );
    case ElementType.Textarea:
      return (
        <TextField
          value={value}
          className="form-dynamic-textarea"
          fullWidth
          label={label}
          multiline
          rowsMax={10}
          variant="outlined"
          key={prop.id}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          name={prop.id.toString()}
          error={!!errors?.[prop.id]}
        />
      );
    case ElementType.Dropdown:
      return (
        <Dropdown
          className="form-dynamic-dropdown"
          label={label}
          info={prop.info?.text}
          options={prop.options ?? []}
          key={prop.id}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          error={!!errors?.[prop.id]}
          value={value}
        />
      );
    case ElementType.LinkedList:
      return (
        <AutocompleteField
          className="form-dynamic-autocomplete"
          label={label}
          options={prop.options ?? []}
          key={prop.id}
          onChange={onChange}
          error={!!errors?.[prop.id]}
          value={value}
        />
      );
    case ElementType.Radio:
      return (
        <MultipleChoiceRadio
          className="form-dynamic-radio"
          label={label}
          info={prop.info?.text}
          options={prop.options ?? []}
          key={prop.id}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          error={!!errors?.[prop.id]}
          name={prop.id.toString()}
          value={value}
        />
      );
    case ElementType.Checkbox:
      return (
        <MultipleChoiceCheckbox
          className="form-dynamic-checkbox"
          label={label}
          info={prop.info?.text}
          options={prop.options ?? []}
          key={prop.id}
          onChange={onChange}
          error={!!errors?.[prop.id]}
          name={prop.id.toString()}
          value={value}
        />
      );
    case ElementType.Divider:
      return <RamiFormsDivider />;
    case ElementType.Header:
      return (
        <Typography variant={prop?.props?.fontSize} color="primary">
          {prop.label}
        </Typography>
      );
    case ElementType.File:
      return (
        <FileUpload
          onChange={onChange}
          label={label}
          id={prop.id}
          error={!!errors?.[prop.id]}
          value={value}
        />
      );
    case ElementType.Drawing:
      return (
        <Drawing
          label={label}
          error={errors?.[prop.id]}
          value={value}
          title={label}
          onSaveFinished={(url) => {
            onChange && onChange(url);
          }}
          onClear={() => {
            onChange && onChange(undefined);
          }}
        />
      );
    case ElementType.UserInfo:
      return <FillWithUserInfo userInfo={prop.props?.userInfo ?? []} />;
    case ElementType.Table:
      return (
        <UserFillableTable
          label={label}
          value={value}
          onChange={onChange}
          tableOptions={prop?.props?.tableOptions ?? []}
        />
      );
    default:
      return <div>Unsupported: {prop.elementType}</div>;
  }
};
