import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentContainer from '../../ContentContainer';
import LoadingSkeleton from '../../LoadingSkeleton';
import { getForm } from '../../requests';
import { GetFormResponse } from '../../types';
import AddForm, { EditState } from './AddForm';

const UpdateForm = () => {
  const { formId } = useParams<{ formId: string }>();
  const [form, setForm] = useState<EditState | null>(null);
  const [loadingForm, setLoadingForm] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    if (formId) {
      (async () => {
        try {
          setLoadingForm(true);
          const form = await getForm<GetFormResponse>(formId, false);
          const editStateForm: EditState = {
            formId: form.formId,
            formTitle: form.formName,
            formDescription: form.formDescription,
            emailTo: form.emailTo,
            createPdf: form.createPdf,
            showSavedSubmitToUserOnly: form.showSavedSubmitToUserOnly,
            attachPdfToEmail: form.attachPdfToEmail,
            visibility: form.visibility,
            emailSubjectOnlyValues: form.emailSubjectOnlyValues,
            createdBy: form.createdBy,
            createdAt: form.createdAt,
            updatedBy: form.updatedBy,
            updatedAt: form.updatedAt,
            elements: form.elements.map((element) => {
              return {
                id: element.id,
                ...(element.listId && { listId: element.listId }),
                orderId: element.orderId,
                label: element.label,
                info: element.info ?? { text: '' },
                entryType: element.entryType,
                entryCount: element.entryCount,
                props: element.props,
                required: element.required,
                elementType: element.elementType,
                includeInEmailSubject: element.includeInEmailSubject,
                ...(element.options && { options: element.options }),
                isDirty: false
              };
            }),
            groups: form.groups
          };
          setForm(editStateForm);
        } catch (error) {
          setIsError(true);
        } finally {
          setLoadingForm(false);
        }
      })();
    }
  }, [formId]);
  return (
    <>
      {isError && (
        <ContentContainer>
          <Alert severity="error">
            Virhe ladattaessa muokattavaa lomaketta
          </Alert>
        </ContentContainer>
      )}
      {formId && loadingForm && <LoadingSkeleton />}
      {form && <AddForm form={form} />}
    </>
  );
};

export default UpdateForm;
