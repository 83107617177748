import React, { memo, useReducer, useRef, useState } from 'react';
import ContentContainer from '../../ContentContainer';
import FormHeader from './Editing/FormHeader';
import FormElement from './Editing/FormElement';
import {
  ElementType,
  EntryType,
  EventTargetValue,
  GetFormElementResponse,
  GetFormOptionResponse,
  ListsResponse,
  LoadingState,
  Visibility
} from '../../types';
import { Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import {
  arrayHasSkippedEntries,
  clone,
  sortByNumeric,
  usePreventWindowUnload
} from '../../utils';
import FormSettings from './Editing/FormSettings';
import { deleteForm, saveFormBase, updateFormBase } from '../../requests';
import { AlertSnackbar } from '../../Alert';
import { Prompt, Redirect, useLocation, useParams } from 'react-router-dom';
import SubmitDialog from '../SubmitDialog';
import { getElementSettings } from './Editing/EditElementSettings';
import { pick } from 'lodash';
import { useApi } from './fetchHook';
import { getUserDataStorage } from '../../storageHelper';
import { useEffect } from 'react';

export interface Option extends GetFormOptionResponse {}

export interface UiOption extends Option {
  isAutofocusable?: boolean;
}
export interface Element extends GetFormElementResponse {
  options?: UiOption[];
  isDirty?: boolean;
}

export type EditState = {
  formId?: string;
  formTitle: string;
  formDescription: string;
  emailTo: string[];
  createPdf: boolean;
  attachPdfToEmail: boolean;
  visibility: Visibility;
  emailSubjectOnlyValues: boolean;
  elements: Element[];
  groups: string[];
  showSavedSubmitToUserOnly: boolean;
  updatedBy?: string;
  updatedAt?: string;
  createdBy?: string;
  createdAt?: string;
};

const newFormState: EditState = {
  formTitle: '',
  formDescription: '',
  emailTo: [],
  emailSubjectOnlyValues: false,
  createPdf: true,
  showSavedSubmitToUserOnly: false,
  attachPdfToEmail: false,
  visibility: 'private',
  elements: [
    {
      orderId: 0,
      entryCount: 0,
      label: '',
      info: { text: '' },
      entryType: EntryType.String,
      props: {},
      required: false,
      elementType: ElementType.Text,
      includeInEmailSubject: false,
      listId: undefined,
      isDirty: false
    }
  ],
  groups: ['1']
};

enum EditActions {
  AddElement,
  RemoveElement,
  UpdateElementLabel,
  UpdateElementInfo,
  UpdateElementType,
  AddOption,
  RemoveOption,
  UpdateOptionValue,
  SetElementProp,
  CopyElement,
  UpdateFormTitle,
  UpdateFormDescription,
  SetVisibility,
  SetEmailTo,
  ToggleCreatePdf,
  ToggleIncludePdf,
  ToggleSubjectOnlyValues,
  ToggleRequired,
  SetIncludeInEmailSubject,
  UpdateOrderId,
  UpdateCommonList,
  CleanFilth,
  SetGroups,
  SetElements,
  SetUpdatedBy,
  Reset,
  ToggleShowSavedSubmitToUserOnly
}

type ReducerAction =
  | { type: EditActions.AddElement; payload: number }
  | { type: EditActions.RemoveElement; payload: number }
  | {
      type: EditActions.UpdateElementLabel;
      payload: { index: number; value: string };
    }
  | {
      type: EditActions.UpdateElementInfo;
      payload: { index: number; value: string };
    }
  | {
      type: EditActions.UpdateElementType;
      payload: { index: number; value: ElementType };
    }
  | {
      type: EditActions.AddOption;
      payload: number;
    }
  | {
      type: EditActions.RemoveOption;
      payload: { elementIndex: number; optionIndex: number };
    }
  | {
      type: EditActions.UpdateOptionValue;
      payload: { elementIndex: number; optionIndex: number; value: string };
    }
  | {
      type: EditActions.SetElementProp;
      payload: {
        elementIndex: number;
        propKey: string;
        value: string | boolean | any[];
      };
    }
  | {
      type: EditActions.CopyElement;
      payload: number;
    }
  | {
      type: EditActions.UpdateFormTitle;
      payload: string;
    }
  | {
      type: EditActions.UpdateFormDescription;
      payload: string;
    }
  | {
      type: EditActions.SetVisibility;
      payload: Visibility;
    }
  | {
      type: EditActions.SetEmailTo;
      payload: string;
    }
  | {
      type: EditActions.ToggleCreatePdf;
    }
  | {
      type: EditActions.ToggleShowSavedSubmitToUserOnly;
    }
  | {
      type: EditActions.ToggleIncludePdf;
    }
  | {
      type: EditActions.ToggleSubjectOnlyValues;
    }
  | {
      type: EditActions.ToggleRequired;
      payload: number;
    }
  | {
      type: EditActions.SetIncludeInEmailSubject;
      payload: number[];
    }
  | {
      type: EditActions.UpdateCommonList;
      payload: { listId: number; elementIndex: number };
    }
  | {
      type: EditActions.UpdateOrderId;
      payload: {
        fromId: number;
        toId: number;
      };
    }
  | { type: EditActions.CleanFilth }
  | { type: EditActions.SetUpdatedBy; payload: string }
  | { type: EditActions.SetGroups; payload: string[] }
  | { type: EditActions.SetElements; payload: Element[] }
  | { type: EditActions.Reset; payload: EditState };

const MemoFormElement = memo(FormElement, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});

const hideRequiredElements: Set<ElementType> = new Set([
  ElementType.Header,
  ElementType.Divider,
  ElementType.Checkbox
]);

const cannotBeRequired = (element: Element) =>
  !hideRequiredElements.has(element.elementType);

const editFormReducer = (state: EditState, action: ReducerAction) => {
  switch (action.type) {
    case EditActions.AddElement: {
      const emptyElement: Element = {
        orderId: action.payload,
        label: '',
        info: { text: '' },
        entryType: EntryType.String,
        props: {},
        entryCount: 0,
        required: false,
        elementType: ElementType.Text,
        includeInEmailSubject: false,
        isDirty: true
      };
      const clonedElements = clone(state.elements);
      clonedElements.splice(action.payload, 0, emptyElement);
      return {
        ...state,
        elements: clonedElements.map((element, i) => ({
          ...element,
          orderId: i,
          isDirty: true
        }))
      };
    }
    case EditActions.RemoveElement: {
      return {
        ...state,
        elements: state.elements
          .filter((element, i) => i !== action.payload)
          .map((element, i) => ({ ...element, orderId: i, isDirty: true }))
      };
    }
    case EditActions.UpdateCommonList: {
      const clonedElements = clone(state.elements);
      clonedElements[action.payload.elementIndex].listId =
        action.payload.listId;
      clonedElements[action.payload.elementIndex].isDirty = true;
      return {
        ...state,
        elements: clonedElements
      };
    }
    case EditActions.UpdateElementLabel: {
      const clonedElements = clone(state.elements);
      clonedElements[action.payload.index].label = action.payload.value;
      clonedElements[action.payload.index].isDirty = true;
      return {
        ...state,
        elements: clonedElements
      };
    }
    case EditActions.UpdateElementInfo: {
      const clonedElements = clone(state.elements);
      clonedElements[action.payload.index].info.text = action.payload.value;
      clonedElements[action.payload.index].isDirty = true;
      return {
        ...state,
        elements: clonedElements
      };
    }
    case EditActions.UpdateElementType: {
      const { value, index } = action.payload;
      const clonedElements = clone(state.elements);
      clonedElements[index].elementType = value;
      clonedElements[index].isDirty = true;
      if (
        (action.payload.value === 'radio' ||
          action.payload.value === 'dropdown') &&
        (!clonedElements[index].options ||
          clonedElements[index]?.options?.length === 0)
      ) {
        const emptyOption: Option = {
          value: '',
          label: '',
          orderId: clonedElements[index]?.options?.length ?? 0
        };
        clonedElements[index].options = (
          state.elements[index].options ?? []
        ).concat(emptyOption);
      }
      return {
        ...state,
        elements: clonedElements
      };
    }
    case EditActions.AddOption: {
      const clonedElements = clone(state.elements);
      const emptyOption: UiOption = {
        value: '',
        label: '',
        orderId: clonedElements[action.payload]?.options?.length ?? 0,
        isAutofocusable: true
      };
      clonedElements[action.payload].isDirty = true;
      clonedElements[action.payload].options = (
        state.elements[action.payload].options ?? []
      ).concat(emptyOption);
      return {
        ...state,
        elements: clonedElements
      };
    }
    case EditActions.RemoveOption: {
      const clonedElements = clone(state.elements);
      const { optionIndex, elementIndex } = action.payload;
      clonedElements[elementIndex].isDirty = true;
      clonedElements[elementIndex].options = clonedElements[
        elementIndex
      ].options?.filter((option, i) => i !== optionIndex);
      return {
        ...state,
        elements: clonedElements
      };
    }
    case EditActions.UpdateOptionValue: {
      const clonedElements = clone(state.elements);
      const { optionIndex, elementIndex, value } = action.payload;
      clonedElements[elementIndex].isDirty = true;
      return {
        ...state,
        elements: clonedElements.map((element, elemIndex) => {
          return {
            ...element,
            options: element.options?.map((option, optIndex) => {
              return optIndex === optionIndex && elemIndex === elementIndex
                ? {
                    label: value,
                    value: value,
                    orderId: optIndex,
                    id: option.id
                  }
                : option;
            })
          };
        })
      };
    }
    case EditActions.SetElementProp: {
      const clonedElements = clone(state.elements);
      const { elementIndex, propKey, value } = action.payload;
      clonedElements[elementIndex].isDirty = true;
      return {
        ...state,
        elements: clonedElements.map((element, elemIndex) => {
          if (elemIndex !== elementIndex) {
            return element;
          }
          const clonedProps = clone(element.props);
          clonedProps[propKey] = value;
          return {
            ...element,
            props: clonedProps
          };
        })
      };
    }
    case EditActions.CopyElement: {
      const clonedElements = clone(state.elements);
      const copiedElement = clone(clonedElements[action.payload]);
      copiedElement.isDirty = true;
      clonedElements.forEach((elem) => {
        elem.options?.forEach((elem) => {
          elem.isAutofocusable = false;
        });
      });
      copiedElement.entryCount = 0;
      copiedElement.id = undefined;
      clonedElements.splice(action.payload + 1, 0, copiedElement);
      return {
        ...state,
        elements: clonedElements.map((element, index) => ({
          ...element,
          orderId: index
        }))
      };
    }
    case EditActions.UpdateFormTitle: {
      return {
        ...state,
        formTitle: action.payload
      };
    }
    case EditActions.UpdateFormDescription: {
      return {
        ...state,
        formDescription: action.payload
      };
    }
    case EditActions.SetVisibility: {
      return {
        ...state,
        visibility: action.payload
      };
    }
    case EditActions.SetUpdatedBy: {
      return {
        ...state,
        updatedBy: action.payload,
        updatedAt: new Date().toISOString()
      };
    }
    case EditActions.SetEmailTo: {
      const emails = action.payload.split(',').map((email) => {
        return email.trim();
      });
      const isArrayWithSingleString = () => {
        return emails.length === 1 && emails[0] === '';
      };
      return {
        ...state,
        emailTo: isArrayWithSingleString() ? [] : emails
      };
    }
    case EditActions.ToggleCreatePdf: {
      return {
        ...state,
        createPdf: state.createPdf ? false : true
      };
    }
    case EditActions.ToggleShowSavedSubmitToUserOnly: {
      return {
        ...state,
        showSavedSubmitToUserOnly: state.showSavedSubmitToUserOnly
          ? false
          : true
      };
    }
    case EditActions.ToggleIncludePdf: {
      return {
        ...state,
        attachPdfToEmail: state.attachPdfToEmail ? false : true
      };
    }
    case EditActions.ToggleSubjectOnlyValues: {
      return {
        ...state,
        emailSubjectOnlyValues: state.emailSubjectOnlyValues ? false : true
      };
    }
    case EditActions.ToggleRequired: {
      const clonedElements = clone(state.elements);
      clonedElements[action.payload].isDirty = true;
      clonedElements[action.payload].required = clonedElements[action.payload]
        .required
        ? false
        : true;
      return {
        ...state,
        elements: clonedElements
      };
    }
    case EditActions.UpdateOrderId: {
      const { toId, fromId } = action.payload;
      const clonedElements = clone(state.elements);
      const updatedElements = clonedElements.map((element) => {
        element.isDirty = true;
        if (toId < fromId) {
          if (element.orderId >= toId && element.orderId < fromId) {
            return {
              ...element,
              orderId: element.orderId + 1
            };
          } else {
            return element;
          }
        } else {
          if (element.orderId <= toId && element.orderId > fromId) {
            return {
              ...element,
              orderId: element.orderId - 1
            };
          } else {
            return element;
          }
        }
      });
      updatedElements[fromId].orderId = toId;
      if (
        arrayHasSkippedEntries(
          updatedElements.map((element) => element.orderId).sort()
        ) > -1
      ) {
        console.warn(
          'Error in element orderIds',
          updatedElements.map((element) => element.orderId),
          arrayHasSkippedEntries(
            updatedElements.map((element) => element.orderId).sort()
          )
        );
      }
      return {
        ...state,
        elements: sortByNumeric(updatedElements, 'orderId')
      };
    }
    case EditActions.SetIncludeInEmailSubject: {
      const clonedElements = clone(state.elements);
      const elems = clonedElements.map((element, index) => {
        if (action.payload.includes(index)) {
          return {
            ...element,
            includeInEmailSubject: true,
            isDirty: true
          };
        } else {
          return {
            ...element,
            includeInEmailSubject: false,
            isDirty: true
          };
        }
      });
      return {
        ...state,
        elements: elems
      };
    }
    case EditActions.CleanFilth: {
      const clonedElements = clone(state.elements);
      clonedElements.forEach((elem) => {
        elem.isDirty = false;
      });
      return {
        ...state,
        elements: clonedElements
      };
    }
    case EditActions.SetGroups: {
      return {
        ...state,
        groups: action.payload
      };
    }
    case EditActions.SetElements: {
      const updatedElements = action.payload.map((element) => {
        if (element.info === null) {
          return {
            ...element,
            info: (element.info = { text: '' })
          };
        } else return element;
      });
      return {
        ...state,
        elements: updatedElements
      };
    }
    case EditActions.Reset: {
      return action.payload;
    }
  }
};

const isUnsavedChanges = (initialState: EditState, currentState: EditState) => {
  return JSON.stringify(initialState) !== JSON.stringify(currentState);
};

const formatVisibility = (visibility: Visibility) => {
  switch (visibility) {
    case 'draft':
      return 'Vain minä';
    case 'private':
      return 'Kirjautuneet käyttäjät';
    case 'public':
      return 'Julkinen';
  }
};

const multipleChoiceElements = [
  'radio',
  'dropdown',
  'checkbox',
  'autocomplete'
];

const isMultipleChoiceElement = (elementType: ElementType) => {
  return multipleChoiceElements.includes(elementType);
};

const pickOptions = (options: UiOption[] | undefined) => {
  return options?.map((o) => ({
    orderId: o.orderId,
    label: o.label,
    value: o.value,
    id: o.id
  }));
};

const getAllowedPropertiesForElement = (type: ElementType) => {
  const allowedPropertiesForElement = getElementSettings(type).map(
    (setting: any) => setting.settingKey
  );
  if (type === ElementType.Table) {
    allowedPropertiesForElement.push('tableOptions');
  }
  return allowedPropertiesForElement;
};

const formatFormBase = (form: EditState) => {
  return {
    formId: form.formId,
    formTitle: form.formTitle,
    formDescription: form.formDescription,
    emailTo: form.emailTo,
    createPdf: form.createPdf,
    showSavedSubmitToUserOnly: form.showSavedSubmitToUserOnly,
    attachPdfToEmail: form.attachPdfToEmail,
    visibility: form.visibility,
    emailSubjectOnlyValues: form.emailSubjectOnlyValues,
    groups: form.groups,
    elements: form.elements.map((element) => {
      delete element.entryCount;
      return {
        ...element,
        options: isMultipleChoiceElement(element.elementType)
          ? pickOptions(element.options)
          : undefined,
        props: pick(
          element.props,
          getAllowedPropertiesForElement(element.elementType)
        )
      };
    })
  };
};

const useStyles = makeStyles((theme) => ({
  isDirtyEditableElement: {
    backgroundColor: '#fffadc'
  },
  editableFormContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  formHeaderContainer: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    boxShadow: '0px 10px 10px 3px rgb(0 0 0 / 5%)'
  },
  chip: {
    transition: '.15s all linear',
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    }
  }
}));

// used to flag if we are clicking last element in which case the page should scroll to the bottom. Otherwise do nothing
let scrollToBottom = false;

const AddForm = ({ form }: { form: EditState }) => {
  const [initialState, setInitialState] = useState(form || newFormState);
  const [state, dispatch] = useReducer(editFormReducer, initialState);
  const [showFormSettings, setShowFormSettings] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [redirectToUpdateForm, setRedirectToUpdateForm] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showDeleteSuccessDialog, setShowDeleteSuccessDialog] = useState(false);
  const [listsResponse] = useApi<ListsResponse>('/api/lists');
  const [isSaveLoading, setIsLoading] = useState<LoadingState>(
    LoadingState.Initial
  );
  const [isArchiveLoading, setIsArchiveLoading] = useState<LoadingState>(
    LoadingState.Initial
  );
  const [formId, setFormId] = useState(
    useParams<{ formId: string }>().formId ?? null
  );
  const location = useLocation();
  const classes = useStyles();
  const bottomScrollToRef = useRef<HTMLSpanElement>(null);

  const scrollPageToBottom = () => {
    bottomScrollToRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  React.useEffect(() => {
    if (scrollToBottom) {
      scrollPageToBottom();
      scrollToBottom = false;
    }
  }, [state.elements.length]);
  const forceRefresh = usePreventWindowUnload(
    isUnsavedChanges(initialState, state)
  );

  useEffect(() => {
    if (form) {
      dispatch({ type: EditActions.Reset, payload: form });
      setInitialState(form);
    }
  }, [form]);

  const onSave = async () => {
    try {
      setIsLoading(LoadingState.IsLoading);
      const formBase = formatFormBase(state);
      if (formId) {
        const formUpdateResponse = await updateFormBase(formBase);
        dispatch({
          type: EditActions.SetElements,
          payload: formUpdateResponse.elements
        });
      } else {
        const response = await saveFormBase(formBase);
        setFormId(response.formId);
      }
      setShowSuccessDialog(true);
      setInitialState(state);
      dispatch({ type: EditActions.CleanFilth });
    } catch (err: any) {
      formatError(err.message.message);
    } finally {
      setIsLoading(LoadingState.Initial);
    }
  };

  const onArchive = async () => {
    try {
      setIsArchiveLoading(LoadingState.IsLoading);
      await deleteForm(formId);
      setShowFormSettings(false);
      setShowDeleteSuccessDialog(true);
    } catch (err: any) {
      setErrorMessage(err.message.message ?? '');
    } finally {
      setIsArchiveLoading(LoadingState.Initial);
    }
  };

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage('');
  };

  if (redirectToReferrer) {
    return <Redirect to="/lomakkeet" />;
  }

  if (redirectToUpdateForm) {
    return <Redirect to={`/lomake/muokkaus/${formId}`} />;
  }

  const formatError = (message: string) => {
    if (message.includes('is not allowed to be empty')) {
      setErrorMessage(
        `Monivalinta elementtien vaihtoehdot eivät saa olla tyhjiä`
      );
    } else if (message.includes('contains a duplicate value')) {
      setErrorMessage(
        `Monivalinta elementeissä ei saa olla useampaa samaa arvoa`
      );
    } else {
      setErrorMessage(message ?? '');
    }
  };

  const isError = errorMessage.length > 0;
  return (
    <>
      <Prompt
        when={isUnsavedChanges(initialState, state)}
        message="Lomakkeella on tallentamattomia muutoksia. Oletko varma, että haluat poistua?"
      />
      <AlertSnackbar
        severity="error"
        open={isError}
        message={errorMessage}
        handleClose={handleCloseAlert}
      />
      <SubmitDialog
        show={showSuccessDialog}
        handleDismiss={() => {
          setRedirectToReferrer(true);
        }}
        handleStay={() => {
          if (['uusi', 'kopio'].some((el) => location.pathname.includes(el))) {
            setRedirectToUpdateForm(true);
          } else {
            // this if else enables elements to have their ids properly set by reloading the whole page.
            // userinfo conmponent works on element ids which are not present, if the elements are new, since
            // unique ids are defined on the server, not in the frontend
            if (
              form.elements.some(
                (element) => element.elementType === 'userinfo'
              )
            ) {
              forceRefresh();
            } else {
              dispatch({
                type: EditActions.SetUpdatedBy,
                payload: getUserDataStorage().email
              });
              setShowSuccessDialog(false);
            }
          }
        }}
        headerText="Lomakepohja tallennettu"
        stayPageButtonText="Jatka muokkausta"
        leavePageButtonText="Palaa etusivulle"
        status="success"
      >
        <>
          <Typography>Lomaketunnus: {formId}</Typography>
          <Typography>
            Näkyvyys: {formatVisibility(state.visibility)}
          </Typography>
          <Typography>Nimi: {state.formTitle}</Typography>
          <Typography>Sähköpostit: {state.emailTo.join(', ')}</Typography>
          <Typography>
            Luodaanko pdf: {state.createPdf ? 'Kyllä' : 'Ei'}
          </Typography>
          <Typography>
            Liitetäänkö pdf sähköpostiin:
            {state.attachPdfToEmail ? 'Kyllä' : 'Ei'}
          </Typography>
          <Typography>Elementtien määrä: {state.elements.length}</Typography>
        </>
      </SubmitDialog>
      <SubmitDialog
        show={showDeleteSuccessDialog}
        handleDismiss={() => {
          setRedirectToReferrer(true);
        }}
        headerText="Lomakepohja arkistoitu"
        leavePageButtonText="Palaa etusivulle"
        status="success"
      >
        <Typography>Lomakepohja {formId} arkistoitu onnistuneesti</Typography>
      </SubmitDialog>
      <FormSettings
        showFormSettings={showFormSettings}
        elements={state.elements}
        setShowFormSettings={setShowFormSettings}
        attachPdfToEmail={state.attachPdfToEmail}
        createPdf={state.createPdf}
        visibility={state.visibility}
        emailTo={state.emailTo}
        emailSubjectOnlyValues={state.emailSubjectOnlyValues}
        isLoading={isArchiveLoading}
        onArchive={onArchive}
        updatedBy={state.updatedBy}
        updatedAt={state.updatedAt}
        createdBy={state.createdBy}
        createdAt={state.createdAt}
        showSavedSubmitToUserOnly={state.showSavedSubmitToUserOnly}
        onChangeVisibility={(e: any) =>
          dispatch({ type: EditActions.SetVisibility, payload: e.target.value })
        }
        onToggleIncludePdf={() =>
          dispatch({
            type: EditActions.ToggleIncludePdf
          })
        }
        onToggleCreatePdf={() =>
          dispatch({ type: EditActions.ToggleCreatePdf })
        }
        onToggleShowSavedSubmitToUserOnly={() =>
          dispatch({ type: EditActions.ToggleShowSavedSubmitToUserOnly })
        }
        onToggleSubjectOnlyValues={() =>
          dispatch({ type: EditActions.ToggleSubjectOnlyValues })
        }
        onSetEmailTo={(e: any) =>
          dispatch({ type: EditActions.SetEmailTo, payload: e.target.value })
        }
        onSetIncludeInEmailSubject={(indices: number[]) =>
          dispatch({
            type: EditActions.SetIncludeInEmailSubject,
            payload: indices
          })
        }
        onSetGroups={(groups) => {
          dispatch({
            type: EditActions.SetGroups,
            payload: groups
          });
        }}
        groups={state.groups}
      />
      <ContentContainer className={classes.formHeaderContainer}>
        <FormHeader
          onSave={onSave}
          isLoading={isSaveLoading}
          onUpdateFormTitle={(e: any) =>
            dispatch({
              type: EditActions.UpdateFormTitle,
              payload: e.target.value
            })
          }
          onUpdateFormDescription={(e: any) =>
            dispatch({
              type: EditActions.UpdateFormDescription,
              payload: e.target.value
            })
          }
          onClickSettings={setShowFormSettings}
          formTitle={state.formTitle}
          formDescription={state.formDescription}
        />
      </ContentContainer>
      {state.elements
        .sort((a, b) => a.orderId - b.orderId)
        .map((element: Element, elementIndex: number) => (
          <div key={elementIndex}>
            <ContentContainer
              className={
                element.isDirty
                  ? classes.isDirtyEditableElement +
                    ' ' +
                    classes.editableFormContainer
                  : classes.editableFormContainer
              }
            >
              <MemoFormElement
                entryCount={element?.entryCount ?? 0}
                orderId={element.orderId}
                label={element.label}
                info={element?.info?.text ?? ''}
                elementId={element.id}
                entryType={element.entryType}
                required={element.required}
                elementType={element.elementType}
                options={element.options}
                props={element.props}
                lists={listsResponse?.lists}
                listId={element.listId}
                hideDelete={state.elements.length === 1}
                hideRequired={cannotBeRequired(element)}
                elementCount={state.elements.length}
                allElements={state.elements}
                onUpdateOrderId={(() => {
                  return (e: any) => {
                    let targetId = e.target.value;
                    if (e.target.value > state.elements.length - 1) {
                      targetId = state.elements.length - 1;
                    }
                    if (e.target.value < 0) {
                      targetId = 0;
                    }
                    dispatch({
                      type: EditActions.UpdateOrderId,
                      payload: { fromId: elementIndex, toId: Number(targetId) }
                    });
                  };
                })()}
                onAddNewElement={() => {
                  dispatch({
                    type: EditActions.AddElement,
                    payload: elementIndex + 1
                  });
                }}
                onToggleRequired={() => {
                  dispatch({
                    type: EditActions.ToggleRequired,
                    payload: elementIndex
                  });
                }}
                onClickRemoveElement={() => {
                  dispatch({
                    type: EditActions.RemoveElement,
                    payload: elementIndex
                  });
                }}
                onUpdateElementLabel={(e: EventTargetValue) => {
                  dispatch({
                    type: EditActions.UpdateElementLabel,
                    payload: {
                      index: elementIndex,
                      value: e.target.value
                    }
                  });
                }}
                onUpdateElementInfo={(e: EventTargetValue) => {
                  dispatch({
                    type: EditActions.UpdateElementInfo,
                    payload: {
                      index: elementIndex,
                      value: e.target.value
                    }
                  });
                }}
                onUpdateElementType={(e: EventTargetValue) => {
                  dispatch({
                    type: EditActions.UpdateElementType,
                    payload: {
                      index: elementIndex,
                      value: e.target.value
                    }
                  });
                }}
                onUpdateCommonList={(e: EventTargetValue) => {
                  dispatch({
                    type: EditActions.UpdateCommonList,
                    payload: {
                      elementIndex: elementIndex,
                      listId: e.target.value
                    }
                  });
                }}
                onAddOption={() => {
                  dispatch({
                    type: EditActions.AddOption,
                    payload: elementIndex
                  });
                }}
                onRemoveOption={(optionIndex: number) => {
                  dispatch({
                    type: EditActions.RemoveOption,
                    payload: {
                      elementIndex: elementIndex,
                      optionIndex: optionIndex
                    }
                  });
                }}
                onUpdateOptionValue={(optionIndex: number, value: string) => {
                  dispatch({
                    type: EditActions.UpdateOptionValue,
                    payload: {
                      elementIndex: elementIndex,
                      optionIndex: optionIndex,
                      value: value
                    }
                  });
                }}
                onSetElementProp={(
                  propKey: string,
                  value: string | boolean | any[]
                ) => {
                  dispatch({
                    type: EditActions.SetElementProp,
                    payload: {
                      elementIndex: elementIndex,
                      propKey: propKey,
                      value: value
                    }
                  });
                }}
                onCopyElement={() => {
                  dispatch({
                    type: EditActions.CopyElement,
                    payload: elementIndex
                  });
                }}
              />
            </ContentContainer>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Chip
                className={classes.chip}
                onClick={() => {
                  if (elementIndex === state.elements.length - 1) {
                    scrollToBottom = true;
                  }
                  dispatch({
                    type: EditActions.AddElement,
                    payload: elementIndex + 1
                  });
                }}
                label="Lisää elementti"
                clickable
              />
            </Grid>
          </div>
        ))}
      <span ref={bottomScrollToRef} />
    </>
  );
};

export default AddForm;
