import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@material-ui/core';
import App from './App';
import AppUpdateProvider from './AppUpdateProvider';
import { initSentry } from './SentryConfig';

if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
  initSentry();
}

const theme = createTheme({
  overrides: {
    MuiLink: {
      root: {
        textDecoration: 'none'
      }
    },
    MuiInput: {
      root: {
        fontFamily: 'Arial'
      }
    },
    MuiOutlinedInput: {
      root: {
        fontFamily: 'Arial'
      }
    }
  },
  props: {},
  typography: {
    fontFamily: 'Boing'
  },
  palette: {
    primary: {
      main: '#003287'
    },
    secondary: {
      main: '#ffdc00'
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppUpdateProvider>
        <App />
      </AppUpdateProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
