import React, { memo, useContext, useEffect, useState } from 'react';
import ContentContainer from '../ContentContainer';
import {
  getOrderPdf,
  getCustomList,
  getOrders,
  getOrderCount
} from '../requests';
import {
  DataListEntry,
  Order,
  OrderFilter,
  FilterParam,
  FilterParamProps
} from '../types';
import OrderTable from './OrderTable';
import OrderFilters from './OrderFilters';
import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { clone, getUserFullName, isMobile } from '../utils';
import OrderTableMobile from './OrderTableMobile';
import { useHistory, useLocation } from 'react-router-dom';
import { EnvContext } from '../App';
const queryString = require('query-string');

const useStyles = makeStyles({
  loadingSpinner: {
    height: 200
  },
  emptyTableMessage: {
    height: 200
  }
});

const MemoOrderTable = memo(isMobile() ? OrderTableMobile : OrderTable);

const filterParams: FilterParamProps[] = [
  {
    value: 'ramiRentOrderNumber',
    label: 'Tilausnumero'
  },
  {
    value: 'siteId',
    label: 'Työnumero'
  },
  {
    value: 'seller',
    label: 'Myyjä'
  }
];

let intervalId: any = null;

const parseLocationSearch = (search: string) => {
  if (search.length === 0) {
    return [];
  }
  const searchObj = queryString.parse(search);
  return Object.entries(searchObj).map(([key, value]) => {
    const orderFilter = {
      value: value,
      param: key
    } as OrderFilter;
    return orderFilter;
  });
};

const updateLocationSearch = (filters: OrderFilter[]) => {
  const searchValues = filters.map((filter) => {
    return `${filter.param}=${filter.value}`;
  });
  return '?' + searchValues.join('&');
};

const useOrderFilters = (): [
  OrderFilter[],
  (value: OrderFilter) => void,
  (filterParam: FilterParam) => void,
  () => void
] => {
  const location = useLocation();
  const [orderFilters, setOrderFilters] = useState<OrderFilter[]>(
    parseLocationSearch(location.search)
  );

  const setOrderFilter = ({ param, value }: OrderFilter) => {
    let newFilters = clone(orderFilters);

    const filterIndex = newFilters.findIndex(
      (orderFilter) => orderFilter.param === param
    );

    const updateFilter = () => {
      newFilters[filterIndex].value = value;
      setOrderFilters(newFilters);
    };
    const addFilter = () => {
      newFilters = newFilters.concat({ param, value });
      setOrderFilters(newFilters);
    };

    filterIndex > -1 ? updateFilter() : addFilter();
  };

  const removeOrderFilter = (filterParam: FilterParam) => {
    const newFilters = orderFilters.filter(
      (orderFilter) => orderFilter.param !== filterParam
    );
    setOrderFilters(newFilters);
  };

  const resetOrderFilters = () => {
    setOrderFilters([]);
  };

  return [orderFilters, setOrderFilter, removeOrderFilter, resetOrderFilters];
};

const fetchOrders = async (
  setOrders: (order: Order[]) => void,
  orderFilters: OrderFilter[],
  setIsLoading: (val: boolean) => void,
  setError: (val: string) => void
) => {
  try {
    setOrders([]);
    setIsLoading(true);
    setError('');
    const ordersResponse = await getOrders(orderFilters);
    setOrders(ordersResponse);
  } catch (err) {
    console.error(err);
    setError('Tilauksien hakeminen epäonnistui');
  } finally {
    setIsLoading(false);
  }
};

const updateOrders = async (
  setOrders: (order: Order[]) => void,
  orderFilters: OrderFilter[]
) => {
  try {
    const ordersResponse = await getOrders(orderFilters);
    setOrders(ordersResponse);
  } catch (error) {
    console.error(error);
  }
};

const fetchOfficeList = async (
  listId: number,
  setOfficeList: (list: DataListEntry[]) => void
) => {
  const officeList = await getCustomList(listId);
  setOfficeList(officeList.data);
};

const fetchOrderCount = async () => {
  try {
    const orderCountResponse = await getOrderCount();
    return orderCountResponse.count;
  } catch (error) {
    console.error(error);
  }
};

export const downloadOrderPdf = async (orderNumber: string) => {
  const pdfBlob = await getOrderPdf(orderNumber);
  const file = window.URL.createObjectURL(pdfBlob);
  const a = document.createElement('a');
  a.href = file;
  a.setAttribute('download', orderNumber + '.pdf');
  a.click();
};

const isFilterEnabled = (
  orderFilters: OrderFilter[],
  filterParam: FilterParam
) => {
  const index = orderFilters.findIndex(
    (filter) => filter.param === filterParam
  );
  return index === -1 ? false : true;
};

const OrdersContainer = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [filterParam, setFilterParam] = useState<FilterParam>(
    'ramiRentOrderNumber'
  );
  const [filterValue, setFilterValue] = useState('');
  const [orderFilters, setOrderFilter, removeOrderFilter, resetOrderFilters] =
    useOrderFilters();
  const [ramiSmartFilter, setRamiSmartFilter] = useState(
    isFilterEnabled(orderFilters, 'sourceSystem')
  );
  const [myOrdersFilter, setMyOrdersFilter] = useState(
    isFilterEnabled(orderFilters, 'userName')
  );
  const [isLoading, setIsLoading] = useState(false);
  const [officeList, setOfficeList] = useState<DataListEntry[]>([]);
  const [officeFilter, setOfficeFilter] = useState('ShowAllOffices');
  const [error, setError] = useState('');
  const [orderCount, setOrderCount] = useState<number>();
  const history = useHistory();
  const userFullName = getUserFullName();

  useEffect(() => {
    history.replace({
      search: updateLocationSearch(orderFilters)
    });
  }, [orderFilters, history]);

  useEffect(() => {
    const updateOrderCount = async () => {
      const count = await fetchOrderCount();
      setOrderCount(count);
    };
    updateOrderCount();
  }, []);

  useEffect(() => {
    fetchOrders(setOrders, orderFilters, setIsLoading, setError);
  }, [orderFilters]);
  const envs = useContext(EnvContext);
  useEffect(() => {
    const commonListDbIds = envs?.TTL_LISTS?.split(',').map(Number) ?? [];
    if (commonListDbIds.length === 0) {
      return;
    }
    fetchOfficeList(commonListDbIds[0], setOfficeList);
  }, [envs]);

  useEffect(() => {
    clearInterval(intervalId);
    intervalId = setInterval(async () => {
      const newCount = await fetchOrderCount();
      if (orderCount !== newCount) {
        setOrderCount(newCount);
        updateOrders(setOrders, orderFilters);
      }
    }, 1000 * 3600);
    return () => clearInterval(intervalId);
  }, [orderCount, orderFilters]);

  const handleMyOrdersFilterToggle = (checked: boolean) => {
    if (checked) {
      setOrderFilter({
        param: 'userName',
        value: userFullName
      });
      setMyOrdersFilter(true);
    } else {
      removeOrderFilter('userName');
      setMyOrdersFilter(false);
    }
  };

  const handleRamiSmartFilterToggle = (checked: boolean) => {
    if (checked) {
      setOrderFilter({
        param: 'sourceSystem',
        value: 'ramiSmart'
      });
      setRamiSmartFilter(true);
    } else {
      removeOrderFilter('sourceSystem');
      setRamiSmartFilter(false);
    }
  };

  const handleOfficeChange = (value: string) => {
    setOfficeFilter(value);
    if (value === 'ShowAllOffices') {
      removeOrderFilter('office');
    } else {
      setOrderFilter({
        param: 'office',
        value: value
      });
    }
  };

  const handleResetClick = () => {
    resetOrderFilters();
    setOfficeFilter('ShowAllOffices');
    setRamiSmartFilter(false);
    setMyOrdersFilter(false);
    setFilterValue('');
  };

  const handleFilterSet = () => {
    setOrderFilter({
      param: filterParam,
      value: filterValue
    });
    setFilterValue('');
  };

  const handleFilterDelete = (orderFilter: OrderFilter) => {
    const { value, param } = orderFilter;
    removeOrderFilter(param);
    if (param === 'sourceSystem' && value === 'ramiSmart') {
      setRamiSmartFilter(false);
    }
    if (param === 'userName' && value === userFullName) {
      setMyOrdersFilter(false);
    }
    if (param === 'office' && value === officeFilter) {
      setOfficeFilter('ShowAllOffices');
    }
  };

  const classes = useStyles();

  return (
    <>
      <ContentContainer>
        <Box marginBottom={4}>
          <Typography variant="h5" color="textPrimary">
            Vastaanotetut tilaukset
          </Typography>
        </Box>
        <OrderFilters
          onFilterSet={handleFilterSet}
          onFilterParamChange={setFilterParam}
          onFilterValueChange={setFilterValue}
          onRamiSmartFilterToggle={handleRamiSmartFilterToggle}
          onMyOrdersFilterToggle={handleMyOrdersFilterToggle}
          onOfficeChange={handleOfficeChange}
          onResetFilterClick={handleResetClick}
          onFilterDelete={handleFilterDelete}
          filterValue={filterValue}
          filterParams={filterParams}
          ramiSmartFilter={ramiSmartFilter}
          myOrdersFilter={myOrdersFilter}
          officeList={officeList}
          officeFilter={officeFilter}
          orderFilters={orderFilters}
        />
      </ContentContainer>
      <ContentContainer>
        {!isLoading && orders.length > 0 && (
          <MemoOrderTable orders={orders} downloadOrderPdf={downloadOrderPdf} />
        )}
        {!error && !isLoading && orders.length === 0 && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.emptyTableMessage}
          >
            <div>Hakuehdoilla ei löytynyt yhtään tilausta</div>
          </Grid>
        )}
        {isLoading && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.loadingSpinner}
          >
            <CircularProgress />
          </Grid>
        )}
        {error && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.emptyTableMessage}
          >
            <div>{error}</div>
          </Grid>
        )}
      </ContentContainer>
    </>
  );
};

export default OrdersContainer;
