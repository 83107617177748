import { Box } from '@material-ui/core';
import EditCommonLabel from './EditCommonLabel';
interface EditDrawingProps {
  onUpdateElementLabel: (e: any) => void;
  label: string;
}

const EditDrawing = ({ onUpdateElementLabel, label }: EditDrawingProps) => {
  return (
    <>
      <EditCommonLabel onChange={onUpdateElementLabel} value={label} />
      <Box paddingTop={2} paddingBottom={2}>
        <img src="/static/gear-up.png" alt="Gear up" />
      </Box>
    </>
  );
};
export default EditDrawing;
