import React from 'react';
import { getJson } from '../../requests';
import { LoadingState } from '../../types';

export const useApi = <T>(
  url: string,
  conditionalFn?: () => boolean
): [T | null, LoadingState, any | null] => {
  const [data, setData] = React.useState<T | null>(null);
  const [loading, setLoading] = React.useState<LoadingState>(
    LoadingState.Initial
  );
  const [error, setError] = React.useState(null);
  React.useEffect(() => {
    if (conditionalFn && !conditionalFn()) {
      return;
    }
    let canceled = false;
    (async () => {
      try {
        setError(null);
        setLoading(LoadingState.IsLoading);
        const form = await getJson<T>(url);
        if (!canceled) {
          setData(form);
        }
      } catch (e: any) {
        setError(e.message);
      } finally {
        setLoading(LoadingState.Initial);
      }
    })();
    return () => {
      canceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);
  return [data, loading, error];
};
