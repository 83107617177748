import { Role, UserData } from './types';

export const setAccessTokenStorage = (token: string) =>
  localStorage.setItem('accessToken', token);

export const getAccessTokenStorage = () => localStorage.getItem('accessToken');

export const removeAccessTokenStorage = () =>
  localStorage.removeItem('accessToken');

export const setUserDataStorage = (userData: UserData) =>
  localStorage.setItem('user', JSON.stringify(userData));

export const getUserDataStorage = () =>
  JSON.parse(localStorage.getItem('user') || '{}') as UserData;

export const removeUserDataStorage = () => localStorage.removeItem('user');

export const setNavigationStorage = (navigation: number) =>
  localStorage.setItem('navigation', navigation.toString());

export const getNavigationStorage = () => {
  const navigation = localStorage.getItem('navigation');
  return navigation ? Number(navigation) : null;
};

export const setUserRolesStorage = (roles: Role[]) =>
  localStorage.setItem('roles', JSON.stringify(roles));

export const removeUserRolesStorage = () => localStorage.removeItem('roles');

export const getUserRolesStorage = () =>
  JSON.parse(localStorage.getItem('roles') || '[]') as Role[];

export const removeActiveGroupsStorage = () =>
  localStorage.removeItem('activeGroups');
