import React, { useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Box,
  Button,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import { loginUser } from './requests';
import { Redirect, useLocation } from 'react-router-dom';
import {
  setAccessTokenStorage,
  setUserDataStorage,
  setUserRolesStorage
} from './storageHelper';
import { AlertSnackbar } from './Alert';
import { isAuthenticated } from './utils';
import { EnvContext } from './App';
const queryString = require('query-string');

const useStyles = makeStyles({
  loginContainer: {
    marginTop: '10%'
  },
  inputField: { width: '100%' }
});

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);
  const location = useLocation();
  const envs = useContext(EnvContext);
  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      const loginResponse = await loginUser(email, password);
      setAccessTokenStorage(loginResponse.accessToken);
      setUserDataStorage(loginResponse.user);
      setUserRolesStorage(loginResponse.roles);
      setRedirectToReferrer(true);
    } catch (e) {
      setErrorMessage('Kirjautuminen epäonnistui');
    }
  };

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage('');
  };

  const isError = errorMessage.length > 0;

  const classes = useStyles();

  if (isAuthenticated() || redirectToReferrer) {
    const search = queryString.parse(location.search);
    return <Redirect to={search.redirect ?? '/'} />;
  }

  return (
    <div className={classes.loginContainer}>
      <AlertSnackbar
        severity="error"
        open={isError}
        message={errorMessage}
        handleClose={handleCloseAlert}
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <form>
            <Grid container justifyContent="center" alignItems="center">
              <Box
                maxWidth={400}
                style={{ backgroundColor: 'white', padding: 32, width: '100%' }}
              >
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <Box justifyContent="center" display="flex">
                      <Typography variant="h6">Kirjaudu sisään</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <TextField
                      id="email"
                      label="Sähköposti"
                      type="email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      className={classes.inputField}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="password"
                      type="password"
                      label="Salasana"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      className={classes.inputField}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={handleSubmit}
                      style={{ width: '100%' }}
                    >
                      Kirjaudu
                    </Button>
                  </Grid>
                  <Grid item>
                    <Link
                      href={
                        envs?.REACT_APP_AUTH_URL + '/public/recover?ramiforms'
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      Unohdin salasanani
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
