export type LoginResponse = {
  user: UserData;
  accessToken: string;
  roles: Role[];
};

export type AccessToken = {
  iss: string;
  iat: number;
  exp: number;
  nbf: number;
  jti: string;
  sub: number;
  prv: string;
};

export type UserData = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
};

export type Role =
  | 'RamiForms\\RamiForms View'
  | 'RamiForms\\RamiForms user'
  | 'RamiForms\\RamiForms admin';
export interface ProductResponse {
  id: number;
  name: string;
  ramiSafe: string;
  amount: number;
  unit: string;
  price: number;
  perMonth: boolean;
  info: string;
  productId: string;
  orderId: number;
  offerprice: boolean;
}

export interface TTLBody {
  userName: string;
  timestamp: string;
  customerName: string;
  customerNumber: string;
  orderId: string;
  contractId: string;
  siteId: string;
  siteName: string;
  deliveryAddress: string;
  town: string;
  deliveryInfo: string;
  orderer: string;
  ordererPhone: string;
  ordererEmail: string;
  deliveryType: string;
  recipient: string;
  recipientPhone: string;
  seller: string;
  sellerPhone: string;
  office: string;
  returnDate: string;
  capabilityConfirmed: boolean;
  deliveryPrice: string;
  fastDelivery: boolean;
  requiresInstallation: boolean;
  info: string;
  orderCopyEmails: string;
  products: ProductResponse[];
  postalCode: string;
  siteKey: string;
  deliveryEarliestDate: string;
  deliveryEarliestTime: string;
  deliveryLatestDate: string;
  deliveryLatestTime: string;
  sellerEmail: string;
  confirmationToSeller: boolean;
  confirmationToCustomer: boolean;
  manualPrice: string;
}

export interface sendTTLResponse {
  success: boolean;
  id: number;
}

export interface Order extends TTLBody {
  id: number;
  ramiRentOrderNumber: string;
  created_at: string;
}

export type FilterParam =
  | 'ramiRentOrderNumber'
  | 'customerName'
  | 'siteId'
  | 'seller'
  | 'office'
  | 'sourceSystem'
  | 'userName'
  | 'startDate'
  | 'endDate'
  | 'limit';

export type OrderFilter = {
  param: FilterParam;
  value: string;
};

export type OrderFilterRequest = {
  param: FilterParam;
  value: string;
};

export type FilterParamProps = {
  value: FilterParam;
  label: string;
};

export type Envs = { [key: string]: string };

export interface DataList {
  data: DataListEntry[];
  description: string;
  listId: number;
  name: string;
}

export interface DataListPostRequest {
  data: DataListEntry[];
  description?: string;
  listId: number | null;
  name: string;
  deletedOptionIds: number[];
}
export interface DataListEntry {
  label: string;
  value: string;
  orderId?: number;
  id?: number;
  sendEmail?: boolean;
}

export interface ProductFormInput {
  id: string;
  name: string;
  ramisafe: string;
  amount: string;
  unit: string;
  price: string;
  permonth: boolean;
  info: string;
  offerprice: boolean;
}

export interface TtlFormInput {
  customerName: string;
  customerNumber: string;
  orderId: string;
  contractId: string;
  siteId: string;
  siteName: string;
  deliveryAddress: string;
  town: string;
  deliveryInfo: string;
  orderer: string;
  ordererPhone: string;
  ordererEmail: string;
  deliveryType: string;
  recipient: string;
  recipientPhone: string;
  seller: string;
  sellerPhone: string;
  office: string;
  returnDate: string;
  capabilityConfirmed: boolean;
  deliveryPrice: string;
  fastDelivery: boolean;
  requiresInstallation: boolean;
  info: string;
  orderCopyEmails: { label: string; value: string };
  products: ProductFormInput[];
  postalCode: string;
  siteKey: string;
  deliveryEarliestDate: string;
  deliveryEarliestTime: string;
  deliveryLatestDate: string;
  deliveryLatestTime: string;
  sellerEmail: string;
  confirmationToSeller: boolean;
  confirmationToCustomer: boolean;
  manualPrice: string;
  [key: string]: string | boolean | ProductFormInput[] | object;
}

export interface TtlStatistics {
  formOpenedAt: string;
  filledDurationSeconds: number;
}

export interface GetOrderCountResponse {
  count: number;
}

export interface GetVisibilityResponse {
  visibility: Visibility;
}

export type SearchResultsResponse = {
  resultCount: number;
  results: SearchResult[];
  totalCount?: number;
};

export type SearchResult = {
  formId: string;
  formName: string;
  description: string;
  createdBy: string;
  createdAt: string;
  submitCount: number;
  isFavorite: boolean;
  visibility: Visibility;
};
export interface PostEntry {
  elementId: number;
  label: string;
  value: string;
}

export interface SubmitFormData {
  formId: string;
  filledBy: string;
  filledByName: string;
  phoneNumber: string;
  entries: PostEntry[];
  submitted: boolean;
  updatedBy: string;
  updatedByName: string;
  submitId?: string;
  comment?: string;
}

export type FormSubmitsGetResponse = {
  results: FormSubmitResult[];
  totalCount?: number;
};

export type FormSubmitCountGetResponse = {
  count: string;
};

export type SubmitFormResponse = {
  submitId: string;
};

export type PutFormResponse = GetFormResponse;

export type PostFormResponse = {
  formId: string;
};

export type PostEmailResponse = {
  submitId: string;
  message: string;
};

export type PostPdfResponse = {
  url: string;
};

export type ElementTypeRequestResponse = {
  value: string;
  label: string;
};

export type FormSubmitResult = {
  formId: string;
  filledBy: string;
  filledByName: string;
  createdAt: string;
  id: number;
  formName: string;
  description: string;
  pdfUrl: string;
  productId: ElementTypeRequestResponse[];
  updatedBy: string;
  updatedByName: string;
  comment: string;
  updatedAt: string;
  showSavedSubmitToUserOnly: boolean;
  isCurrentlyLocked: string;
  submitted: boolean;
};
export interface EntryResponse {
  elementsId: number;
  entryType: EntryType;
  elementType: ElementType;
  label: string;
  value: string;
  props: any;
  comment?: string;
}
export interface EntriesResponse {
  formName: string;
  formId: string;
  filledBy: string;
  filledByName: string;
  createdAt: string;
  id: number;
  entries: EntryResponse[];
  pdfUrl: string;
  updatedAt: string;
  updatedBy: string;
  updatedByName: string;
  submitted: boolean;
}

export interface PostDataListResponse {
  list: number;
  options: DataListEntry[];
}

export enum ElementType {
  Divider = 'divider',
  File = 'file',
  Text = 'text',
  Header = 'header',
  Number = 'number',
  Radio = 'radio',
  Dropdown = 'dropdown',
  Date = 'date',
  LinkedList = 'linkedlist',
  Email = 'email',
  Textarea = 'textarea',
  Checkbox = 'checkbox',
  ProductId = 'productId',
  Drawing = 'drawing',
  UserInfo = 'userinfo',
  Table = 'table'
}

export enum EntryType {
  String = 'string',
  Number = 'number',
  Date = 'date',
  ImageArray = 'imagearray'
}

export type Visibility = 'private' | 'draft' | 'public';

export interface GetFormOptionResponse {
  orderId: number;
  label: string;
  value: string;
  id?: number;
}

export type ListItem = {
  name: string;
  id: number;
  created_at: string;
  updated_at: string;
};

export type ListsResponse = {
  lists: ListItem[];
};

export interface GetFormElementResponse {
  id?: number;
  listId?: number;
  orderId: number;
  entryCount?: number;
  label: string;
  info: { text: string };
  entryType: EntryType;
  props: any;
  required: boolean;
  elementType: ElementType;
  includeInEmailSubject: boolean;
  options?: GetFormOptionResponse[];
}

export interface GetFormResponse {
  formId?: string;
  formName: string;
  formDescription: string;
  emailTo: string[];
  createPdf: boolean;
  showSavedSubmitToUserOnly: boolean;
  attachPdfToEmail: boolean;
  visibility: Visibility;
  emailSubjectOnlyValues: boolean;
  elements: GetFormElementResponse[];
  groups: string[];
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
}

export type EventTargetValue = { target: { value: any } };

export enum LoadingState {
  IsLoading,
  Initial
}

export type UploadProgressEvent = { loaded: number; total: number };

export interface DbGroup {
  id: number;
  groupName: string;
  parent: number;
}

export type FormGroupNode = {
  name: string;
  groupId: string;
  children: FormGroupNode[];
  parent: string | null;
};

export type FillWithUserSettingsChocies = { key: string; value: string }[];

export type ProductInfo = {
  [key: string]: string;
};
