import {
  createStyles,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme
} from '@material-ui/core';
import React from 'react';
import { sortBy } from '../../../../utils';
import EditCommonLabel from './EditCommonLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    elementPreview: { marginTop: theme.spacing(4), marginLeft: 0 },
    elementSelect: { marginLeft: theme.spacing(3) }
  })
);
interface EditLinkedListProps {
  onUpdateElementLabel: (e: any) => void;
  onUpdateCommonList: (e: any) => void;
  label: string;
  listId?: number;
  commonLists: any[];
}

const EditLinkedList = ({
  onUpdateElementLabel,
  onUpdateCommonList,
  listId,
  label,
  commonLists
}: EditLinkedListProps) => {
  const classes = useStyles();
  return (
    <>
      <EditCommonLabel onChange={onUpdateElementLabel} value={label} />
      <FormControlLabel
        className={classes.elementPreview}
        control={
          <Select
            className={classes.elementSelect}
            onChange={onUpdateCommonList}
            value={commonLists.length > 0 ? listId : ''}
          >
            {sortBy(commonLists, 'name').map((listElement: any, i: number) => (
              <MenuItem
                color="primary"
                value={listElement.id}
                key={i + listElement.name}
              >
                {listElement.name}
              </MenuItem>
            ))}
          </Select>
        }
        labelPlacement="start"
        label={'Linkitetty lista'}
      />
    </>
  );
};
export default EditLinkedList;
