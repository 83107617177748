import { Snackbar } from '@material-ui/core';
import MuiAlert, { Color } from '@material-ui/lab/Alert';

export const AlertSnackbar = ({
  open,
  message,
  handleClose,
  severity
}: {
  open: boolean;
  message: string;
  severity: Color | undefined;
  handleClose: () => void;
}) => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <MuiAlert variant="filled" severity={severity} onClose={handleClose}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
};
