import { Button } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { getUserDataStorage } from '../../../storageHelper';
import { FillWithUserInfoProps } from './ComponentTypes';

const FillWithUserInfo: React.FC<FillWithUserInfoProps> = ({ userInfo }) => {
  const { setValue } = useFormContext();
  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={() => {
        const user = getUserDataStorage();
        userInfo.forEach((userInfoField) => {
          if (userInfoField.value === 'firstName_lastName') {
            setValue(userInfoField.key.toString(), {
              value: user.firstName + ' ' + user.lastName
            });
          } else {
            setValue(userInfoField.key.toString(), {
              value: user[userInfoField.value]
            });
          }
        });
      }}
    >
      Täytä käyttäjän tiedoilla
    </Button>
  );
};

export { FillWithUserInfo };
