import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React from 'react';
import { Order } from '../types';
import { OpenInNew, PictureAsPdf } from '@material-ui/icons';
import { Chip, Grid, Tooltip } from '@material-ui/core';
import { DateTime } from 'luxon';
import { ramiSafeOptions } from '../utils';

import { OpenInNewDialog } from './OpenInNewDialog';

const useRowStyles = makeStyles({
  orderRow: {
    '& > td': {
      borderBottom: 'unset'
    }
  },
  cell: {
    color: '#333',
    maxWidth: '180px',
    overflowWrap: 'break-word',
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  collapsibleTableCell: { paddingBottom: 0, paddingTop: 0 },
  orderCountText: {
    fontSize: 14,
    color: '#333'
  },
  tableInfoText: {
    fontSize: 14
  },
  firstTableCell: {
    minWidth: 100
  },
  disabled: {
    cursor: 'not-allowed'
  },
  icon: {
    paddingLeft: '8px',
    paddingRight: '8px'
  }
});

export const priceText = (priceAmount: number, pricePerMonth: boolean) => {
  if (priceAmount) {
    return `${priceAmount}€ ${pricePerMonth ? '/kk' : '/päivä'}`;
  } else {
    return 'Ei hintaa';
  }
};

// PDF files before 6.1.2021 are not available because of order-api environment change
export const showDownloadButton = (created_at: string) =>
  DateTime.fromISO(created_at) > DateTime.fromISO('2021-01-06');

const formatEmptyNumber = (val: number) => val ?? '-';

const formatEmptyString = (val: string) => (val?.length > 0 ? val : '-');

export const formatRamiTurva = (val: string) => {
  const ramiSafe = ramiSafeOptions.find((option) => option.value === val);
  return ramiSafe?.label ?? '-';
};

const Row = ({
  order,
  downloadOrderPdf,
  showOrderForm
}: {
  order: Order;
  downloadOrderPdf: (orderNumber: string) => void;
  showOrderForm: Boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const [showDialog, setShowDialog] = React.useState(false);

  const handleClickOpen = () => {
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleCopyClick = () => {
    setOpen(true);
    setShowDialog(false);
  };

  return (
    <React.Fragment>
      <OpenInNewDialog
        show={showDialog}
        handleClose={handleClose}
        handleCopyClick={handleCopyClick}
        customerName={order?.customerName ?? ''}
        orderNumber={order?.ramiRentOrderNumber ?? ''}
        town={order?.town ?? ''}
        postalCode={order?.postalCode ?? ''}
        deliveryAddress={order?.deliveryAddress ?? ''}
      />
      <TableRow className={classes.orderRow}>
        <TableCell className={classes.icon}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.cell}>
          {order.ramiRentOrderNumber}
        </TableCell>
        <TableCell className={classes.cell}>{order.userName}</TableCell>
        <TableCell className={classes.cell}>{order.customerName}</TableCell>
        <TableCell className={classes.cell}>{order.siteId}</TableCell>
        <TableCell className={classes.cell}>{`${order.deliveryAddress}, ${
          order?.postalCode ?? ''
        } ${order?.town ?? ''}`}</TableCell>
        <TableCell className={classes.cell}>{order.orderer}</TableCell>
        <TableCell className={classes.cell}>
          {DateTime.fromISO(order.created_at).toFormat('dd.MM.yyyy HH:mm')}
        </TableCell>
        <TableCell className={classes.cell}>{order.office}</TableCell>
        <TableCell
          className={`${classes.icon} ${showOrderForm ? '' : classes.disabled}`}
        >
          <Tooltip
            placement="top"
            title="Kopioi tilauksen tiedot uudeksi tilaukseksi"
          >
            <IconButton
              size="medium"
              onClick={handleClickOpen}
              disabled={!showOrderForm}
            >
              <OpenInNew />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell className={classes.icon}>
          {showDownloadButton(order.created_at) ? (
            <IconButton
              size="medium"
              onClick={() => {
                downloadOrderPdf(order.ramiRentOrderNumber);
              }}
            >
              <PictureAsPdf />
            </IconButton>
          ) : (
            <div />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.collapsibleTableCell} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <h3>Tilauksen tiedot</h3>
                <table>
                  <tbody>
                    <tr>
                      <td className={classes.firstTableCell}>Myyjä</td>
                      <td>{order.seller}</td>
                    </tr>
                    <tr>
                      <td className={classes.firstTableCell}>Työmaan nimi</td>
                      <td>{order.siteName}</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={3}>
                <h3>Sähköpostit lähetetty</h3>
                <table>
                  <tbody>
                    <tr>
                      <td>Myyjälle</td>
                      <td>
                        <Chip label={order.sellerEmail} />
                      </td>
                    </tr>
                    <tr>
                      <td>Asiakkaalle</td>
                      <td>
                        <Chip label={order.ordererEmail} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Tuotteet
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Määrä</TableCell>
                    <TableCell>Tuotenumero</TableCell>
                    <TableCell>Nimi</TableCell>
                    <TableCell>Info</TableCell>
                    <TableCell>RamiTurva</TableCell>
                    <TableCell>Hinta</TableCell>
                    <TableCell>Yksikkö</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.products.map((product, i) => {
                    return (
                      <TableRow key={product.name + i}>
                        <TableCell className={classes.cell}>
                          {formatEmptyNumber(product.amount)}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {formatEmptyString(product.productId)}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {product.name}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {product.info}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {formatRamiTurva(product.ramiSafe)}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {priceText(product.price, product.perMonth)}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {product.unit}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default function OrderTable({
  orders,
  downloadOrderPdf
}: {
  orders: Order[];
  downloadOrderPdf: (orderNumber: string) => void;
}) {
  const showOrderForm = localStorage.getItem('showOrderForm') === 'true';
  const classes = useRowStyles();
  return (
    <TableContainer>
      <Box display="flex" justifyContent="space-between">
        <Box alignContent="flex-start">
          <Typography className={classes.orderCountText} gutterBottom>
            {orders.length < 100
              ? `Tilauksia yhteensä ${orders.length}`
              : `Sata viimeisintä tilausta`}
          </Typography>
        </Box>
        <Box alignContent="flex-end">
          <Typography
            className={classes.tableInfoText}
            color="textSecondary"
            gutterBottom
          >
            Lista päivittyy automaattisesti
          </Typography>
        </Box>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.icon} />
            <TableCell className={classes.cell}>Tilausnumero</TableCell>
            <TableCell className={classes.cell}>Täyttäjä</TableCell>
            <TableCell className={classes.cell}>Asiakas</TableCell>
            <TableCell className={classes.cell}>Työnumero</TableCell>
            <TableCell className={classes.cell}>Toimitusosoite</TableCell>
            <TableCell className={classes.cell}>Tilaaja</TableCell>
            <TableCell className={classes.cell}>Päivämäärä</TableCell>
            <TableCell className={classes.cell}>Toimipiste</TableCell>
            <TableCell className={classes.icon}>Kopioi</TableCell>
            <TableCell className={classes.icon}>Lataa</TableCell>
          </TableRow>
        </TableHead>
        <TableBody id="OrderTableBody">
          {orders.map((order: Order) => (
            <Row
              key={order.ramiRentOrderNumber}
              order={order}
              downloadOrderPdf={downloadOrderPdf}
              showOrderForm={showOrderForm}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
