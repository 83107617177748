import { Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { Order } from '../types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';

const useStyles = makeStyles({
  orderCountText: {
    fontSize: 14,
    color: '#333'
  },
  tableInfoText: {
    fontSize: 14
  }
});

interface OrderTableMobileProps {
  orders: Order[];
}

const OrderTableMobile = ({ orders }: OrderTableMobileProps) => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <>
      <Typography className={classes.orderCountText} gutterBottom>
        {orders.length < 100
          ? `Tilauksia yhteensä ${orders.length}`
          : `Sata viimeisintä tilausta`}
      </Typography>
      <Typography
        className={classes.tableInfoText}
        color="textSecondary"
        gutterBottom
      >
        Lista päivittyy automaattisesti
      </Typography>
      <div style={{ marginTop: 24 }}>
        {orders.map((order: Order) => (
          <div key={'tilaus-' + order.id} style={{ marginBottom: 16 }}>
            <Link
              component={RouterLink}
              to={{
                pathname: `/tilaus/${order.ramiRentOrderNumber}`,
                search: location.search
              }}
            >
              <Grid container direction="row" justifyContent="space-between">
                <Typography color="textPrimary">{`${order.ramiRentOrderNumber}`}</Typography>
                <Typography color="textSecondary">
                  {DateTime.fromISO(order.created_at).toFormat(
                    'dd.MM.yyyy HH:mm'
                  )}
                </Typography>
              </Grid>
              <Typography color="primary">{`${order.customerName}`}</Typography>
              <Typography color="textSecondary">{order.office}</Typography>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default OrderTableMobile;
