import { FormLabel, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { DataListEntry } from '../../../types';

interface AutocompleteFieldProps {
  label: string;
  options: DataListEntry[];
  value?: DataListEntry;
  onChange?: (event: any) => void;
  defaultValue?: string;
  className?: string;
  error?: boolean;
}

const AutocompleteField: React.FC<AutocompleteFieldProps> = ({
  options,
  onChange,
  value,
  label,
  error
}) => {
  return (
    <>
      <FormLabel component="legend">
        <Typography color={error ? 'error' : 'primary'}>{label}</Typography>
      </FormLabel>
      <Autocomplete
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        value={value ? value : null}
        onChange={(e, selected) => {
          if (onChange) {
            onChange(selected);
          }
        }}
        getOptionSelected={(o, value) => {
          return value.label === o.label && value.value === o.value;
        }}
        options={options}
        getOptionLabel={(o) => {
          return o.label;
        }}
        renderInput={(params: any) => (
          <TextField {...params} margin="normal" placeholder="Valitse" />
        )}
      ></Autocomplete>
    </>
  );
};

export { AutocompleteField };
