import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    maxWidth: theme.spacing(180), // 1440 px
    width: '100%',
    margin: '0 auto',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  }
}));

const ContentWrapper: FunctionComponent<{ className?: string }> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.contentWrapper + ' ' + props.className}>
      {props.children}
    </div>
  );
};

export default ContentWrapper;
