import {
  createStyles,
  IconButton,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useState } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      color: 'rgba(0, 0, 0, 1)',
      boxShadow: '2px 4px 4px 2px rgba(0, 0, 0, 0.5)',
      fontSize: 14,
      padding: 8,
      opacity: 1,
      minWidth: 'auto',
      whiteSpace: 'pre-wrap'
    }
  })
);

const InfoTooltip = ({ info }: { info?: string }) => {
  const classes = useStyles();
  const [toggleInfo, setToggleInfo] = useState(false);

  return (
    <Tooltip
      title={info ?? ''}
      classes={{
        tooltip: classes.tooltip
      }}
      open={toggleInfo}
      onClose={() => setToggleInfo(false)}
      disableHoverListener={true}
      placement="top"
      leaveTouchDelay={10000}
    >
      <IconButton
        style={{
          display: info === undefined || info.length === 0 ? 'none' : 'block'
        }}
        onClick={() => setToggleInfo(!toggleInfo)}
      >
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

export default InfoTooltip;
