import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  addressField: {
    marginLeft: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  header: {
    marginBottom: theme.spacing(1)
  }
}));

export const OpenInNewDialog = ({
  show,
  handleClose,
  handleCopyClick,
  customerName,
  orderNumber,
  deliveryAddress,
  postalCode,
  town
}: {
  show: boolean;
  handleClose: () => void;
  handleCopyClick: () => void;
  customerName: string;
  orderNumber: string;
  deliveryAddress: string;
  postalCode: string;
  town: string;
}) => {
  const classes = useStyles();
  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">
        Luo uusi tilaus vanhan pohjalta
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.header}>Kopioidaan tilaus</Typography>
        <Typography
          className={classes.addressField}
          color="textPrimary"
        >{`${orderNumber} - ${customerName}`}</Typography>
        <Typography
          className={classes.addressField}
          color="textSecondary"
        >{`${deliveryAddress} , ${postalCode} ${town}`}</Typography>

        <Divider className={classes.divider} />
        <Typography> Seuraavat kentät eivät kopioidu</Typography>
        <ul>
          <li>Tilausnumero </li>
          <li>Toimituksen huomiot</li>
          <li>Toimitus- ja palautuspäivämäärät</li>
          <li>Toimitustapa</li>
          <li>Myyjän tiedot</li>
          <li>Vastuullinen toimipiste</li>
          <li>Valintaruudut</li>
          <li>Tuotteet</li>
          <li>Sovittu kuljetusveloitus</li>
          <li>Lisätietoja tilauksesta</li>
          <li>Tilauksen käsittelijä</li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Peruuta</Button>
        <RouterLink
          target="_blank"
          style={{ textDecoration: 'none' }}
          to={`/tilaustieto/${orderNumber}`}
        >
          <Button color="primary" onClick={handleCopyClick} autoFocus>
            Kopioi tiedot
          </Button>
        </RouterLink>
      </DialogActions>
    </Dialog>
  );
};
