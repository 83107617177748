import { Box, Grid, Typography } from '@material-ui/core';
import React, { Component, ReactNode } from 'react';
import ContentContainer from './ContentContainer';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false
  };

  static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <ContentContainer>
          <Grid container alignItems="center">
            <Box>
              <img width={150} src="/static/rami-error.svg" alt="Error" />
            </Box>
            <Typography variant="h5" style={{ marginLeft: 50 }}>
              Järjestelmässä tapahtui virhe. Päivitä sivu tai yritä myöhemmin
              uudelleen
            </Typography>
          </Grid>
        </ContentContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
