import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const VersionContext = React.createContext<string | null>(null);

interface IProps {}

interface IState {
  updatePromptOpen: boolean;
  latestAppVersion: string | null;
  postponedUntil: number | null;
  pendingLatestVersion: string | null;
}

class AppUpdateProvider extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      updatePromptOpen: false,
      latestAppVersion: null,
      postponedUntil: null,
      pendingLatestVersion: null
    };
    setInterval(() => {
      this.checkForUpdates();
    }, 1000 * 60);
  }

  setLatestVersion(latestAppVersion: string) {
    this.setState({
      latestAppVersion
    });
  }

  componentDidMount() {
    this.checkForUpdates();
  }

  promptReload() {
    this.setState({ updatePromptOpen: true });
  }

  async reloadHandler() {
    const latestAppVersion = await this.fetchLatestVersion();
    this.setLatestVersion(latestAppVersion);
    // eslint-disable-next-line
    location.reload();
  }

  postponeHandler() {
    const NOW_PLUS_TWO_HOURS_MS = new Date().getTime() + 1000 * 3600 * 2;
    this.setState({
      postponedUntil: NOW_PLUS_TWO_HOURS_MS
    });
    this.handlePromtClose();
  }

  handlePromtClose() {
    this.setState({ updatePromptOpen: false });
  }

  async fetchLatestVersion() {
    const manifestHeaders = {
      'cache-control': 'no-cache',
      pragma: 'no-cache'
    };
    const response = await fetch('/api/version', {
      headers: manifestHeaders
    });
    const { latestAppVersion } = await response.json();
    return latestAppVersion as string;
  }

  async checkForUpdates() {
    if (
      this.state.postponedUntil !== null &&
      new Date().getTime() < this.state.postponedUntil
    ) {
      return;
    }
    try {
      const latestAppVersion = await this.fetchLatestVersion();
      const currentAppVersion = this.state.latestAppVersion;
      if (currentAppVersion === null) {
        // initial state, no app version is yet set
        this.setLatestVersion(latestAppVersion);
        return;
      } else if (currentAppVersion !== latestAppVersion) {
        // check if saved app version does not match what we got from manifest.json
        this.setState({
          pendingLatestVersion: latestAppVersion
        });
        this.promptReload();
      }
    } catch (e) {
      // fail silently as this is not required for the app to function. A fail might happen during a flaky connection or when switching networks.
      console.warn('Failed to fetch latest version.', e);
      return;
    }
  }

  render() {
    return (
      <>
        <Dialog open={this.state.updatePromptOpen}>
          <DialogTitle id="alert-dialog-slide-title">
            Päivitys saatavilla
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Uusi RamiForms-versio on saatavilla. Päivitä heti uusimpaan
              versioon valitsemalla Päivitä. Mikäli sinulla on lomakkeen täyttö
              kesken, voit lykätä päivitystä valitsemalla Päivitä myöhemmin.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.reloadHandler.bind(this)}>Päivitä</Button>
            <Button onClick={this.postponeHandler.bind(this)}>
              Päivitä myöhemmin
            </Button>
          </DialogActions>
        </Dialog>
        <VersionContext.Provider value={this.state.latestAppVersion}>
          {this.props.children}
        </VersionContext.Provider>
      </>
    );
  }
}

export default AppUpdateProvider;
