import { createStyles, makeStyles, TextField } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    label: { width: '100%' }
  })
);

interface EditCommonLabelProps {
  onChange: (e: any) => void;
  value: string;
}

const EditCommonLabel = ({ value, onChange }: EditCommonLabelProps) => {
  const classes = useStyles();
  return (
    <TextField
      onChange={onChange}
      value={value}
      placeholder="Otsikko"
      className={classes.label}
      size="medium"
      multiline
    />
  );
};

export default EditCommonLabel;
