import React, { useEffect, useReducer } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  createStyles,
  Theme,
  makeStyles,
  alpha
} from '@material-ui/core/styles';
import { ReactComponent as RamirentLogo } from './ramirent-company-logo.svg';
import SearchIcon from '@material-ui/icons/Search';
import ContentWrapper from './ContentWrapper';
import Version from './Version';
import {
  Avatar,
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Fade,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Person from '@material-ui/icons/Person';
import { logout } from './App';
import { useLocation, useHistory, Link, Redirect } from 'react-router-dom';
import { search } from './requests';
import { Skeleton } from '@material-ui/lab';
import { LoadingState, SearchResult } from './types';
import { getUserDataStorage } from './storageHelper';
import OnlyRoles from './OnlyRoles';
import Folder from '@material-ui/icons/Folder';
import { ListAlt, Lock, Settings } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';

const queryString = require('query-string');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    logo: {
      height: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: 100,
        marginRight: theme.spacing(2)
      },
      cursor: 'pointer'
    },
    spaceBetween: {
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up(1490)]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
      }
    },
    searchResults: {
      maxWidth: 1440,
      width: '90%',
      marginTop: theme.spacing(-1),
      marginLeft: theme.spacing(-1),
      position: 'absolute',
      zIndex: 2,
      [theme.breakpoints.up('md')]: {
        width: '100%'
      }
    },
    searchResultsItem: {
      '&:hover': {
        backgroundColor: '#bfbfbf1f'
      }
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.35),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.45)
      },
      marginLeft: 0,
      width: '60%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1)
      },
      transition: theme.transitions.create('width')
    },
    wideSearch: {
      width: '100%'
    },
    activeSearchResult: {
      backgroundColor: '#bfbfbf1f'
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.main
    },
    inputRoot: {
      color: 'inherit',
      width: '100%'
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width')
    },
    unstyledLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        textDecoration: 'none'
      },
      '&:visited': {
        textDecoration: 'none'
      }
    },
    profile: {
      width: '80%',
      maxWidth: 400,
      position: 'absolute',
      right: 0,
      top: 60,
      zIndex: 2
    },
    logoutButton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    profileIcon: { marginLeft: theme.spacing(3) },
    profileIconText: { marginRight: theme.spacing(1) },
    boxCentered: {
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    editLink: {
      textDecoration: 'none',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  })
);

type SearchInputProps = {
  onFocus: () => void;
  handleClear: () => void;
  handleOnChange: (value: string) => void;
  value: string;
  searchOpen: boolean;
};

const SearchInput: React.FC<SearchInputProps> = ({
  onFocus,
  handleClear,
  handleOnChange,
  value,
  searchOpen
}) => {
  const classes = useStyles();
  const isMobileView = useMediaQuery('(max-width:640px)');
  return (
    <div
      className={classes.search + ' ' + (searchOpen ? classes.wideSearch : '')}
    >
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        data-cy="forms-search-input"
        endAdornment={
          <Fade in={value.length > 0}>
            <Box
              display="flex"
              paddingRight={1}
              onClick={handleClear}
              style={{ cursor: 'pointer' }}
            >
              <ClearIcon color="primary" data-cy="clear-search" />
            </Box>
          </Fade>
        }
        onFocus={onFocus}
        placeholder={isMobileView ? 'Hae' : 'Hae lomaketta...'}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const value = event.target.value;
          handleOnChange(value);
        }}
        value={value}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  );
};

type SearchState = {
  searchQuery: string;
  results: SearchResult[];
  isSearchOpen: boolean;
  activeResult: number;
  isLoading: LoadingState;
  totalResultCount: number;
};

enum SearchActions {
  OpenSearch,
  CloseSearch,
  SetSearchResults,
  ClearSearchResults,
  SetQuery,
  SetActiveResult,
  GoToForm,
  SetIsLoading,
  SetTotalResultCount
}

const initialState: SearchState = {
  activeResult: -1,
  isLoading: LoadingState.IsLoading,
  searchQuery: '',
  results: [],
  isSearchOpen: false,
  totalResultCount: 0
};

type ReducerAction =
  | { type: SearchActions.OpenSearch }
  | { type: SearchActions.CloseSearch }
  | { type: SearchActions.SetSearchResults; payload: SearchResult[] }
  | { type: SearchActions.ClearSearchResults }
  | { type: SearchActions.SetQuery; payload: string }
  | { type: SearchActions.SetActiveResult; payload: number }
  | { type: SearchActions.GoToForm; payload: (formId: string) => void }
  | { type: SearchActions.SetIsLoading; payload: LoadingState }
  | { type: SearchActions.SetTotalResultCount; payload: number };

const searchReducer = (state: SearchState, action: ReducerAction) => {
  switch (action.type) {
    case SearchActions.OpenSearch:
      return { ...state, isSearchOpen: true };
    case SearchActions.CloseSearch:
      return { ...state, isSearchOpen: false, results: [] };
    case SearchActions.SetSearchResults:
      return { ...state, results: action.payload };
    case SearchActions.ClearSearchResults:
      return { ...state, results: [] };
    case SearchActions.SetQuery:
      return { ...state, searchQuery: action.payload };
    case SearchActions.SetActiveResult:
      let target = state.activeResult + action.payload;
      if (target < 0) {
        target = state.results.length - 1;
      } else if (target >= state.results.length) {
        target = 0;
      }
      return { ...state, activeResult: target };
    case SearchActions.GoToForm:
      if (state.results.length === 0) {
        // unable goto form -> do nothing
        return state;
      }
      const targetForm = state.results[state.activeResult].formId;
      action.payload(targetForm);
      return state;
    case SearchActions.SetIsLoading:
      return { ...state, isLoading: action.payload };
    case SearchActions.SetTotalResultCount:
      return { ...state, totalResultCount: action.payload };
  }
};

const useKeys = (
  arrow: (dir: number) => void,
  selectTarget: () => void,
  close: () => void
) => {
  useEffect(() => {
    window.addEventListener('keydown', (ev: any) => {
      switch (ev.key) {
        case 'ArrowUp':
          arrow(-1);
          break;
        case 'ArrowDown':
          arrow(1);
          break;
        case 'Enter':
          selectTarget();
          break;
        case 'Escape':
          close();
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

type AnyFunction = (...args: any[]) => any;
type Await<T> = T extends Promise<infer U> ? U : T;
const accBounce = <T extends AnyFunction>(f: T, timeout: number) => {
  let timeoutId: any = null;
  return (...args: Parameters<T>) => {
    return new Promise<Await<ReturnType<T>>>((resolve, reject) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(async () => {
        try {
          resolve(await f.apply(null, args));
        } catch (e) {
          reject(e);
        }
        timeoutId = null;
      }, timeout);
    });
  };
};

const debouncedSearch = accBounce(search, 1000);

const getSearchInfoText = (searchQuery: string, totalResults: number) => {
  if (searchQuery.length <= 2) {
    return 'Haun oltava yli kaksi merkkiä pitkä.';
  } else {
    return `Yhteensä ${totalResults} lomaketta haulla '${searchQuery}'`;
  }
};

const generateInitials = (firstName: string, lastName: string) => {
  let initials = '';
  if (firstName && firstName.length > 0) {
    initials += firstName[0].toUpperCase();
  }
  if (lastName && lastName.length > 0) {
    initials += lastName[0].toUpperCase();
  }
  return initials;
};

const RamiAvatar = withStyles((theme) => ({
  colorDefault: { backgroundColor: theme.palette.primary.main }
}))(Avatar);

const UserInformation: React.FC<{
  firstName: string;
  lastName: string;
  email: string;
}> = ({ firstName, lastName, email }) => {
  return (
    <>
      <RamiAvatar>{generateInitials(firstName, lastName)}</RamiAvatar>
      <Box marginRight="auto" marginLeft={2} overflow="auto">
        <Typography>{`${firstName} ${lastName}`}</Typography>
        <Typography color="textSecondary">{`${email}`}</Typography>
      </Box>
    </>
  );
};

export default function SearchAppBar() {
  const classes = useStyles();
  const [state, dispatch] = useReducer(searchReducer, initialState);
  const history = useHistory();
  const [isInitialRender, setInitialRender] = React.useState(true);
  const location = useLocation();
  const pdfView = queryString.parse(location.search).pdfView;

  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);
  const [showProfile, setShowProfile] = React.useState(false);
  const [closeProfile, setCloseProfile] = React.useState(false);
  const user = getUserDataStorage();
  const isMobileView = useMediaQuery('(max-width:640px)');

  useEffect(() => {
    setRedirectToReferrer(false);
  }, [redirectToReferrer]);

  // Clicking profile icon without the timer would pop the profile back open
  useEffect(() => {
    if (closeProfile) {
      setShowProfile(false);
      setTimeout(() => {
        setCloseProfile(false);
      }, 100);
    }
  }, [closeProfile]);

  useEffect(() => {
    if (!isInitialRender && state.isSearchOpen) {
      (async () => {
        dispatch({
          type: SearchActions.SetIsLoading,
          payload: LoadingState.IsLoading
        });
        try {
          if (state.searchQuery.length <= 2) {
            // skip short queries
            return;
          }
          const { results, resultCount } = await debouncedSearch(
            state.searchQuery
          );
          dispatch({
            type: SearchActions.SetSearchResults,
            payload: results.slice(0, 6)
          });
          dispatch({
            type: SearchActions.SetTotalResultCount,
            payload: resultCount
          });
        } catch (e) {
          console.error('Unable to fetch form results', e);
        } finally {
          dispatch({
            type: SearchActions.SetIsLoading,
            payload: LoadingState.Initial
          });
        }
      })();
    }
    setInitialRender(false);
    // eslint-disable-next-line
  }, [state.searchQuery, isInitialRender, state.isSearchOpen]);
  const updateActiveIndex = (dir: number) => {
    dispatch({
      type: SearchActions.SetActiveResult,
      payload: dir
    });
  };
  const handleEnterPress = () => {
    dispatch({
      type: SearchActions.GoToForm,
      payload: (formId) => history.push(`/lomake/${formId}`)
    });
    dispatch({ type: SearchActions.CloseSearch });
  };
  const handleCloseSearch = () => {
    dispatch({ type: SearchActions.CloseSearch });
  };
  useKeys(updateActiveIndex, handleEnterPress, handleCloseSearch);

  if (redirectToReferrer) {
    return <Redirect to="/" />;
  }

  return (
    <div className={classes.root}>
      {pdfView !== 'true' && (
        <ClickAwayListener
          onClickAway={() => dispatch({ type: SearchActions.CloseSearch })}
        >
          <div>
            <AppBar position="static" color="secondary">
              <Toolbar>
                <ContentWrapper className={classes.spaceBetween}>
                  {state.isSearchOpen ? null : (
                    <RamirentLogo
                      onClick={() => setRedirectToReferrer(true)}
                      className={classes.logo}
                    />
                  )}
                  <>
                    <SearchInput
                      searchOpen={state.isSearchOpen}
                      value={state.searchQuery}
                      onFocus={() =>
                        dispatch({ type: SearchActions.OpenSearch })
                      }
                      handleClear={() => {
                        dispatch({ type: SearchActions.SetQuery, payload: '' });
                        dispatch({
                          type: SearchActions.SetSearchResults,
                          payload: []
                        });
                      }}
                      handleOnChange={(value) => {
                        if (value.length <= 2) {
                          dispatch({
                            type: SearchActions.SetSearchResults,
                            payload: []
                          });
                        }
                        dispatch({
                          type: SearchActions.SetQuery,
                          payload: value
                        });
                        if (!state.isSearchOpen) {
                          dispatch({ type: SearchActions.OpenSearch });
                        }
                        dispatch({
                          type: SearchActions.SetIsLoading,
                          payload: LoadingState.IsLoading
                        });
                      }}
                    />
                  </>
                  {state.isSearchOpen ? null : (
                    <Box
                      display="inline-flex"
                      className={classes.profileIcon}
                      onClick={() => {
                        if (!closeProfile) {
                          setShowProfile(true);
                        }
                      }}
                    >
                      {location?.pathname !== '/login' && (
                        <>
                          <div
                            style={{
                              cursor: 'pointer',
                              display: 'inline-flex'
                            }}
                          >
                            {!isMobileView && (
                              <Typography
                                color="primary"
                                className={classes.profileIconText}
                              >
                                {user?.firstName ?? ''}
                              </Typography>
                            )}
                            <Person color="primary" />
                          </div>
                          {showProfile && (
                            <ClickAwayListener
                              onClickAway={() => {
                                setCloseProfile(true);
                              }}
                            >
                              <Paper elevation={5} className={classes.profile}>
                                <Box
                                  display="flex"
                                  paddingTop={2}
                                  paddingLeft={2}
                                  paddingRight={2}
                                >
                                  {user.email ? (
                                    <UserInformation
                                      firstName={user.firstName}
                                      lastName={user.lastName}
                                      email={user.email}
                                    />
                                  ) : (
                                    <UserInformation
                                      firstName={'Julkinen käyttäjä'}
                                      lastName={''}
                                      email={'Ei sähköpostia'}
                                    />
                                  )}
                                </Box>
                                <List aria-label="main mailbox folders">
                                  <OnlyRoles
                                    allowedRoles={[
                                      'RamiForms\\RamiForms admin'
                                    ]}
                                  >
                                    <Divider />
                                    <ListItem
                                      button
                                      onClick={() => {
                                        setCloseProfile(true);
                                        history.push('/listat');
                                      }}
                                    >
                                      <ListItemIcon>
                                        <ListAlt />
                                      </ListItemIcon>
                                      <ListItemText primary="Listat" />
                                    </ListItem>
                                    <ListItem
                                      button
                                      onClick={() => {
                                        setCloseProfile(true);
                                        history.push('/kansiomuokkaus');
                                      }}
                                    >
                                      <ListItemIcon>
                                        <Folder />
                                      </ListItemIcon>
                                      <ListItemText primary="Kansiot" />
                                    </ListItem>
                                    <ListItem
                                      button
                                      onClick={() => {
                                        setCloseProfile(true);
                                        history.push('/asetukset');
                                      }}
                                    >
                                      <ListItemIcon>
                                        <Settings />
                                      </ListItemIcon>
                                      <ListItemText primary="Asetukset" />
                                    </ListItem>
                                  </OnlyRoles>
                                  <ListItem button onClick={logout}>
                                    <ListItemIcon>
                                      <Lock />
                                    </ListItemIcon>
                                    <ListItemText primary="Kirjaudu ulos" />
                                  </ListItem>
                                </List>
                                <Divider />
                                <Box padding={2}>
                                  <Typography color="textSecondary">
                                    <Version />
                                  </Typography>
                                </Box>
                              </Paper>
                            </ClickAwayListener>
                          )}
                        </>
                      )}
                    </Box>
                  )}
                </ContentWrapper>
              </Toolbar>
            </AppBar>

            {state.searchQuery.length > 0 ? (
              <ContentWrapper>
                <Box display="flex" justifyContent="center">
                  <Fade in={state.isSearchOpen}>
                    <Box className={classes.searchResults}>
                      <Paper elevation={5} data-cy="search-results">
                        <Box paddingBottom={2}>
                          {state.isLoading !== LoadingState.IsLoading ? (
                            state.results.map((result, i) => {
                              return (
                                <Link
                                  key={result.formId}
                                  to={`/lomake/${result.formId}`}
                                  className={classes.unstyledLink}
                                  onClick={() => {
                                    dispatch({
                                      type: SearchActions.ClearSearchResults
                                    });
                                    dispatch({
                                      type: SearchActions.CloseSearch
                                    });
                                    dispatch({
                                      type: SearchActions.SetQuery,
                                      payload: ''
                                    });
                                  }}
                                >
                                  <Box
                                    display="flex"
                                    paddingTop={2}
                                    paddingBottom={2}
                                    paddingLeft={4}
                                    paddingRight={4}
                                    className={
                                      i === state.activeResult
                                        ? classes.activeSearchResult +
                                          ' ' +
                                          classes.searchResultsItem
                                        : classes.searchResultsItem
                                    }
                                  >
                                    <Box minWidth={40}>
                                      <Typography color="primary">
                                        {result.formId}
                                      </Typography>
                                    </Box>
                                    <Box marginLeft={2} marginRight={2}>
                                      <Divider orientation="vertical" />
                                    </Box>
                                    <Box>
                                      <Box>
                                        <Typography color="primary">
                                          {result.formName}
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography variant="subtitle2">
                                          {result.description}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box marginLeft={2} marginRight={2}>
                                      <Divider orientation="vertical" />
                                    </Box>
                                    <OnlyRoles
                                      allowedRoles={[
                                        'RamiForms\\RamiForms admin'
                                      ]}
                                    >
                                      <Link
                                        className={classes.editLink}
                                        to={`/lomake/muokkaus/${result.formId}`}
                                      >
                                        <Box className={classes.boxCentered}>
                                          <Typography variant="subtitle2">
                                            {'Muokkaa'}
                                          </Typography>
                                        </Box>
                                      </Link>
                                    </OnlyRoles>
                                  </Box>
                                  <Divider />
                                </Link>
                              );
                            })
                          ) : (
                            <Box
                              paddingTop={2}
                              paddingLeft={4}
                              paddingRight={4}
                              position="relative"
                            >
                              <Box marginTop={2}>
                                <Skeleton width={200} />
                                <Skeleton height={50} />
                              </Box>
                              <Box marginTop={2}>
                                <Skeleton width={200} />
                                <Skeleton height={50} />
                              </Box>
                              <Box marginTop={2}>
                                <Skeleton width={200} />
                                <Skeleton height={50} />
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="center"
                                position="relative"
                                top="-150px"
                              >
                                <CircularProgress />
                              </Box>
                            </Box>
                          )}
                          {state.isLoading !== LoadingState.IsLoading ? (
                            <Box paddingLeft={4} paddingTop={2}>
                              <Typography variant="body2">
                                {getSearchInfoText(
                                  state.searchQuery,
                                  state.totalResultCount
                                )}
                              </Typography>
                            </Box>
                          ) : null}
                        </Box>
                      </Paper>
                    </Box>
                  </Fade>
                </Box>
              </ContentWrapper>
            ) : null}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}
