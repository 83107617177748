import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  makeStyles,
  createStyles,
  Typography,
  Button,
  IconButton
} from '@material-ui/core';
import { PictureAsPdf } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import TableSkeleton from '../TableSkeleton';
import { FormSubmitsGetResponse, LoadingState } from '../types';
import { usePersistentScrollLocation } from '../utils';

const useStyles = makeStyles(() =>
  createStyles({
    descMaxWidth: {
      maxWidth: 170
    },
    alignTop: {
      verticalAlign: 'top'
    }
  })
);

interface FormSubmitsProps {
  formSubmitList: FormSubmitsGetResponse | null;
  showPagination: () => void;
  isLoading?: LoadingState;
}

const FormSubmits = ({
  formSubmitList,
  showPagination,
  isLoading
}: FormSubmitsProps) => {
  const classes = useStyles();
  const location = useLocation();
  const [height, setHeight] = useState<number | undefined>(400);
  const heightRef = useRef<HTMLDivElement>(null);
  const rows = Array.from(Array(formSubmitList?.results.length).keys());

  useEffect(() => {
    if (heightRef.current) {
      setHeight(heightRef.current.clientHeight);
    }
  }, [heightRef]);

  usePersistentScrollLocation();
  return (
    <>
      {showPagination()}
      <TableContainer ref={heightRef}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Lomake</TableCell>
              <TableCell>Täyttäjä</TableCell>
              <TableCell>Täytetty</TableCell>
              <TableCell>Sarjanumero</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {isLoading === LoadingState.IsLoading ? (
            <TableSkeleton height={height} rows={rows} />
          ) : (
            <TableBody>
              {formSubmitList?.results.map((row) => (
                <TableRow key={row.id}>
                  <TableCell scope="row" className={classes.alignTop}>
                    <Link
                      component={RouterLink}
                      to={{
                        pathname: `/vastaus/${row.id}`,
                        search: location.search
                      }}
                    >
                      <Typography>{row.id}</Typography>
                    </Link>
                  </TableCell>
                  <TableCell
                    scope="row"
                    className={classes.descMaxWidth + ' ' + classes.alignTop}
                  >
                    <Typography color="primary">
                      <Link component={RouterLink} to={`/vastaus/${row.id}`}>
                        {row.formId}
                      </Link>
                    </Typography>
                    <Typography variant="body2">{row.formName}</Typography>
                  </TableCell>
                  <TableCell scope="row">{row.updatedByName}</TableCell>
                  <TableCell scope="row">
                    {DateTime.fromISO(row.updatedAt).toFormat(
                      'dd.MM.yyyy HH:mm'
                    )}
                  </TableCell>
                  <TableCell scope="row">
                    {row.productId &&
                      row.productId
                        .filter((productId) => productId.value)
                        .map((productId) => productId.value)
                        .join(', ')}
                  </TableCell>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={{
                        pathname: `/vastaus/${row.id}`,
                        search: location.search
                      }}
                    >
                      <Button color="primary">Näytä</Button>
                    </Link>
                  </TableCell>
                  <TableCell>
                    {row.pdfUrl && (
                      <a target="_blank" rel="noreferrer" href={row.pdfUrl}>
                        <IconButton size="medium">
                          <PictureAsPdf />
                        </IconButton>
                      </a>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {showPagination()}
    </>
  );
};
export default FormSubmits;
