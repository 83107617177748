import { makeStyles, Typography } from '@material-ui/core';

export type CategorySelectorFormGroup = {
  name: string;
  groupId: string;
  isActive: boolean;
};

type FormListCategorySelectorProps = {
  availableGroups: CategorySelectorFormGroup[];
  setSelectedGroupId: (groupId: CategorySelectorFormGroup) => void;
};

const useStyles = makeStyles((theme) => ({
  categoryLabel: {
    display: 'inline-block',
    padding: theme.spacing(1)
  },
  categoryLabelActive: {
    borderBottom: '3px solid'
  },
  categoryList: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 0,
    listStyleType: 'none',
    margin: 0,
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    minHeight: 60
  }
}));

const FormListCategorySelector: React.FC<FormListCategorySelectorProps> = ({
  availableGroups,
  setSelectedGroupId
}) => {
  const classes = useStyles();
  return (
    <ul className={classes.categoryList}>
      {availableGroups
        .sort((a, b) =>
          a.name.toLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1
        )
        .map((g) => {
          return (
            <Typography
              key={g.groupId}
              onClick={() => {
                setSelectedGroupId(g);
              }}
              color={g.isActive ? 'primary' : 'initial'}
              component={'span'}
              className={`${classes.categoryLabel} ${
                g.isActive ? classes.categoryLabelActive : ''
              }`}
              variant="body2"
            >
              {g.name}
            </Typography>
          );
        })}
    </ul>
  );
};

export default FormListCategorySelector;
