import { TreeItem } from '@material-ui/lab';
import { FormGroupNode } from '../types';
import { sortBy } from '../utils';

const rcur = (groups: FormGroupNode[]) => {
  return sortBy(groups, 'name').map((g) => {
    if (g.children.length > 0) {
      return (
        <TreeItem label={g.name} nodeId={g.groupId} key={g.groupId}>
          {rcur(g.children)}
        </TreeItem>
      );
    }
    return <TreeItem label={g.name} nodeId={g.groupId} key={g.groupId} />;
  });
};

const GroupTree: React.FC<{ group: FormGroupNode }> = ({ group }) => {
  return (
    <TreeItem label={group.name} nodeId={group.groupId}>
      {rcur(group.children)}
    </TreeItem>
  );
};

export default GroupTree;
