import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ContentContainer from './ContentContainer';

const useStyles = makeStyles((theme) =>
  createStyles({
    element: {
      width: '100%',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    }
  })
);

const LoadingSkeleton = () => {
  const classes = useStyles();

  return (
    <ContentContainer>
      <>
        <Box display="flex" justifyContent="center">
          <CircularProgress size={48} />
        </Box>
        <Box className={classes.element}>
          <Skeleton width={200} />
          <Skeleton height={50} />
        </Box>
        <Box className={classes.element}>
          <Skeleton width={200} />
          <Skeleton height={50} />
        </Box>
        <Box className={classes.element}>
          <Skeleton width={200} />
          <Skeleton height={50} />
        </Box>
      </>
    </ContentContainer>
  );
};

export default LoadingSkeleton;
