import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

type TableSkeletonProps = {
  height?: number;
  rows?: number[];
};

const TableSkeleton: React.FC<TableSkeletonProps> = ({
  height = 400,
  rows = [100]
}) => {
  return (
    <TableBody style={{ height: height }}>
      {rows.map((row) => (
        <TableRow key={row}>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default TableSkeleton;
