import { TextField, Box } from '@material-ui/core';
import React from 'react';

interface EditCommonInfoProps {
  onChange: (e: any) => void;
  value: string;
}

const EditCommonInfo = ({ value, onChange }: EditCommonInfoProps) => {
  return (
    <Box
      style={{
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderRadius: '4px'
      }}
      width="100%"
    >
      <TextField
        id="outlined-multiline-static"
        style={{
          padding: 4,
          width: '100%'
        }}
        InputProps={{ disableUnderline: true }}
        placeholder="Lisätietoja"
        value={value}
        onChange={onChange}
        rows={4}
        multiline
      />
    </Box>
  );
};

export default EditCommonInfo;
