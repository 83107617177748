import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ContentContainer from '../../ContentContainer';
import LoadingSkeleton from '../../LoadingSkeleton';
import { getForm } from '../../requests';
import { GetFormResponse } from '../../types';
import AddForm, { EditState } from './AddForm';

type LocationState = {
  formId?: string;
};

const CopyForm = () => {
  const [form, setForm] = useState<EditState | null>(null);
  const [loadingForm, setLoadingForm] = useState(false);
  const [isError, setIsError] = useState(false);

  const location = useLocation();
  const locationState = location.state as LocationState;
  const formId = locationState.formId;

  useEffect(() => {
    if (formId) {
      (async () => {
        try {
          setLoadingForm(true);
          const form = formId
            ? await getForm<GetFormResponse>(formId, false)
            : null;
          if (form) {
            const editStateForm: EditState = {
              formId: undefined,
              formTitle: form.formName + ' kopio',
              formDescription: form.formDescription,
              emailTo: form.emailTo,
              createPdf: form.createPdf,
              showSavedSubmitToUserOnly: form.showSavedSubmitToUserOnly,
              attachPdfToEmail: form.attachPdfToEmail,
              visibility: form.visibility,
              emailSubjectOnlyValues: form.emailSubjectOnlyValues,
              createdBy: form.createdBy,
              createdAt: form.createdAt,
              updatedBy: form.updatedBy,
              updatedAt: form.updatedAt,
              elements: form.elements.map((element) => {
                return {
                  id: undefined,
                  listId:
                    element.elementType === 'linkedlist'
                      ? element.listId
                      : undefined,
                  orderId: element.orderId,
                  label: element.label,
                  info: element.info ?? { text: '' },
                  entryType: element.entryType,
                  entryCount: element.entryCount,
                  props: element.props,
                  required: element.required,
                  elementType: element.elementType,
                  includeInEmailSubject: element.includeInEmailSubject,
                  options:
                    element.options &&
                    element.options.map((e) => {
                      e.id = undefined;
                      return e;
                    }),
                  isDirty: false
                };
              }),
              groups: form.groups
            };
            setForm(editStateForm);
          }
        } catch (error) {
          setIsError(true);
        } finally {
          setLoadingForm(false);
        }
      })();
    }
  }, [formId]);
  return (
    <>
      {isError && (
        <ContentContainer>
          <Alert severity="error">Virhe ladattaessa lomake pohjaa</Alert>
        </ContentContainer>
      )}
      {formId && loadingForm && <LoadingSkeleton />}
      {form && <AddForm form={form} />}
    </>
  );
};

export default CopyForm;
