import { Box, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import ContentContainer from '../ContentContainer';
import { FormSubmitResult, FormSubmitsGetResponse } from '../types';
import { useApi } from './DynamicForms/fetchHook';
import { FormFilter } from './FormFilterView';
import { getUserDataStorage } from '../storageHelper';
import {
  isMobile,
  getActivePage,
  setActivePage,
  isAdminUser,
  isViewUser
} from '../utils';
import CircularProgressIndicator from './CircularProgressIndicator';
import CustomPagination from '../CustomPagination';
import FormIncompleteSubmits from './FormIncompleteSubmits';
import FormIncompleteSubmitsMobile from './FormIncompleteSubmitsMobile';

const pageSize = 100;
const pageName = 'incompleteSubmitsPage';

// Same as in FormSubmitsContainer, maybe later filters will be added
const buildSubmitsQueryString = ({
  base = '',
  filters = [],
  start = 0,
  end = 100,
  submitted
}: {
  base: string;
  filters?: FormFilter[];
  start?: number;
  end?: number;
  submitted: boolean;
}) => {
  const qs = filters.map((f) => `${f.columnName}=${f.value.trim()}`).join('&');
  return `${base}?${qs}&start=${start}&end=${end}&submitted=${submitted}`;
};

export const availableFilters = [
  { column: 'formId', label: 'Lomaketunniste', viewRole: 'user' },
  { column: 'formName', label: 'Lomakkeen nimi', viewRole: 'user' },
  { column: 'filledBy', label: 'Täyttäjä (s-posti)', viewRole: 'admin' },
  { column: 'filledByName', label: 'Täyttäjä (nimi)', viewRole: 'admin' },
  { column: 'createdAtStart', label: 'Alkava täyttöpäivä', viewRole: 'user' },
  { column: 'createdAtEnd', label: 'Viimeisin täyttöpäivä', viewRole: 'user' },
  { column: 'productId', label: 'Sarjanumero', viewRole: 'user' }
];

const FormSubmitsContainer: React.FC = () => {
  const [showOnlyUser, setShowOnlyUser] = useState(false);
  const [filteredFormSubmitList, setFilteredFormSubmitList] = useState<
    FormSubmitResult[]
  >([]);
  const [pagination, setPagination] = useState({
    count: 1,
    start: (getActivePage(pageName) - 1) * pageSize,
    end: getActivePage(pageName) * pageSize
  });
  const userEmail = getUserDataStorage().email;
  const [formSubmitList, isLoading, error] = useApi<FormSubmitsGetResponse>(
    buildSubmitsQueryString({
      base: '/api/submits',
      filters: [
        {
          columnName: 'includeValuesByType',
          value: 'productId'
        }
      ],
      start: pagination.start,
      end: pagination.end,
      submitted: false
    })
  );

  useEffect(() => {
    if (formSubmitList) {
      const filteredList = formSubmitList.results.filter((submit) => {
        if (showOnlyUser) {
          return (
            submit.filledBy === userEmail || submit.updatedBy === userEmail
          );
        } else if (submit.showSavedSubmitToUserOnly) {
          return submit.filledBy === userEmail;
        } else {
          return submit;
        }
      });
      setFilteredFormSubmitList(filteredList);
      setPagination({
        ...pagination,
        count: formSubmitList?.totalCount ? formSubmitList?.totalCount : 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSubmitList, showOnlyUser]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem(pageName);
    };
  }, []);

  const handlePageChange = (event: any, page: any) => {
    const start = (page - 1) * pageSize;
    const end = (page - 1) * pageSize + pageSize;
    setPagination({ ...pagination, start: start, end: end });
    setActivePage(pageName, page);
  };

  const showPagination = () => {
    return (
      <CustomPagination
        count={pagination.count}
        activePage={getActivePage(pageName)}
        pageSize={pageSize}
        onPageChange={handlePageChange}
      />
    );
  };

  if (error) {
    return (
      <ContentContainer>
        <Alert severity="error">Virhe ladattaessa lomakelistaa: {error}</Alert>
      </ContentContainer>
    );
  }
  return (
    <>
      <ContentContainer>
        <Box marginBottom={4}>
          <Typography variant="h5" color="textPrimary">
            Keskeneräiset lomakkeet
          </Typography>
        </Box>
        {isAdminUser() || isViewUser() ? (
          <Box mb={1}>
            <FormControlLabel
              color="primary"
              control={
                <Checkbox
                  onChange={() => setShowOnlyUser(!showOnlyUser)}
                  color="primary"
                  checked={showOnlyUser}
                />
              }
              label="Näytä vain minun keskeneräiset lomakkeet"
            />
          </Box>
        ) : null}
        {formSubmitList ? (
          isMobile() ? (
            <FormIncompleteSubmitsMobile
              formSubmitList={filteredFormSubmitList}
              showPagination={showPagination}
            />
          ) : (
            <FormIncompleteSubmits
              formSubmitList={filteredFormSubmitList}
              showPagination={showPagination}
            />
          )
        ) : (
          <CircularProgressIndicator isLoading={isLoading} />
        )}
      </ContentContainer>
    </>
  );
};
export default FormSubmitsContainer;
