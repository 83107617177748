import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { sendFileStream } from '../../../requests';
import { LoadingState } from '../../../types';
import { isMobile, useIsLoading } from '../../../utils';
import CircularProgressIndicator from '../../CircularProgressIndicator';
const pos = { x: 0, y: 0 };
const setPosition = (canvasPos: DOMRect, e: any) => {
  let clientX = e.clientX;
  let clientY = e.clientY;
  // touch specific
  if (e.changedTouches && e.changedTouches.length > 0) {
    clientX = e.changedTouches[0].clientX;
    clientY = e.changedTouches[0].clientY;
  }
  pos.x = clientX - canvasPos.left;
  pos.y = clientY - canvasPos.top;
};

const setBodyPositionFixed = () => {
  (document.querySelector('body') as HTMLElement).setAttribute(
    'style',
    'position: fixed;'
  );
};

const setBodyPostionDefault = () => {
  (document.querySelector('body') as HTMLElement).setAttribute(
    'style',
    'position: static;'
  );
};

let canvasPos: any = null;

const useHookWithRefCallback = (): [
  React.MutableRefObject<HTMLCanvasElement>,
  (node: HTMLCanvasElement) => void
] => {
  const ref = React.useRef<any>(null);
  const setRef = React.useCallback((node: HTMLCanvasElement) => {
    if (node) {
      const ctx = node.getContext('2d') as CanvasRenderingContext2D;
      const resize = (ctx: CanvasRenderingContext2D) => {
        node.setAttribute('width', Math.min(window.innerWidth, 800).toString());
        canvasPos = node.getBoundingClientRect();
      };
      resize(ctx);

      const draw = (e: any) => {
        // if using a mouse, skip drawing when left mouse button is not pressed
        if (!e.sourceCapabilities.firesTouchEvents && e.buttons !== 1) {
          return;
        }
        ctx.beginPath();
        ctx.lineWidth = 3;
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.strokeStyle = '#003287';
        ctx.moveTo(pos.x, pos.y);
        setPosition(canvasPos, e);
        ctx.lineTo(pos.x, pos.y);
        ctx.stroke();
      };

      window.addEventListener('resize', () => resize(ctx));
      const _setPosition = (e: MouseEvent | TouchEvent) => {
        setPosition(canvasPos, e);
      };
      node.addEventListener('mousemove', draw);
      node.addEventListener('mousedown', _setPosition);
      node.addEventListener('mouseenter', _setPosition);
      // touch
      node.addEventListener('touchmove', draw);
      node.addEventListener('touchstart', _setPosition);
    }

    // Save a reference to the node
    ref.current = node;
  }, []);

  return [ref, setRef];
};

const canvasWidth = 300;
const canvasHeight = 150;

const useStyles = makeStyles((theme) => ({
  canvas: {
    height: canvasHeight,
    border: '1px solid #e0e0e0'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));
export interface DrawingProps {
  title: string;
  value: string;
  label: string;
  error: { type: string; message: string };
  onSaveFinished: (url: string) => void;
  onClear: () => void;
}
const Drawing: React.FC<DrawingProps> = ({
  title,
  value,
  error,
  label,
  onSaveFinished,
  onClear
}) => {
  const classes = useStyles();
  const [isOpen, setOpen] = React.useState(false);
  const [isLoading, setLoadingFinished, setLoadingStarted] = useIsLoading();
  const [canvas, setRef] = useHookWithRefCallback();
  const handleSaveImage = () => {
    canvas.current.toBlob(async (blob) => {
      if (blob === null) {
        return;
      }
      const newImg = document.createElement('img'),
        url = URL.createObjectURL(blob);

      newImg.onload = function () {
        // no longer need to read the blob so it's revoked
        URL.revokeObjectURL(url);
      };
      newImg.src = url;
      const formData = new FormData();
      formData.append('file', new File([blob], 'drawing.jpg'));
      formData.append('extension', 'jpg');
      setLoadingStarted();
      setOpen(false);
      try {
        const fileDataResponse = await sendFileStream(formData);
        onSaveFinished(fileDataResponse.data.url);
      } finally {
        setLoadingFinished();
      }
    });
  };
  // fixes window scrolling on mobile devices when drawing with a finger
  useEffect(() => {
    if (isOpen && isMobile()) {
      setBodyPositionFixed();
      document.documentElement.requestFullscreen();
    } else {
      setBodyPostionDefault();
      if (document.exitFullscreen && document.fullscreenElement) {
        document.exitFullscreen();
      }
    }
    return () => {
      setBodyPostionDefault();
    };
  }, [isOpen]);
  return (
    <>
      <Dialog fullScreen open={isOpen}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box justifyContent="center" display="flex">
            <canvas ref={setRef} className={classes.canvas} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              const context = canvas.current.getContext('2d');
              context?.clearRect(0, 0, window.innerWidth, window.innerHeight);
              onClear();
            }}
          >
            Tyhjennä
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Sulje
          </Button>
          <Button variant="contained" color="primary" onClick={handleSaveImage}>
            Tallenna
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading === LoadingState.IsLoading ? (
        <Box height={canvasHeight} width={canvasWidth} position="relative">
          <CircularProgressIndicator size={48} isLoading={isLoading} />
        </Box>
      ) : (
        <>
          {error && <Alert severity="error">{error.message}</Alert>}
          <Typography>{label}</Typography>
          <Box
            height={canvasHeight}
            display="flex"
            alignItems="center"
            marginTop={1}
          >
            <Button
              onClick={() => {
                setOpen(true);
              }}
            >
              {value ? (
                <Box overflow="hidden">
                  <img width="100%" src={value} alt="Allekirjoitus" />
                </Box>
              ) : (
                <Typography>
                  <img src="/static/gear-up.png" alt="Gear up" />
                </Typography>
              )}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default Drawing;
