import * as Sentry from '@sentry/react';
import { formatReleaseVersion } from './utils';

export const initSentry = async () => {
  const response = await fetch('/api/version', {
    headers: {
      'cache-control': 'no-cache',
      pragma: 'no-cache'
    }
  });
  const { latestAppVersion } = await response.json();

  Sentry.init({
    dsn: 'https://21ece3884972460fa2311c3ac6743091@o318763.ingest.sentry.io/5644382',
    release: formatReleaseVersion(latestAppVersion)
  });
};
