import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import React from 'react';
import EditCommonLabel from './EditCommonLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    elementPreview: { marginTop: theme.spacing(4), width: '70%' }
  })
);

interface EditTextProps {
  onUpdateElementLabel: (e: any) => void;
  label: string;
}

export const EditText = ({ onUpdateElementLabel, label }: EditTextProps) => {
  const classes = useStyles();
  return (
    <>
      <EditCommonLabel onChange={onUpdateElementLabel} value={label} />
      <TextField
        className={classes.elementPreview}
        disabled
        placeholder="Tekstikenttä"
      />
    </>
  );
};
export default EditText;
