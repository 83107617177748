import React, { useEffect } from 'react';
import { deleteAccessTokenCookie, deleteUserCookie } from './cookieHelper';
import { getEnvs } from './requests';
import Routes from './Routes';
import {
  getAccessTokenStorage,
  removeAccessTokenStorage,
  removeActiveGroupsStorage,
  removeUserDataStorage,
  removeUserRolesStorage
} from './storageHelper';
import { Envs } from './types';
import { isTokenExpired } from './utils';

export const EnvContext = React.createContext<Envs | null>(null);

const App = () => {
  const [envs, setEnvs] = React.useState<Envs | null>(null);
  useEffect(() => {
    (async () => {
      const _envs = await getEnvs();
      setEnvs(_envs);
    })();
  }, []);
  return (
    <EnvContext.Provider value={envs}>
      <Routes />
    </EnvContext.Provider>
  );
};

export const logout = () => {
  deleteAccessTokenCookie();
  deleteUserCookie();
  removeAccessTokenStorage();
  removeUserDataStorage();
  removeUserRolesStorage();
  removeActiveGroupsStorage();
  window.location.href = '/login';
};

const doLogout = () => {
  const t = getAccessTokenStorage();
  if (t && isTokenExpired(t)) {
    logout();
  }
};

setInterval(() => {
  doLogout();
}, 1000 * 15);
doLogout();

export default App;
