import { TreeItem, TreeItemProps, TreeView } from '@material-ui/lab';
import { Typography, useMediaQuery } from '@material-ui/core';
import { DbGroup, FormGroupNode } from '../types';
import { buildGroupHierarchy, searchTree, sortBy } from '../utils';
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';

export interface FormGroup {
  name: string;
  groupId: string;
}

export interface SelectionTargetFormGroup extends FormGroup {
  closeWhenSelected: boolean;
}

interface FormGroupProps {
  formGroups: DbGroup[];
  onGroupSelected: (g: SelectionTargetFormGroup) => void;
  selected: string;
}
const roundCorners = {
  borderTopLeftRadius: 8,
  borderTopRightRadius: 999,
  borderBottomRightRadius: 999,
  borderBottomLeftRadius: 8
};
const StyledTreeItem = withStyles({
  root: {
    '&$selected > $label': {
      backgroundColor: 'transparent'
    }
  },
  label: {
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  content: {
    color: 'rgba(0, 0, 0, 0.87)',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.1)',
      ...roundCorners
    }
  }
})(TreeItem);

const FormTreeItem: React.FC<TreeItemProps & { active: boolean }> = (props) => {
  return (
    <StyledTreeItem
      {...props}
      style={{
        ...roundCorners,
        marginRight: 16
      }}
      label={
        <Typography color={props.active ? 'primary' : 'textPrimary'}>
          {props.label}
        </Typography>
      }
    />
  );
};

const showAllInGroupText = 'Näytä kansion lomakkeet';

const buildTreeElements = (
  tree: FormGroupNode,
  isMobile: boolean,
  activeId: string
) => {
  return (
    <>
      {isMobile && (
        <FormTreeItem
          nodeId={tree.groupId}
          label={showAllInGroupText}
          key={tree.groupId}
          active={false}
        />
      )}
      {sortBy(tree.children, 'name').map((node) => {
        return (
          <FormTreeItem
            expandIcon={<KeyboardArrowRight />}
            collapseIcon={<KeyboardArrowDown />}
            active={node.groupId === activeId}
            nodeId={node.groupId}
            label={node.name}
            key={node.groupId}
          >
            {node.children.length > 0 &&
              buildTreeElements(node, isMobile, activeId)}
          </FormTreeItem>
        );
      })}
    </>
  );
};

const defaultExpaned = () => new Set(['1']);

const buildPathToNode = (
  tree: FormGroupNode,
  nodeId: string,
  result: Set<string>
) => {
  const node = searchTree(tree, nodeId);
  if (!node) {
    return Array.from(result);
  }
  result.add(node.groupId);
  if (node.parent) {
    buildPathToNode(tree, searchTree(tree, node.parent)?.groupId ?? '', result);
  }
  return Array.from(result);
};

const FormGroups: React.FC<FormGroupProps> = ({
  onGroupSelected,
  formGroups,
  selected
}) => {
  const isMobileView = useMediaQuery('(max-width:640px)');
  const tree = buildGroupHierarchy(formGroups);
  const expanded = buildPathToNode(tree, selected, defaultExpaned());
  return (
    <>
      <TreeView
        style={{ minWidth: 250 }}
        onNodeSelect={(_: React.ChangeEvent<{}>, groupId: string) => {
          const shouldClose =
            (_.target as HTMLParagraphElement).innerText === showAllInGroupText;
          const node = searchTree(tree, groupId);
          onGroupSelected({
            name: node?.name ?? '',
            groupId,
            closeWhenSelected: shouldClose
          });
        }}
        expanded={expanded}
      >
        <FormTreeItem label="Pääkansio" nodeId="1" active={false}>
          {buildTreeElements(tree, isMobileView, selected)}
        </FormTreeItem>
      </TreeView>
    </>
  );
};

export default FormGroups;
