import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import {
  TableRowOption,
  TableElementType
} from '../Editing/Components/EditTable';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import { clone } from '../../../utils';
import { useEffect } from 'react';
import { getCustomList } from '../../../requests';
import React from 'react';
import { DataListEntry } from '../../../types';

interface UserFillableTableProps {
  onChange: any;
  value: any;
  label: string;
  tableOptions: TableRowOption[];
}

const inputLabelProps = (elementType: TableElementType) => {
  return elementType === 'date' ? { shrink: true } : {};
};

const TableRow: React.FC<{
  label: string;
  onChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  elementType: TableElementType;
  value: { label: string; value: string };
  dataListItems: DataListEntry[];
  className?: string;
}> = ({ elementType, label, value, dataListItems, className, onChange }) => {
  const classes = useStyles();
  switch (elementType) {
    case 'text':
    case 'email':
    case 'number':
    case 'date':
      return (
        <span className={className}>
          <TextField
            onWheel={(e: any) => {
              e.target.blur();
            }}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
              }
            }}
            fullWidth={true}
            onChange={onChange}
            type={elementType}
            label={label}
            value={value.value}
            InputLabelProps={{
              className: classes.labelOffset,
              ...inputLabelProps(elementType)
            }}
          />
        </span>
      );
    case 'radio':
      return dataListItems ? (
        <FormControl component="fieldset" className={className}>
          <FormLabel component="legend">{label}</FormLabel>
          <RadioGroup value={value.value} onChange={onChange}>
            {dataListItems.map((item) => {
              return (
                <FormControlLabel
                  // value is purposely 'label' here because it is the value that is stored in the database
                  value={item.label}
                  control={<Radio color="primary" />}
                  label={item.label}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      ) : null;
    case 'checkbox':
      const checkboxOnChange = (e: any) => {
        const newValue = new Set(value.value.split(' | '));
        if (e.target.checked) {
          newValue.add(e.target.value);
        } else {
          newValue.delete(e.target.value);
        }
        e.target.value = Array.from(newValue)
          .filter((v) => v.length !== 0)
          .join(' | ');
        onChange(e);
      };
      return dataListItems ? (
        <FormControl component="fieldset" className={className}>
          <FormLabel component="legend">{label}</FormLabel>
          <FormGroup>
            {dataListItems.map((item) => {
              return (
                <FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox
                      color="primary"
                      checked={value.value.split(' | ').includes(item.label)}
                      onChange={checkboxOnChange}
                      // value is purposely 'label' here because it is the value that is stored in the database
                      name={item.label}
                      value={item.label}
                    />
                  }
                  label={item.label}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      ) : null;
    case 'select':
      return dataListItems ? (
        <FormControl className={className}>
          <InputLabel
            className={classes.labelOffset}
            shrink
            id="list-picker-label"
          >
            {label}
          </InputLabel>
          <Select
            style={{ minWidth: 150 }}
            labelId="list-picker-label"
            onChange={onChange as any}
            value={value.value}
          >
            {dataListItems.map((item) => {
              return (
                // value is purposely 'label' here because it is the value that is stored in the database
                <MenuItem key={item.id} value={item.label}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : null;
    default:
      return <span>Unsupported: {elementType}</span>;
  }
};

const generateEmptyRow = (tableOptions: TableRowOption[]) => {
  return tableOptions.map((tableValue) => {
    return { label: tableValue.label, value: '', type: tableValue.elementType };
  });
};

const getFetchableLists = (tableOptions: TableRowOption[]) => {
  return Array.from(
    new Set(
      tableOptions
        .filter((tableOption) => tableOption.listId)
        .map((tableOption) => tableOption.listId)
    )
  ) as number[];
};

const useStyles = makeStyles((theme) => ({
  striped: {
    '& > div:nth-child(even)': {
      marginTop: '8px',
      marginBottom: '8px',
      backgroundColor: '#f8f8f8'
    }
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.primary.main
  },
  topOffset: {
    marginTop: theme.spacing(2)
  },
  labelOffset: {
    top: '-10px'
  },
  title: {
    marginBottom: '10px'
  }
}));

const UserFillableTable: React.FC<UserFillableTableProps> = ({
  label,
  value,
  onChange,
  tableOptions
}) => {
  const isMobileView = useMediaQuery('(max-width:640px)');
  const classes = useStyles();
  const [lists, setLists] = React.useState<{ [key: number]: DataListEntry[] }>(
    {}
  );
  useEffect(() => {
    Promise.all(
      getFetchableLists(tableOptions).map((listId) => {
        return getCustomList(listId);
      })
    ).then((results) => {
      const resultsLists = results.reduce(
        (prev: { [key: number]: DataListEntry[] }, curr) => {
          prev[curr.listId] = curr.data;
          return prev;
        },
        {}
      );
      const newLists = { ...lists, ...resultsLists };
      setLists(newLists);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableOptions]);
  return (
    <>
      <FormLabel component="legend">
        <Typography className={classes.title}>{label}</Typography>
      </FormLabel>
      <div className={classes.striped}>
        {value.map((_: any, rowIndex: number) => {
          const handleRowDelete = () => {
            const newRows = [...value];
            newRows.splice(rowIndex, 1);
            onChange(newRows);
          };
          const handleRowCopy = () => {
            const newRows = [...value];
            newRows.push(clone(newRows)[rowIndex]);
            onChange(newRows);
          };
          return (
            <>
              <Box
                key={rowIndex}
                display={isMobileView ? 'block' : 'flex'}
                alignItems="center"
                paddingTop={1}
                paddingBottom={1}
              >
                {tableOptions.map((column, columnIndex) => {
                  const onChangeHandler = (
                    e: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
                    >
                  ) => {
                    const newValue = clone(value);
                    newValue[rowIndex][columnIndex].value = e.target.value;
                    onChange(newValue);
                  };

                  return (
                    <div
                      style={{
                        paddingLeft: 8,
                        marginRight: 16,
                        marginTop: 20,
                        display: isMobileView ? 'block' : 'inline'
                      }}
                      key={`${rowIndex}.${columnIndex}`}
                    >
                      <TableRow
                        label={column.label}
                        onChange={onChangeHandler}
                        elementType={column.elementType}
                        value={value[rowIndex][columnIndex]}
                        dataListItems={lists[column.listId ?? 0]}
                        className={isMobileView ? classes.topOffset : ''}
                      />
                    </div>
                  );
                })}
                <Box display="flex" justifyContent="flex-end">
                  <IconButton onClick={handleRowCopy}>
                    <FileCopyOutlined />
                  </IconButton>
                  <IconButton onClick={handleRowDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
              {isMobileView && <Divider className={classes.divider} />}
            </>
          );
        })}
      </div>
      <Button
        className={classes.topOffset}
        variant="outlined"
        color="primary"
        onClick={() => {
          const newRow = generateEmptyRow(tableOptions);
          onChange([...value, newRow]);
        }}
      >
        Lisää rivi
      </Button>
    </>
  );
};

export default UserFillableTable;
