import {
  Box,
  createStyles,
  FormLabel,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import React from 'react';
import { DataListEntry } from '../../../types';
import ClearIcon from '@material-ui/icons/Clear';
import InfoTooltip from './InfoTooltip';

interface DropdownProps {
  label: string;
  info?: string;
  options: DataListEntry[];
  value?: string;
  onChange?: (event: any) => void;
  defaultValue?: string;
  className?: string;
  error?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    width: {
      width: '100%'
    }
  })
);

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onChange,
  value,
  label,
  info,
  className,
  error,
  defaultValue
}) => {
  const classes = useStyles();
  return (
    <>
      <FormLabel component="legend">
        <Typography
          color={error ? 'error' : 'primary'}
          style={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'nowrap'
          }}
        >
          {label}
          <InfoTooltip info={info} />
        </Typography>
      </FormLabel>
      <Box display="flex">
        <Select
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          className={classes.width + (className ? ' ' + className : '')}
        >
          {options.map((entry, i) => (
            <MenuItem value={entry.value} key={i + label}>
              {entry.label}
            </MenuItem>
          ))}
        </Select>
        <IconButton
          size="small"
          onClick={() => {
            const a: any = { target: { value: defaultValue } };
            onChange && onChange(a);
          }}
        >
          <ClearIcon />
        </IconButton>
      </Box>
    </>
  );
};

export { Dropdown };
