import { VersionContext } from './AppUpdateProvider';
import React from 'react';
import { formatReleaseVersion } from './utils';
const Version = () => {
  return (
    <VersionContext.Consumer>
      {(releaseTime) =>
        `Julkaistu: ${
          releaseTime !== null ? formatReleaseVersion(releaseTime) : ''
        }`
      }
    </VersionContext.Consumer>
  );
};

export default Version;
