import {
  Box,
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';
import React from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import { UiOption } from '../../AddForm';
import EditCommonLabel from './EditCommonLabel';
import EditCommonInfo from './EditCommonInfo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionField: { width: '65%', margin: theme.spacing(1) },

    optionNumber: { width: theme.spacing(2) }
  })
);

type OptionFieldProps = {
  value: string;
  label: string;
  optionIndex: number;
  autoFocus: boolean;
  onRemoveOption: (index: number) => void;
  onUpdateOptionValue: (optionIndex: number, value: string) => void;
  onAddOption: () => void;
};

const OptionField = ({
  value,
  label,
  optionIndex,
  onRemoveOption,
  onUpdateOptionValue,
  onAddOption,
  autoFocus
}: OptionFieldProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Typography className={classes.optionNumber}>
        {optionIndex + 1}.
      </Typography>
      <TextField
        autoFocus={autoFocus}
        className={classes.optionField}
        placeholder={`Vaihtoehto ${optionIndex + 1}`}
        size="medium"
        value={value}
        onChange={(e) => onUpdateOptionValue(optionIndex, e.target.value)}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            onAddOption();
          }
        }}
      ></TextField>
      <IconButton onClick={() => onRemoveOption(optionIndex)}>
        <ClearIcon />
      </IconButton>
    </Grid>
  );
};

interface EditMultipleChoiceProps {
  onUpdateElementLabel: (e: any) => void;
  onUpdateElementInfo: (e: any) => void;
  onRemoveOption: (index: number) => void;
  onAddOption: () => void;
  onUpdateOptionValue: (optionIndex: number, value: string) => void;
  info: string;
  label: string;
  options: UiOption[];
}

const EditMultipleChoice = ({
  onUpdateElementLabel,
  onUpdateElementInfo,
  onAddOption,
  onRemoveOption,
  onUpdateOptionValue,
  info,
  label,
  options
}: EditMultipleChoiceProps) => {
  return (
    <>
      <EditCommonLabel onChange={onUpdateElementLabel} value={label} />
      <Typography style={{ marginTop: 14 }}>
        <EditCommonInfo onChange={onUpdateElementInfo} value={info} />
      </Typography>
      <Box marginTop={4}>
        {options.map((option, index) => {
          return (
            <OptionField
              autoFocus={option.isAutofocusable ?? false}
              key={index}
              label={option.label}
              value={option.value}
              optionIndex={index}
              onRemoveOption={onRemoveOption}
              onUpdateOptionValue={onUpdateOptionValue}
              onAddOption={onAddOption}
            />
          );
        })}
        <Button onClick={onAddOption}>Lisää</Button>
      </Box>
    </>
  );
};

export default EditMultipleChoice;
