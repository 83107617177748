import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import ContentContainer from '../../ContentContainer';
import OnlyRoles from '../../OnlyRoles';
import { ListItem, ListsResponse, LoadingState } from '../../types';
import { compareNumberOrString } from '../../utils';
import { useApi } from '../DynamicForms/fetchHook';
import { Link as RouterLink } from 'react-router-dom';
import { DateTime } from 'luxon';

const ListsView: React.FC = () => {
  const [listsResponse, isLoading] = useApi<ListsResponse>('/api/lists');
  const [orderBy, setOrderBy] = useState('id');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const handleSort = (property: keyof ListItem) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedLists = listsResponse?.lists.sort((a, b) => {
    const valA = a[orderBy as keyof ListItem];
    const valB = b[orderBy as keyof ListItem];
    const orderVal = order === 'asc' ? 1 : -1;

    if (['created_at', 'updated_at'].includes(orderBy)) {
      if (new Date(valA) > new Date(valB)) {
        return -1 * orderVal;
      }
      if (new Date(valA) < new Date(valB)) {
        return 1 * orderVal;
      }
    } else {
      return compareNumberOrString(valA, valB) * orderVal;
    }
    return 0;
  });

  return (
    <ContentContainer>
      <Grid
        style={{ marginBottom: 32 }}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5" color="textPrimary">
          Kaikki listat
        </Typography>
        <OnlyRoles allowedRoles={['RamiForms\\RamiForms admin']}>
          <Link component={RouterLink} to="/listat/uusi">
            <Button color="primary" variant="contained" size="small">
              Luo uusi
            </Button>
          </Link>
        </OnlyRoles>
      </Grid>
      <Box overflow="auto">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'id'}
                  direction={order}
                  onClick={handleSort('id')}
                >
                  ID
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={order}
                  onClick={handleSort('name')}
                >
                  Nimi
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={order}
                  onClick={handleSort('created_at')}
                >
                  Luotu
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={orderBy === 'updated_at'}
                  direction={order}
                  onClick={handleSort('updated_at')}
                >
                  Muokattu
                </TableSortLabel>
              </TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedLists?.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">
                  {DateTime.fromISO(row.created_at).toFormat(
                    'dd.MM.yyyy HH:mm'
                  )}
                </TableCell>
                <TableCell align="right">
                  {DateTime.fromISO(row.updated_at).toFormat(
                    'dd.MM.yyyy HH:mm'
                  )}
                </TableCell>
                <TableCell align="right">
                  <Link component={RouterLink} to={`/listat/${row.id}`}>
                    Muokkaa
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      {isLoading === LoadingState.IsLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </ContentContainer>
  );
};

export default ListsView;
