import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import * as React from 'react';

interface PickableForm {
  name: string;
  formId: string;
}

interface FormIdPickerDialogProps {
  forms: PickableForm[];
  handleFormPick: (formId: string) => void;
  handleClose: () => void;
  isOpen: boolean;
}

const FormIdPickerDialog: React.FC<FormIdPickerDialogProps> = ({
  forms,
  isOpen,
  handleClose,
  handleFormPick
}) => {
  const [selectedValue, setSelectedValue] = React.useState<string | null>(null);
  const selectableFormFilterFn = (form: PickableForm) => {
    if (selectedValue) {
      return form.formId === selectedValue;
    } else {
      return true;
    }
  };
  return (
    <div>
      <Dialog onClose={handleClose} open={isOpen}>
        <DialogTitle>Valitse lomake</DialogTitle>
        <DialogContent style={{ minWidth: 400 }}>
          <Autocomplete
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onChange={(e, selected) => {
              setSelectedValue(selected?.formId ?? null);
            }}
            options={forms}
            getOptionLabel={(o) => {
              return o.name;
            }}
            renderInput={(params: any) => (
              <TextField {...params} margin="normal" />
            )}
          ></Autocomplete>
          <List>
            {forms.filter(selectableFormFilterFn).map((form) => (
              <ListItem
                button
                onClick={() => handleFormPick(form.formId)}
                key={form.formId}
              >
                <ListItemText primary={form.name} secondary={form.formId} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FormIdPickerDialog;
