import { Breadcrumbs, Link, Typography } from '@material-ui/core';

export interface RamicrumbsProps {
  crumbs: { href?: string; label: string }[];
}

const Ramicrumbs: React.FC<RamicrumbsProps> = ({ crumbs }) => {
  return (
    <Breadcrumbs>
      {crumbs.map((link, i) => {
        const isLast = i === crumbs.length - 1;
        if (isLast) {
          return (
            <Typography key={i + link.label} variant="body1">
              {link.label}
            </Typography>
          );
        }
        return (
          <Link key={i + link.label} color="primary" href={link.href}>
            {link.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Ramicrumbs;
