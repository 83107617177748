import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  IconButton
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { getProduct } from '../../../requests';
import { LoadingState, ProductInfo } from '../../../types';
import { ProductIdProps } from './ComponentTypes';
import ClearIcon from '@material-ui/icons/Clear';
import * as Sentry from '@sentry/react';
import { formatDate } from '../../../utils';
import SubmitDialog from '../../SubmitDialog';

export type MiraInventEquipmentsValue = {
  '@odata.etag'?: string;
  dataAreaId?: string;
  ItemId?: string;
  InventEquipmentId?: string;
  LRTIsSparePart?: string;
  LRTInventSerialAssetId?: string;
  EstablishedStaffPersonnelNumber?: string;
  PollutionClass?: string;
  LifeCycle?: number;
  LRTDateFirstRental?: string;
  LRTPreviousOwner?: string;
  LogisticStatusId?: string;
  DefaultDimensionDisplayValue?: string;
  LRTPurchaseBlocked?: string;
  LRTInventSerialDisposerId?: string;
  RequiredOperatingPersonal?: string;
  LRTBlockedForRentalTo?: Date;
  LRTRentalBlocked?: string;
  LRTSalesBlocked?: string;
  LRTSalesBlockingReasonId?: string;
  LRTPurchaseBlockingReasonId?: string;
  LRTSalesBlockingReasonComment?: string;
  LRTPurchaseBlockReasonComment?: string;
  LRTDispoEnabled?: string;
  LRTDepartmentId?: string;
  LRTIsAdditionalEquipment?: string;
  LRTDateLastRental?: Date;
  AllowEditLogisticStatus?: string;
  Description?: string;
  LRTRentalBlockingReasonComment?: string;
  LRTInventSerialName?: string;
  LRTBlockedForRentalFrom?: Date;
  ProdDate?: Date;
  LRTPropertyStatus?: string;
  LRTLeasingUntil?: Date;
  LRTInventSerialDimension?: string;
  LRTInventSiteId?: string;
  LRTAcquisitionDate?: Date;
  LRTIsAccessories?: string;
  EstalishedStaffDispoEnabled?: string;
  LRTRentalBlockingReasonId?: string;
  LRTForeignCustAccount?: string;
  LRTInventSerialNameAlias?: string;
  LRTForeignVendAccount?: string;
  AESRentalHydraulicOilTypeId?: string;
  ManufactureInventSerialId?: string;
  RefIdReceipt?: string;
  ResponsibleInventSiteId?: number;
  SalesOrderName?: string;
  RedeliveryDateTime?: Date;
};

const defaultFormatter = (val: string) => val;

const getMiraElementFormatter = (
  miraResponseFieldName: keyof MiraInventEquipmentsValue
): ((val: string) => string) => {
  switch (miraResponseFieldName) {
    case 'LRTAcquisitionDate':
    case 'ProdDate':
    case 'RedeliveryDateTime':
      return formatDate;
    default:
      return defaultFormatter;
  }
};

const ProductId: React.FC<ProductIdProps> = ({
  label,
  onChange,
  errors,
  value,
  prop,
  setValue,
  formData
}) => {
  const [productId, setProductId] = useState('');
  const [productName, setProductName] = useState('');
  const [productNotFound, setProductNotFound] = useState(false);
  const [productGetError, setProductGetError] = useState(false);
  const [noCurrentRentModal, setNoCurrentRentModal] = useState(false);
  const [noPreviousRentModal, setNoPreviousRentModal] = useState(false);
  const [isLoading, setIsLoading] = useState<LoadingState>(
    LoadingState.Initial
  );
  const miraSearchEnabled = prop.props?.miraSearchEnabled ?? false;

  const clearValues = useCallback(() => {
    setProductName('');
    const miraElementIds =
      formData?.elements
        .filter(
          (element) =>
            element?.props?.miraName && element.props.miraName.length > 0
        )
        .map((element) => element.id) ?? [];

    miraElementIds.forEach((miraElementId) => {
      setValue(
        miraElementId.toString(),
        { value: '' },
        { shouldValidate: true }
      );
    });
  }, [formData?.elements, setValue]);

  useEffect(() => {
    fetchProductData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const fetchProductData = (
    fetchCurrentRent = prop.props?.currentlyRented ?? false
  ) => {
    const setValues = (productInfo: ProductInfo) => {
      const miraElements =
        formData?.elements
          .filter(
            (element) =>
              element?.props?.miraName && element.props.miraName.length > 0
          )
          .map((element) => {
            return { id: element.id, miraName: element?.props?.miraName };
          }) ?? [];

      miraElements.forEach((miraElement) => {
        if (miraElement?.miraName && productInfo[miraElement?.miraName]) {
          const formatter = getMiraElementFormatter(miraElement?.miraName);
          setValue(
            miraElement.id.toString(),
            {
              value: formatter(productInfo[miraElement?.miraName])
            },
            {
              shouldValidate: true
            }
          );
        }
      });
    };

    if (productId && miraSearchEnabled) {
      (async () => {
        try {
          clearValues();
          setProductNotFound(false);
          setProductGetError(false);
          setNoCurrentRentModal(false);
          setIsLoading(LoadingState.IsLoading);
          const response = await getProduct(productId, fetchCurrentRent);
          setProductName(
            response?.LRTInventSerialName ??
              `Tuotteelle ${productId} ei ole asetettu nimeä`
          );
          setValues(response);
          if (response?.RefIdReceipt === null) {
            fetchCurrentRent
              ? setNoCurrentRentModal(true)
              : setNoPreviousRentModal(true);
          }
        } catch (error: any) {
          console.log(error);
          if (error.message?.statusCode === 404) {
            setProductNotFound(true);
          } else {
            Sentry.captureException(error);
            setProductGetError(true);
          }
        } finally {
          setIsLoading(LoadingState.Initial);
        }
      })();
    }
  };

  return (
    <>
      <TextField
        // multiline components bind this prevent default automatically
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !prop?.props?.multiline) {
            e.preventDefault();
          }
        }}
        value={value}
        onWheel={(e: any) => {
          e.target.blur();
        }}
        onBlur={(e) => {
          if (e.target.value !== productId) {
            setProductId(e.target.value);
          }
        }}
        inputProps={{ min: 0 }}
        multiline={prop?.props?.multiline}
        className="form-dynamic-textfield"
        fullWidth
        label={label}
        type="number"
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        name={prop.id.toString()}
        error={!!errors?.[prop.id]}
        helperText={errors?.[prop.id]?.message}
      />
      {isLoading === LoadingState.IsLoading && (
        <Box style={{ marginTop: 8 }} display="flex" justifyContent="left">
          <CircularProgress size={24} />
        </Box>
      )}
      {productNotFound && (
        <Typography variant="body1" color="error">
          {`Tuotekoodilla ${productId} ei löytynyt tuotetta Mirasta.`}
        </Typography>
      )}
      {productGetError && (
        <Typography variant="body1" color="error">
          {`Tuotteen hakemisessa tapahtui virhe. Tuotetiedot joudutaan syöttämään käsin.`}
        </Typography>
      )}
      <SubmitDialog
        show={noCurrentRentModal}
        headerText="Haluatko hakea edellisen vuokrauksen tiedot?"
        leavePageButtonText="Hae"
        stayPageButtonText="Peruuta"
        status="error"
        handleClose={() => {
          setNoCurrentRentModal(false);
        }}
        handleDismiss={() => {
          setNoCurrentRentModal(false);
          fetchProductData(false);
        }}
        handleStay={() => {
          setNoCurrentRentModal(false);
        }}
      >
        <Typography>
          <p>
            Sarjanumerolla {productId} ei löytynyt nykyisen vuokran
            vuokratietoja
          </p>
          <p>Haluatko hakea edellisen vuokran vuokratiedot?</p>
        </Typography>
      </SubmitDialog>
      <SubmitDialog
        show={noPreviousRentModal}
        headerText="Edellisen vuokran vuokratietoja ei löytynyt"
        leavePageButtonText="Selvä"
        status="error"
        handleClose={() => {
          setNoPreviousRentModal(false);
        }}
        handleDismiss={() => {
          setNoPreviousRentModal(false);
        }}
      >
        <Typography>
          <p>
            Sarjanumerolla {productId} ei löytynyt edellisen vuokran
            vuokratietoja
          </p>
        </Typography>
      </SubmitDialog>
      {productName && !productNotFound && isLoading === LoadingState.Initial && (
        <>
          <Box
            style={{ marginTop: 8 }}
            display="flex"
            justifyContent="left"
            alignItems="center"
          >
            <Typography variant="body1" color="textSecondary">
              Mira-nimi:
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              style={{ marginLeft: 8 }}
            >
              {productName}
            </Typography>
            <IconButton onClick={clearValues} size="small">
              <ClearIcon />
            </IconButton>
          </Box>
        </>
      )}
    </>
  );
};

export { ProductId };
