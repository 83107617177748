import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React from 'react';

interface SendTtlErrorDialogProps {
  handleDismiss: () => void;
  show: boolean;
}

const SendTtlErrorDialog: React.FC<SendTtlErrorDialogProps> = ({
  show,
  handleDismiss,
  children
}) => {
  return (
    <Dialog open={show} onClose={handleDismiss}>
      <DialogTitle>Tilausta ei voitu lähettää</DialogTitle>
      <DialogContent>
        <Box>
          <Box display="flex" minWidth={500}>
            <Box>
              <img width={150} src="/static/rami-error.svg" alt="Success" />
            </Box>
            <Box flex={3}>{children}</Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDismiss} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendTtlErrorDialog;
